//===================================================================================
// External
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Internal
import PageTitleBar from '../PageTitleBar/PageTitleBar';
import QueryBuilderForm from '../QueryBuilderForm/QueryBuilderForm';
import { useGetProject } from '../../hooks/commonHooks';
import { useDispatch } from 'react-redux';
import { redirectIfTrialPeriodExpired } from '../../store/actions/actions';
//===================================================================================

// TODO: moves this to 'views'
const QueryCreate = () => {
  // used when clicking 'Query This Agent'
  const { state } = useLocation();
  let agentId;
  if (state) {
    ({agentId} = state);
  }

  // used when clicking 'Create Query'
  const { projectId } = useParams();
  const { title: projectTitle } = useGetProject(projectId);

  return (
    <div className="QueryCreate__container" >
      <PageTitleBar
        title={"Create New Query"}
        subTitle={'New Query'}
      />
      <div className="QueryCreate__contentContainer">
        <QueryBuilderForm preselectedAgentId={agentId}/>
      </div>
    </div>
  )
}

export default QueryCreate;
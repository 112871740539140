//==============================================================================
// External:
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Internal:

//==============================================================================

const ButtonCreateQuery = ({route, text}) => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate(route)
  }

  return (
    <div className='ButtonCreateQuery__container' onClick={() => handleSubmit()}>
      <FontAwesomeIcon icon="fa-plus-circle" className="ButtonCreateQuery__plusIcon "/>
      <div className="ButtonCreateQuery__link">{text}</div>
    </div>
  )
}

export default ButtonCreateQuery;
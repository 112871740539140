//==============================================================================
// External:
import React from 'react';
import { useDispatch } from 'react-redux';

// Internal:
import {
  APP_USER_APPROVE_SEND_EMAIL_TO_AGENT,
  APP_USER_CANCEL_SEND_EMAIL_TO_AGENT
} from '../../utils/actionConstants';
//==============================================================================

const GoogleSendEmailToAgentModal = ({ }) => {
  const dispatch = useDispatch();

  const onSendEmailToAgent = () => {
    dispatch({ type: APP_USER_APPROVE_SEND_EMAIL_TO_AGENT })
  }

  const onClose = () => {
    dispatch({ type: APP_USER_CANCEL_SEND_EMAIL_TO_AGENT })
  }

  return (
    <div className='GoogleSendModal__container'>
      <div className='GoogleSendModal__textContainer'>
        <div className='GoogleSendModal__text'>
          Please confirm you have reviewed the draft in your Gmail account and confirm it appears as expected
        </div>
      </div>
      <div className='GoogleSendModal__buttonContainer'>
        <button
          className='GoogleSendModal__confirmButton'
          onClick={() => onSendEmailToAgent()}
        >
          Yes, I Have Reviewed!
        </button>
        <button
          className='GoogleSendModal__closeButton'
          onClick={() => onClose()}
        >
          Wait, Let Me Check...
        </button>
      </div>
    </div>
  )
}

export default GoogleSendEmailToAgentModal;
//==============================================================================
// Internal:
import { gmailDeleteDraftAPICall, updateQueryAPICall } from "../effectUtils/apiCalls";
import { setQuery } from "../slices/queries.slice";
import { setFlashMessage } from "../slices/ui.slice";
import { getUserDetails } from "../slices/user.slice";
//==============================================================================

export const gmailDeleteDraftEffect = async (action, { dispatch, getState }) => {
  try {
    const { gmailDraftId, _id: queryId, navigate } = action.payload;
    if (!gmailDraftId) throw Error('No draft id exists for query, not sent to drafts folder')
    const { _id: userId } = getUserDetails(getState());
    gmailDeleteDraftAPICall(userId, gmailDraftId);

    const dataToBeUpdated = { dateSentToInbox: null, gmailDraftId: null, threadId: null } // SHOULD THREADID BE NULL?
    const updatedQuery = await updateQueryAPICall({}, dataToBeUpdated, queryId)

    dispatch(setQuery(updatedQuery));

    dispatch(setFlashMessage({ type: 'success', message: 'Draft Successfully Deleted From Your Inbox!' }))
    navigate(`/project/${userId}/queries`)
  } catch (error) {
    dispatch(setFlashMessage({ type: 'error', message: 'There Was An Error Deleting Your Email Draft. Please Try Again.' }))
  }
};

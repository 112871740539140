//==============================================================================
// Internal:
import fetchAPI from "../../utils/fetchAPI";
import { clearUserDetails } from "../slices/user.slice";
import environment from "../../utils/env";
//==============================================================================

export const logoutEffect = async (action, { dispatch, getState }) => {
  try {
    const {navigate} = action.payload;
    const url = `${environment.dbBaseURL}/logout`;
    await fetchAPI(url);
    dispatch(clearUserDetails())
    navigate(`/sign-in`)
  } catch (error) {
    console.error('Error in LogoutEffect: ', error);
  }
};
//==============================================================================
/* External */
import {createSlice} from '@reduxjs/toolkit';
//==============================================================================

const initialState = {};

const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    setAgents(state, action) {
      return {
        ...action.payload
      }
    },
    setAgent(state, action) {
      return {
        ...state,
        [action.payload._id]: {...action.payload}
      }
    },
  },
});

// selectors:
export const getAgents = (state) => state.agents;

// actions:
export const {setAgent, setAgents} = agentsSlice.actions;

// reducer:
export default agentsSlice.reducer;

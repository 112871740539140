//==============================================================================
// External:
import React from 'react';

// Internal:
import PageTitleBar from '../../components/PageTitleBar/PageTitleBar';
//==============================================================================

const KnowledgeCenter = () => {
  return (
    <div className='KnowledgeCenter__container'>
      {/* <PageTitleBar title={'Querier Knowledge Center'} /> */}
      <iframe src="https://querier.freshdesk.com/support/home" />
    </div>
  )
}

export default KnowledgeCenter;
//==============================================================================
// External:
import React from 'react';
import { useDispatch } from 'react-redux';

// Internal:
import {
  APP_USER_AUTHORIZED_SEND_GMAIL_DRAFT ,
  APP_USER_CANCELLED_SEND_GMAIL_DRAFT
} from '../../utils/actionConstants';
//==============================================================================

const GoogleSendModal = ({ }) => {
  const dispatch = useDispatch();

  const onSendToGmail = () => {
    dispatch({type: APP_USER_AUTHORIZED_SEND_GMAIL_DRAFT})
  }

  const onClose = () => {
    dispatch({type: APP_USER_CANCELLED_SEND_GMAIL_DRAFT})
  }

  return (
    <div className='GoogleSendModal__container'>
      <div className='GoogleSendModal__textContainer'>
        <div className='GoogleSendModal__text'>Please Confirm You Wish To Send To Your Gmail 'Drafts' Folder</div>
      </div>
      <div className='GoogleSendModal__buttonContainer'>
        <button
          className='GoogleSendModal__confirmButton'
          onClick={() => onSendToGmail()}
        >
          Send To Gmail 'Drafts' Folder!
        </button>
        <button
          className='GoogleSendModal__closeButton'
          onClick={() => onClose()}
        >
          No, I'm Still Working On It
        </button>
      </div>
    </div>
  )
}

export default GoogleSendModal;
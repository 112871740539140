//===================================================================================
// External
import React from 'react';

// Internal
import SubstitutionTitle from './Subcomponents/SubstitutionTitle/SubstitutionTitle';
import SubstitutionTitleForm from './Subcomponents/SubstitutionTitleForm/SubstitutionTitleForm';
import { createNewSubstitution, updateSubstitution } from '../../store/actions/actions';
import { setFlashMessage } from '../../store/slices/ui.slice';
import useSubstitutionHooks from './SubstitutionHooks';
//===================================================================================

const Substitution = ({
  setShowNewSubstitution,
  showNewSubstitution,
  substitution = {}
}) => {
 
  const {
    dispatch,
    exampleText,
    projectId,
    queryTemplateId,
    redBorderClass,
    setExampleText,
    setRedBorderClass,
    setShowEditSubstitution,
    setSignifier,
    setTitle,
    showEditSubstitution,
    signifier,
    title,
    userId,
  } = useSubstitutionHooks(substitution);

  const signifierValidated = () => {
    if (!signifier) {
      dispatch(setFlashMessage({ type: 'error', message: 'You Must Include A Signifier In The Substitution' }));
      return false;
    }
    if (!signifier.includes('{{') || !signifier.includes('}}')) {
      dispatch(setFlashMessage({ type: 'error', message: 'Please Wrap Signifier In Curly Braces. For example: {{text}}' }));
      return false;
    }
    return true;
  }

  const titleValidated = () => {
    if (!title) {
      dispatch(setFlashMessage({ type: 'error', message: 'Please Include A Title For Your Signifier' }));
      return false;
    }
    return true;
  }

  const exampleTextValdiated = () => {
    if (!exampleText) {
      dispatch(setFlashMessage({ type: 'error', message: 'Please Include Example Text For Your Signifier' }));
      return false;
    }
    return true;
  }

  const handleSubmit = () => {
    if (!signifierValidated()) return;
    if (!titleValidated()) return;
    if (!exampleTextValdiated()) return;

    if (showEditSubstitution) {
      dispatch(updateSubstitution({ title, signifier, exampleText, _id: substitution._id, queryTemplateId, projectId, userId }))
      setShowEditSubstitution(false);
    }

    if (showNewSubstitution) {
      dispatch(createNewSubstitution({ title, signifier, exampleText, _id: substitution._id, queryTemplateId, projectId, userId }));
      setShowNewSubstitution(false);
    }
  }

  const handleBlur = () => {
    if (title && signifier && exampleText) {
      setRedBorderClass('red');
    }
  }

  return (
    <div
      className={`Substitution__container ${redBorderClass || ''}`}
      onBlur={() => handleBlur()}
    >
      {
        (showNewSubstitution || showEditSubstitution) ?
          <SubstitutionTitleForm
            handleSubmit={handleSubmit}
            setShowNewSubstitution={setShowNewSubstitution}
            setShowEditSubstitution={setShowEditSubstitution}
            setTitle={setTitle}
            showNewSubstitution={showNewSubstitution}
            substitution={substitution}
            title={title}
          />
          : <SubstitutionTitle
            title={substitution.title}
            setShowEditSubstitution={setShowEditSubstitution}
            showEditSubstitution={showEditSubstitution}
          />
      }
      <div className='Substitution__signifier'>
        <div className='Substitution__label'>Signifier</div>
        <input
          className='Substitution__input'
          disabled={!showEditSubstitution && substitution.signifier}
          id='SubstitutionSignifierInput'
          onChange={(event) => setSignifier(event.target.value)}
          placeholder='{{add me}}'
          value={signifier}
        />
      </div>
      <div className='Substitution__exampleText'>
        <div className='Substitution__label'>Example Text</div>
        <input
          className='Substitution__input'
          disabled={!showEditSubstitution && substitution.exampleText}
          id='SubstitutionExampleText'
          onChange={(event) => setExampleText(event.target.value)}
          placeholder='See this text in the template'
          value={exampleText}
        />
      </div>
    </div>
  )
}

export default Substitution;

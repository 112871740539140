//==============================================================================
// Internal:
import { fetchWithPost } from "../../utils/fetchAPI";
import { setQuery } from "../slices/queries.slice";
import { setFlashMessage } from "../slices/ui.slice";
import environment from "../../utils/env";
//==============================================================================

export const simpleUpdateQueryEffect = async (action, { dispatch, getState, take }) => {
  try {
    const newQueryInfo = action.payload;

    const url = `${environment.dbBaseURL}/queries/${newQueryInfo.queryId}`;
    const queryBody = { ...newQueryInfo };
    const updatedQuery = await fetchWithPost(url, queryBody, 'PUT');
    dispatch(setQuery(updatedQuery));

    dispatch(setFlashMessage({ type: 'success', message: 'Query Successfully Updated' }))
  } catch (error) {
    console.error('Error in the SimpleUpdateQueryEffect Effect', error)
    dispatch(setFlashMessage({ type: 'error', message: 'Unable To Submit Query Changes. Please Try Again.' }))
  }
};
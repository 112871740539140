//==============================================================================
// Internal:
import { isSubscriptionInactive } from "../../utils/utils";
import { getUserDetails } from "../slices/user.slice";
//==============================================================================

export const redirectIfTrialExpiredEffect = async (action, { dispatch, getState }) => {
  try {
    const { navigate } = action.payload;
    const { paymentInfo } = getUserDetails(getState());
    
    if (isSubscriptionInactive(paymentInfo)) {
      navigate('/subscribe')
    };
  } catch (error) {
    console.error('Error in GetPaymentInfoEffect: ', error);
  }
};

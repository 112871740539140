//==============================================================================
// External:
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Internal:
import { formateDate } from '../../utils/utils';
import { getUserDetails } from '../../store/slices/user.slice';
import { useSelector } from 'react-redux';
//==============================================================================

const MessageCard = ({ msg }) => {
  console.log('THREAD: message card', {msg});
  const { email } = useSelector(getUserDetails);
  const [isOpen, setIsOpen] = useState(false);

  const margin = () => (email === msg?.from[0]?.address) ? '' : 'auto'

  const icon = isOpen ?
    <FontAwesomeIcon icon="fa-angle-down" />
    : <FontAwesomeIcon icon="fa-angle-up" />

  const text = isOpen ? 'Close Full Email' : 'View Full Email'

  const edgeStyle = () => {
    if (msg.labels.includes('SENT')) return 'greenBorder';
    if (msg.labels.includes('UNREAD')) return 'redBorder';
    return ''
  }

  return (
    <div className={`MessageCard__container ${edgeStyle()}`} style={{ marginLeft: margin() }}>
      <div>
        <div>From: {msg.to[0].address}</div>
        <div>To: {msg.from[0].address}</div>
        <div>{formateDate(msg.date)}</div>
        <div>{msg.labels}</div>
        <div>{msg.snippet}</div>
        <div className="MessageCard__expandableContainer" onClick={() => setIsOpen(!isOpen)}>
          <div className="MessageCard__toggle">{text}</div>
          <div className="MessageCard__icon">{icon}</div>
        </div>
        {
          isOpen &&
          <div>{msg.body.plainText.split('________')[0].split('-------')}</div>
        }
      </div>
    </div>
  )
}

export default MessageCard;
//===================================================================================
// External:
import React from "react";
import { useSelector } from "react-redux";

// Internal:
import loader from "../../assets/loading.svg";
import { getLoading } from "../../store/slices/ui.slice";
//===================================================================================

const Loading = () => {
  const isLoading = useSelector(getLoading);

  return (
    <>
      {
        isLoading &&
        <div className="Loading__container">
          <img src={loader} alt="Loading" className={'Loading__icon'} />
        </div>
      }
    </>
  )
};

export default Loading;


//==============================================================================
// External:
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//==============================================================================

export const onClickHandler = (agent, navigate, projectId, query) => {
  if (!query || (!query.dateSentToInbox && !query.dateSentToAgent)) {
    navigate(`/project/${projectId}/query-create`, { state: { agentId: agent._id } })
    return;
  }

  if (query.dateSentToAgent && query.threadId) {
    navigate(`/query-thread/${query._id}`)
    return;
  }

  if (query.dateSentToInbox) {
    navigate(`/query-final-review/${query._id}`)
    return;
  }
};

export const hasBeenQueried = (queriesByAgentArray, agent) => queriesByAgentArray.includes(agent._id);

export const setIsOpenClass = (isOpen) => {
  if (isOpen) return 'isOpen';
  if (isOpen === false) return 'isClosed';
  return 'undeterminedOpenStatus';
};

export const setIsOpenIcon = (isOpen) => {
  if (isOpen) return <FontAwesomeIcon icon="fa-check-circle" className="AgentCard__editIcon" />;
  if (isOpen === false) return <FontAwesomeIcon icon="fa-exclamation-circle" className="AgentCard__editIcon" />;
  return <FontAwesomeIcon icon="fa-question-cicrle" className="AgentCard__editIcon" />;
};
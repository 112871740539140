//==============================================================================
// External:
import React from "react";
import { useDispatch } from "react-redux";

// Internal:
import { login } from "../../store/actions/actions";
import environment from "../../utils/env";
import GoogleIcon from "../../assets/google-icon.png";
import QuillImage from "../../assets/quill-black.svg";
//==============================================================================

const SignIn = ({ isSignUp = false }) => {
  const dispatch = useDispatch();
  //By signing up, I accept the Atlassian Cloud Terms of Service and acknowledge the Privacy Policy.

  const onLogin = () => {
    dispatch(login());
  };

  return (
    <div className="SignIn__container" >
      <div className="SignIn__innerContainer">
        <div className="SignIn__header">
          <img src={QuillImage} className='SignIn__logo' />
          <div className='SignIn__title'>Querier</div>
        </div>
        <div className="SignIn__textContainer">
          <div className='SignIn__welcome'>Welcome!</div>
          <div className='SignIn__text'>{`Please sign ${isSignUp ? "up" : "in"} using your Google credentials.`}</div>
        </div>
        <div className="SignIn__gmailLogin" onClick={() => onLogin()} >
          <a
            href={`${environment.dbBaseURL}/auth/google`}
            className="SignIn__text"
            id="GoogleSignIn"
          >
            <img src={GoogleIcon} className="SignIn__image" />
            {isSignUp ? "Sign Up With Google" : "Sign In With Google"}
          </a>
        </div>
        <div className="SignIn__links">
          {isSignUp ? <a href="/sign-in">Already have a Querier account? Log in</a> : <a href="/sign-up">Create an account</a>}
        </div>
      </div>
    </div>
  );
};

export default SignIn;

/*
NOTES:
1. Google Docs: https://developers.google.com/identity/protocols/oauth2/native-app#exchange-authorization-code
*/

//==============================================================================
// External:
import React, { useState } from 'react';
import { convert } from 'html-to-text';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Internal:
import PageTitleBar from '../PageTitleBar/PageTitleBar';
import { useGetQueries, useGetQueryTemplate } from '../../hooks/commonHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteQuery, simpleUpdateQuery } from '../../store/actions/actions';
import QuestionDisplay from '../QuestionDisplay/QuestionDisplay';
import QueryStatusDropdown from '../Dropdown/QueryStatusDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//==============================================================================

const CustomFormReview = () => {
  const { queryId } = useParams();
  const query = useGetQueries()[queryId];
  const queryTemplate = useGetQueryTemplate();
  const [dateSentToAgent, setDateSentToAgent] = useState(query.dateSentToAgent);
  const [queryStatus, setQueryStatus] = useState(query.status);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onDeleteEntireQuery = () => {
    dispatch(deleteQuery({ ...query, navigate }));
  }

  const copyData = async (element) => {
    await navigator.clipboard.writeText(JSON.stringify(element));
    console.log("Copied the text: " + JSON.stringify(element));
  };

  const onSubmit = (event) => {
    event.preventDefault()
    dispatch(simpleUpdateQuery({
      queryId: query._id,
      dateSentToAgent,
      status: queryStatus
    }));
  }

  const CopyItem = ({ element, title }) => {
    return (
      <div onClick={() => copyData(element)}>
        <span>{title}</span>
        <FontAwesomeIcon
          className={''}
          icon="fa-copy"
        />
      </div>
    )
  };

  return (
    <div className='CustomFormReview__container'>
      <PageTitleBar title={'Agent Form Review'} subTitle={'Review Info Submitted To Agent'} />
      <div className='CustomFormReview__contentContainer'>
        <p>Querier can't anticipate what every custom form may ask for, but we can help make copy/pasting easier</p>
        <CopyItem element={convert(query.finalEmailText)} title='Query Letter' />
        <CopyItem element={convert(queryTemplate.synopsis)} title='Synopsis'/>
        <CopyItem element={queryTemplate.pitch} title='Pitch'/>
      </div>
      <form className='CustomFormReview__formContainer' onSubmit={(event) => onSubmit(event)}>
        <div className='CustomFormReview__dateSubmittedContainer'>
          <div className='CustomFormReview__labelContainer'>
            <label className='CustomFormReview__agentInfoLabel'>Date Submitted To Agent:</label>
            <label>
              <DatePicker
                selected={dateSentToAgent}
                onChange={(date) => setDateSentToAgent(date)} //only when value has changed
              />
            </label>
          </div>
          {/* <QuestionDisplay tooltipKey={'customFormDate'} /> */}
        </div>
        <QueryStatusDropdown value={queryStatus} onChangeHandler={setQueryStatus} />
        <button className='CustomFormReview__formButton'>Submit</button>
        <button type="button" onClick={() => onDeleteEntireQuery()} className='CustomFormReview__deleteButton red'>Delete Query</button>
      </form>
    </div>
  )
}

export default CustomFormReview;

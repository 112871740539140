//==============================================================================
// Internal:
import { fetchWithPost } from "../../utils/fetchAPI";
import { setQuery } from "../slices/queries.slice";
import { setFlashMessage, setModalContent } from "../slices/ui.slice";
import { getUserDetails } from "../slices/user.slice";
import { objHasData } from '../../utils/utils';
import environment from "../../utils/env";
import * as ACTIONS from '../../utils/actionConstants';
//==============================================================================

export const gmailSendDraftEffect = async (action, { dispatch, getState, take }) => {
  try {
    const { agent, email, navigate, projectId, query } = action.payload;
    const user = getUserDetails(getState())

    // await popup response from user:
    dispatch(setModalContent('googleSendConfirmation'));

    const actionsArray = [
      ACTIONS.APP_USER_AUTHORIZED_SEND_GMAIL_DRAFT,
      ACTIONS.APP_USER_CANCELLED_SEND_GMAIL_DRAFT,
      'ui/setModalContent'
    ];

    const [newAction, ...rest] = await take(action => actionsArray.includes(action.type));

    dispatch(setModalContent(''));

    if (newAction.type === ACTIONS.APP_USER_AUTHORIZED_SEND_GMAIL_DRAFT) {
      const url = `${environment.dbBaseURL}/mail/drafts/create`
      const requestBody = {
        to: agent.email,
        from: user.email,
        subject: query.emailSubject,
        message: email,
      }
      const returnedEmailInfo = await fetchWithPost(url, { ...requestBody });

      if (objHasData(returnedEmailInfo)) {
        const url = `${environment.dbBaseURL}/queries/${query._id}`;
        const params = {
          dateSentToInbox: new Date(),
          gmailDraftId: returnedEmailInfo.id,
          threadId: returnedEmailInfo.message.threadId
        }
        const queryBody = { ...query, ...params };
        const updatedQuery = await fetchWithPost(url, queryBody, 'PUT');
        dispatch(setQuery(updatedQuery));
        dispatch(setFlashMessage({ type: 'success', message: `Draft Successfully Sent To Your 'Drafts' Folder!` }))
      }
    }

    navigate(`/project/${projectId}/queries`);
    // TODO: is this flash message right? Should it be above when we get in the user approval?
  } catch (error) {
    console.error('Error in GmailSendDraft Effect:', error);
    dispatch(setFlashMessage({
      type: 'error',
      message: 'There Was An Error Submitting Your Gmail Draft. Please Try Again.'
    }))
  }
};
//==============================================================================
// External:
import React from 'react';
import SubstitutionEntry from '../SubstitutionEntry/SubstitutionEntry';
import Editor from '../Editor/Editor';
import PageSubtitle from '../PageSubtitle/PageSubtitle';

// Internal:
//==============================================================================

const SubstitutionFinalText = ({
  handleSubstitutionEntriesInput,
  newQueryLetterText,
  setNewQueryLetterText,
  substitutionTemplates,
  substitutionEntriesFS,
}) => {

  const getSubEntry = (substitution) => {
    if (!substitutionEntriesFS || Object?.keys(substitutionEntriesFS)?.length === 0) return {};

    return Object?.values(substitutionEntriesFS)?.filter(subEnt => subEnt.substitutionId === substitution._id)[0]
   }

  // Format Substitutions:
  const substitutionList = Object.values(substitutionTemplates).map((substitution) => {
    return <SubstitutionEntry
      substitution={substitution}
      handleSubstitutionEntriesInput={handleSubstitutionEntriesInput}
      substitutionEntry={getSubEntry(substitution)}
    />
  });

  const handleTextInput = (input) => {
    setNewQueryLetterText(input); // update template with any changes made
  }

  return (
    <div className='SubstitutionFinalText__container' >
      <div className='SubstitutionFinalText__substitutions'>
        <PageSubtitle title={'Substitutions'} />
        <div>
          {substitutionList}
        </div>
        {/* </form> */}
      </div>
      <div className='SubstitutionFinalText__editor'>
        <PageSubtitle title={'Editor'} />
        <form>
          <Editor setValue={handleTextInput} text={newQueryLetterText} />
        </form>
      </div>
    </div>
  )
}

export default SubstitutionFinalText;

//==============================================================================
// External:
import React from 'react';

// Internal:

//==============================================================================

const StatusContainer = ({ Component, title }) => {
  return (
    <div className='StatusContainer__container'>
      <div className='StatusContainer__title'>
        {title}
      </div>
      <div className='StatusContainer__component'>
        <Component />
      </div>
    </div>
  )
}

export default StatusContainer;
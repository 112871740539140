//==============================================================================
// Internal:
import { hasAddedAgents, sortByRating } from "../../utils/utils";
//==============================================================================

export const doNotQueryHasChanged = (updatedAgentInfo, existingAgentInfo) => {
  return (updatedAgentInfo.doNotQuery !== existingAgentInfo.doNotQuery)
};

export const setPriorityWhenDoNotQueryChange = (updatedAgentInfo, existingAgentInfo, fullAgentList) => {
  // changing from false to true ('query' to 'doNotQuery')
  if (updatedAgentInfo.doNotQuery && !existingAgentInfo.doNotQuery) {
    updatedAgentInfo.priority = null;
  }

  // changing from true to false ('doNotQuery' to 'query')
  if (!updatedAgentInfo.doNotQuery && existingAgentInfo.doNotQuery) {
    updatedAgentInfo.priority = _setPriority(fullAgentList) + 1;
  }
}

export const _setPriority = (fullAgentList) => {
  const sortedAgentList = sortByRating(fullAgentList);
  const agentsWithPriorities = sortedAgentList.filter(agent => agent.priority > 0);
  return agentsWithPriorities.length;
}

export const setPriorityOnCreate = (agents) => {
  if (!hasAddedAgents(agents)) return 1;
  return Object.values(agents)
        .map(agent => agent.priority)
        .sort((a, b) => a - b)
        .pop() + 1
}
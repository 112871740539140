//==============================================================================
// Internal:
import fetchAPI from "../../utils/fetchAPI";
import { setPaymentInfo } from "../slices/user.slice";
import environment from "../../utils/env";
//==============================================================================

export const getPaymentInfoEffect = async (action, { dispatch, getState }) => {
  try {
    const subscriptionInfo = await fetchAPI(`${environment.dbBaseURL}/get-subscription`);

    if (subscriptionInfo.status === 500) {
      return; //no customer exists
    }

    dispatch(setPaymentInfo(subscriptionInfo));
  } catch (error) {
    console.error('Error in GetPaymentInfo Effect: ', error);
  }
};

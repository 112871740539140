//==============================================================================
// External:
import React from 'react';
import WhyQueryCard from './WhyQuerierCard';

// Internal:

//==============================================================================

const WhyQuerier = ({ }) => {
  return (
    <div className='WhyQuerier__container'>
      <div className='WhyQuerier__title'>
        Why Querier?
      </div>
      <div className='WhyQuerier__contentContainer'>
        <WhyQueryCard
          icon='query'
          text='Create full query letters based on agent requirements with the click of a button!'
          title='Simplify the query process'
        />
        <WhyQueryCard
          icon='agents'
          text='Create, organize, and manage a list of agents and their query requirements'
          title='Curate your agent list'
        />
        <WhyQueryCard
          icon='plan'
          text='Keep pace with goal setting and reminders'
          title='Track your progress'
        />
      </div>
    </div>
  )
}

export default WhyQuerier;
//===================================================================================
// External
import React from 'react';

// Internal
import Editor from '../Editor/Editor';
import PageSubtitle from '../PageSubtitle/PageSubtitle';
import SubstitutionList from '../SubstitutionList/SubstitutionList';
import { convertTextToHTML} from '../../utils/utils';
//===================================================================================
// TODO: move to 'views'
const QueryLetterTemplate = ({props}) => {
  const {
    formattedText,
    formatText,
    localText,
    setLocalText,
  } = props;

  const handleTextInput = (input) => {
    setLocalText(input)
    formatText(input)
  }

  return (
    <div className='QueryLetterTemplate__container'>
      <div className="QueryLetterTemplate___innerContainer">
        <div className='QueryLetterTemplate__substitutionList'>
          <SubstitutionList />
        </div>
        <div className="QueryLetterTemplate__editor">
          <PageSubtitle tooltipKey='editor' title='Editor' shouldShowQuestionMark />
          <Editor setValue={handleTextInput} text={localText}/>
        </div>
        <div className='QueryLetterTemplate__exampleText'>
          <PageSubtitle tooltipKey='formattedText' title='Formatted Text' shouldShowQuestionMark />
          {convertTextToHTML(formattedText)}
        </div>
      </div>
    </div>
  )
}
export default QueryLetterTemplate;

//==============================================================================
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import {combineReducers} from 'redux';

/* Internal */
import agentsReducer from "./slices/agents.slice";
// import authReducer from "./slices/auth.slice";
import projectsReducer from "./slices/projects.slice";
import queriesReducer from "./slices/queries.slice"
import queryTemplatesReducer from "./slices/queryTemplates.slice";
import substitutionsSlice from './slices/substitutions.slice';
import substitutionsEntriesSlice from './slices/substitutionEntries.slice';
import threadSlice from './slices/thread.slice';
import uiReducer from "./slices/ui.slice";
import userReducer from "./slices/user.slice";
//==============================================================================

const rootReducer = combineReducers({
  user: userReducer,
  // auth: authReducer,
  projects: projectsReducer,
  queryTemplates: queryTemplatesReducer,
  queries: queriesReducer,
  substitutions: substitutionsSlice,
  substitutionEntries: substitutionsEntriesSlice,
  agents: agentsReducer,
  thread: threadSlice,
  ui: uiReducer
});

export default rootReducer;

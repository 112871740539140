//==============================================================================
// Internal:
import environment from "../../utils/env";
import fetchAPI from "../../utils/fetchAPI";
import { setAgents } from "../slices/agents.slice";
import { setFlashMessage } from "../slices/ui.slice";
//==============================================================================

export const deleteAgentEffect = async (action, { dispatch, getState }) => {
  try {
    const {agentId, navigate, projectId} = action.payload;

    const url = `${environment.dbBaseURL}/agents/${agentId}`
    const response = await fetchAPI(url, 'DELETE')

    dispatch(setAgents(response.agentsArray));
    dispatch(setFlashMessage({ type: 'success', message: response.message }))
    navigate(`/project/${projectId}/agents`);
  } catch (error) {
    dispatch(setFlashMessage({ type: 'error', message: 'There Was An Error Deleting This Agent. Please Try Again.' }))
    console.error('Error In The DeleteAgentEffect: ', error)
  }
};

//==============================================================================
// External:
import React from 'react';
import FAQCard from './FAQCard';

// Internal:
import * as FAQ from '../../../utils/constants';
import { convertTextToHTML } from '../../../utils/utils';
//==============================================================================

const FAQs = () => {
  return (
    <div className='FAQs__container'>
      <div className='FAQs__title'>
        Frequently Asked Questions
      </div>
      <div className='FAQs__contentContainer'>
        <FAQCard text='Fo asdf asdf dsafsadf' title='Some Title' />
        <FAQCard text={convertTextToHTML(FAQ.TEST)} title='Testing HTML' customHt={'300px'} />
        <FAQCard text={FAQ.QUERIER_COST} title={FAQ.QUERIER_COST_QUESTION} customHt={'165px'} />
        <FAQCard text={FAQ.USE_GMAIL} title={FAQ.USE_GMAIL_QUESTION} customHt={'195px'}/>
        <FAQCard text={FAQ.GMAIL_ACCESS} title={FAQ.GMAIL_ACCESS_QUESTION} />
      </div>
    </div>
  )
}

export default FAQs;
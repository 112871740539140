//==============================================================================
// External:
import React from 'react';
import DatePicker from 'react-datepicker';

// Internal:
import QuestionDisplay from '../../../../components/QuestionDisplay/QuestionDisplay';
import { simpleUpdateQuery } from '../../../../store/actions/actions';
//==============================================================================

const QueryManagerPersonalLink = ({
  dateSentToAgent,
  dispatch,
  navigate,
  queryId,
  query,
  queryManagerPersonalLink,
  setDateSentToAgent,
  setQueryManagerPersonalLink,
}) => {
  const formatQMPersonalLink = (queryManagerPersonalLink) => {
    if (!queryManagerPersonalLink.includes('https://')) return `https://${queryManagerPersonalLink}`
    return queryManagerPersonalLink
  }

  const onSubmit = (event) => {
    event.preventDefault()
    dispatch(simpleUpdateQuery({
      queryId: query._id,
      queryManagerPersonalLink: formatQMPersonalLink(queryManagerPersonalLink),
      dateSentToAgent
    }));
    navigate(`/query-manager-review/${queryId}`)
  }

  return (
    <div className='QueryManagerPersonalLink__container'>
      <form className='QueryManagerSubmit__formContainer' onSubmit={(event) => onSubmit(event)}>
        <div className='QueryManagerSubmit__submitQTLinkInputContainer'>
          <div >
            <div className='QueryManagerSubmit__inputContainer'>
              <label className='QueryManagerSubmit__agentInfoLabel'>QueryManager Personal Link:</label>
              <label>
                <input
                  value={queryManagerPersonalLink}
                  placeholder='Paste Your Email Link Here...'
                  onChange={(event) => setQueryManagerPersonalLink(event.target.value)}
                />
              </label>
              <QuestionDisplay tooltipKey={'queryManagerPersonalLink'} />
            </div>
          </div>
        </div>
        <div className='QueryManagerSubmit__inputContainer'>
          <label className='QueryManagerSubmit__agentInfoLabel'>Date Submitted To Agent:</label>
          <label>
            <DatePicker
              selected={dateSentToAgent}
              onChange={(date) => setDateSentToAgent(date)} //only when value has changed
            />
          </label>
          <QuestionDisplay tooltipKey={'queryManagerDate'} />
        </div>
        <button className='QueryManagerSubmit__formButton'>Submit QueryManager Personal Link</button>
      </form>
    </div>
  )
}

export default QueryManagerPersonalLink;
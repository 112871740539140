//==============================================================================
// External:
import React from 'react';

// Internal:
import { useGetAgents } from '../../../../hooks/commonHooks';
//==============================================================================

const AgentRatingGraph = () => {
  const agents = useGetAgents();
  const getRatingsCount = (r1, r2) => Object.values(agents).filter(agent => agent.rating === r1 || agent.rating === r2).length;
  const totalAgentCount = Object.values(agents).length;
  const calcPercentage = (topNum, bottomNum) => {
    if (!bottomNum) return 0
    const percent = (topNum/bottomNum) * 100;
    return Math.round(percent);
  }
  const rating0 = getRatingsCount(0, 0);
  const rating12 = getRatingsCount(1, 2);
  const rating34 = getRatingsCount(3, 4);
  const rating56 = getRatingsCount(5, 6);
  const rating78 = getRatingsCount(7, 8);
  const rating910 = getRatingsCount(9, 10);

  const makeRow = (label, ratingCount) => {
    const percent = calcPercentage(ratingCount, totalAgentCount);

    return (
      <div className='AgentRatingGraph__graphContainer'>
        <div className='AgentRatingGraph__label'>{`${label}`}</div>
        <div className="progress">
          <div className="progress__bar" style={{ width: `${percent}%` }}></div>
        </div>
        <div className='AgentRatingGraph__percentLabel'>{`${percent}%`}</div>
      </div>
    )
  }

  return (
    <div className='AgentRatingGraph__container'>
      {makeRow('Rtg 1-2', rating12)}
      {makeRow('Rtg 3-4', rating34)}
      {makeRow('Rtg 5-6', rating56)}
      {makeRow('Rtg 7-8', rating78)}
      {makeRow('Rtg 9-10', rating910)}
      {makeRow('No Rtg', rating0)}
    </div>
  )
}

export default AgentRatingGraph;
//==============================================================================
// External:
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';

// Internal:
import { formatFinalEmail } from '../QueryFinalEmail/QueryFinalEmailUtils';
import {
  useGetAgents,
  useGetQueryTemplate,
  useGetSubstitutionEntries,
  useGetSubstitutions
} from '../../hooks/commonHooks';
//==============================================================================

const useQueryBuilderFormHooks = (preselectedQuery, preselectedAgentId) => {
  const { projectId } = useParams();
  const queryTemplate = useGetQueryTemplate();
  const substitutionEntriesArray = useGetSubstitutionEntries(preselectedQuery._id);
  const querySubstitutionsObj = substitutionEntriesArray.reduce((accumulator, subEntry) => {
    accumulator[subEntry.substitutionId] = subEntry;
    return accumulator;
  }, {})
  const [agentId, setAgentId] = useState(preselectedQuery?.agentId || preselectedAgentId || '');
  const agents = useGetAgents()
  const [agent, setAgent] = useState({})
  const [emailSubjectFS, setEmailSubjectFS] = useState(preselectedQuery?.emailSubject || queryTemplate?.emailSubject || '');
  const substitutionTemplates = useGetSubstitutions();
  const [existingSubstitutionEntriesFS, setExistingSubstitutionEntriesFS] = useState(querySubstitutionsObj || {});
  const [newSubstitutionEntriesFS, setNewSubstitutionEntriesFS] = useState({});
  const [newQueryLetterText, setNewQueryLetterText] = useState(preselectedQuery.queryLetterText || queryTemplate?.queryTemplateText || '');
  const [queryBodyFS, setQueryBodyFS] = useState(preselectedQuery.queryBody || '');
  const [finalEmail, setFinalEmail] = useState('');
  const [finalEmailHTML, setFinalEmailHTML] = useState('');
  const [queryFormat, setQueryFormat] = useState(preselectedQuery?.queryFormat || '')
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!agentId) return;
    const selectedAgent = agents[agentId]
    setAgent(selectedAgent);

    const finalEmailText = formatFinalEmail({
      agent: agents[agentId],
      queryTemplate,
      newQueryLetterText,
      substitutionTemplates,
      substitutionEntriesFS: { ...existingSubstitutionEntriesFS, ...newSubstitutionEntriesFS }
    });

    setQueryFormat(selectedAgent.queryFormat)
    setFinalEmail(finalEmailText.stringVersion)
    setFinalEmailHTML(finalEmailText.htmlVersion)
    setAgent(selectedAgent)
  }, [agentId, existingSubstitutionEntriesFS, newQueryLetterText, newSubstitutionEntriesFS])

  return {
    agent,
    agentId,
    agents,
    dispatch,
    emailSubjectFS,
    existingQuerySubstitutions: querySubstitutionsObj,
    existingSubstitutionEntriesFS,
    finalEmail,
    finalEmailHTML,
    navigate,
    newQueryLetterText,
    newSubstitutionEntriesFS,
    projectId,
    queryBodyFS,
    queryFormat,
    queryTemplate,
    setAgentId,
    setEmailSubjectFS,
    setExistingSubstitutionEntriesFS,
    setFinalEmail,
    setFinalEmailHTML,
    setQueryBodyFS,
    setNewQueryLetterText,
    setNewSubstitutionEntriesFS,
    substitutionTemplates,
  }
}

export default useQueryBuilderFormHooks;

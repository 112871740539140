//==============================================================================
// External:
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Internal:
import MessageCard from '../../components/MessageCard/MessageCard';
import PageSubtitle from '../../components/PageSubtitle/PageSubtitle';
import { getQuery } from '../../store/slices/queries.slice';
import { deleteQuery, gmailGetThread, simpleUpdateQuery } from '../../store/actions/actions';
import { clearThread, getThread } from '../../store/slices/thread.slice';
import { setFlashMessage } from '../../store/slices/ui.slice';
import { objHasData } from '../../utils/utils';
import { GMAIL_THREAD_NOT_FOUND, GMAIL_TOKEN_EXPIRED } from '../../utils/constants';
//==============================================================================

const QueryThread = () => {
  const { queryId } = useParams();
  const query = useSelector(getQuery(queryId));
  const thread = useSelector(getThread);
  const [messages, setMessages] = useState([])
  const [value, setValue] = useState(query?.status)
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(gmailGetThread({ query }));
    return () => dispatch(clearThread());
  }, [queryId])


  useEffect(() => {
    if (!objHasData(thread) || (thread?.status && thread?.status !== 200)) {
      if (thread.status === 401) setErrorMessage(GMAIL_TOKEN_EXPIRED);
      if (thread.status === 404) setErrorMessage(GMAIL_THREAD_NOT_FOUND);
      return;
    }

    if (objHasData(thread) && !thread.hasOwnProperty('errors')) {
      const messageContents = Object.values(thread)?.map(msg => <MessageCard msg={msg} />)
      setMessages(messageContents)
    }

    if (thread?.errors?.length > 0) {
      dispatch(setFlashMessage({
        type: 'error',
        message: 'Unable to retrieve message thread. Please check your Gmail to view the email thread.'
      }));
    }
  }, [thread])

  const onChangeHandler = (event) => {
    const status = event.target.value
    setValue(status);
    dispatch(simpleUpdateQuery({ queryId: query._id, status }));
  };

  const onDeleteEntireQuery = () => {
    dispatch(deleteQuery({ ...query, navigate }));
  }

  return (
    <div className='QueryThread__container'>
      <div className="QueryThread__innerContainer">
        <PageSubtitle
          title='Query Submission Email Thread'
        />
        <div>
          {messages}
          {errorMessage}
        </div>
      </div>
      <div className="QueryThread__buttonsContainer">
        <div className="QueryThread__dropdownContainer">
          <label>Query Status:</label>
          <select
            className="QueryThread__dropdown red"
            defaultValue={'Select Query Status'}
            onChange={(event) => onChangeHandler(event)}
            value={value}
          >
            <option value="" disabled selected>Set Query Status</option>
            <optgroup label="Query Remains Open">
              <option value="waiting">Waiting</option>
              <option value="manuscript-request-full">Manuscript Request - Full</option>
              <option value="manuscript-request-partial">Manuscript Request - Partial</option>
              <option value="revise-resubmit">Revise & Resubmit</option>
            </optgroup>
            <optgroup label="Query Closed">
              <option value="rejection-form">Form Rejection</option>
              <option value="rejection-personal">Personal Rejection</option>
              <option value="representation-offer">Offer of Representation</option>
              <option value="no-response">No Response</option>
            </optgroup>
          </select>
        </div>
        <button onClick={() => onDeleteEntireQuery()} className='QueryThread__deleteButton'>Delete Query</button>
      </div>
    </div>
  )
}

export default QueryThread;

//==============================================================================
// External:
import React from 'react';
import { useParams } from 'react-router';

// Internal
import PageTitleBar from '../../components/PageTitleBar/PageTitleBar';
import QueryTemplateChecklist from '../../components/QueryTemplateChecklist/QueryTemplateChecklist';
import { useGetProject } from '../../hooks/commonHooks';
//==============================================================================

const QueryTemplate = () => {
  const { id } = useParams()
  const { title: projectTitle } = useGetProject(id)

  return (
    <div className='QueryTemplate__container'>
      <PageTitleBar
        title={projectTitle}
        subTitle={'Query Template'}
      />
      <div className='QueryTemplate__contentContainer'>
        <div className='QueryTemplate__checklist'>
          <QueryTemplateChecklist />
        </div>
      </div>
    </div>
  )
}

export default QueryTemplate;


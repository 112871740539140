//==============================================================================
// Internal:
import environment from "../../utils/env";
import { fetchWithPost } from "../../utils/fetchAPI";
import { getQueryTemplates } from "../slices/queryTemplates.slice";
import { setSubstitution } from "../slices/substitutions.slice";
import { setFlashMessage } from "../slices/ui.slice";
import { getUserId } from "../slices/user.slice";
//==============================================================================

export const updateSubstitutionEffect = async (action, { dispatch, getState }) => {
  try {
    const updatedSubstitution = action.payload;
    const existingSubstitutionInfo = getQueryTemplates(getState())[updatedSubstitution._id];
    const userId = getUserId(getState());

    const returnedSubstitution = await fetchWithPost(
      `${environment.dbBaseURL}/substitutions/${updatedSubstitution._id}`,
      { ...existingSubstitutionInfo, ...updatedSubstitution, userId },
      'PUT'
    );

    dispatch(setSubstitution(returnedSubstitution));
    dispatch(setFlashMessage({ type: 'success', message: 'Substitution Successfully Updated' }))
  } catch (error) {
    console.error('Error in the updateSubstitution Effect', error);
    dispatch(setFlashMessage({ type: 'error', message: 'Unable To Submit Substitution. Please Try Again!' }))
  }
};
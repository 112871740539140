//==============================================================================
import * as ACTIONS from "../../utils/actionConstants";
//==============================================================================


export const startSessionExpiration = (payload) => ({
  type: ACTIONS.APP_START_SESSION_EXPIRATION,
  payload
})

export const createNewAgent = (agent) => ({
  type: ACTIONS.AGENT_CREATE,
  payload: agent
});

export const createNewCustomer = (payload) => ({
  type: ACTIONS.APP_CREATE_NEW_CUSTOMER
});

export const createNewProject = (payload) => ({
  type: ACTIONS.PROJECT_CREATE,
  payload
});

export const createNewQuery = (payload) => ({
  type: ACTIONS.QUERY_CREATE_NEW,
  payload,
});

export const createNewQueryTemplate = (queryTemplate) => ({
  type: ACTIONS.QUERY_TEMPLATE_CREATE_NEW,
  payload: queryTemplate
});

export const createNewSubstitution = (substitution) => ({
  type: ACTIONS.SUBSTITUTION_CREATE_NEW,
  payload: substitution
});

export const createNewSubstitutionEntries = (substitutionEntries) => ({
  type: 'app/createNewSubstitutionEntries',
  payload: substitutionEntries
})

export const createOrUpdateSubstitutionEntries = (substitutionEntries) => ({
  type: ACTIONS.SUB_ENTRY_CREATE_OR_UPDATE,
  payload: substitutionEntries
})

export const deleteAgent = (payload) => ({
  type: ACTIONS.AGENT_DELETE,
  payload,
})

export const deleteProject = (payload) => ({
  type: ACTIONS.PROJECT_DELETE,
  payload
})

export const deleteQuery = (payload) => ({
  type: ACTIONS.QUERY_DELETE,
  payload
})

export const deleteUser = (payload) => ({
  type: ACTIONS.APP_DELETE_USER_ACCT,
  payload
})

export const fetchProjects = () => ({
  type: ACTIONS.PROJECTS_GET
})

export const fetchProjectData = id => ({
  type: ACTIONS.PROJECT_GET,
  payload: id
})

export const fetchSubstitutions = id => ({
  type: ACTIONS.SUBSTITUTIONS_GET,
  payload: id
});

export const finalizeQuery = query => ({
  type: ACTIONS.QUERY_FINALIZE,
  payload: query
});

export const getPaymentInfo = () => ({
  type: ACTIONS.APP_GET_PAYMENT_INFO,
});

export const gmailDeleteDraft = payload => ({
  type: ACTIONS.GMAIL_DELETE_DRAFT,
  payload
});

export const gmailGetThread = payload => ({
  type: ACTIONS.GMAIL_GET_THREAD,
  payload
});

export const gmailSendDraft = payload => ({
  type: ACTIONS.GMAIL_SEND_DRAFT,
  payload
});

export const gmailSendEmailToAgent = payload => ({
  type: ACTIONS.GMAIL_SEND_EMAIL,
  payload
});

export const gmailUpdateDraft = payload => ({
  type: ACTIONS.GMAIL_UPDATE_DRAFT,
  payload
});

export const gmailUpdateQuerySentToAgent = payload => ({
  type: ACTIONS.GMAIL_UPDATE_QUERY_SENT_TO_AGENT,
  payload
});

export const handleIdleSession = (payload) => ({
  type: ACTIONS.APP_HANDLE_IDLE_SESSION,
  payload,
});

export const initialize = (payload) => ({
  type: ACTIONS.INITIALIZE,
  payload,
});

export const login = () => ({
  type: ACTIONS.APP_LOGIN
});

export const logout = (payload) => ({
  type: ACTIONS.APP_LOGOUT,
  payload,
});

export const redirectIfTrialPeriodExpired = (navigate) => ({
  type: ACTIONS.APP_REDIRECT_IF_TRIAL_EXPIRED,
  payload: navigate
});

export const simpleUpdateQuery = (query) => ({
  type: ACTIONS.QUERY_UPDATE_SIMPLE,
  payload: query
});

export const submitQueryManagerPersonalLink = () => ({
  type: ACTIONS.QUERY_MANAGER_PERSONAL_LINK
});

export const updateAgent = (payload) => ({
  type: ACTIONS.AGENT_UPDATE,
  payload
});

export const updateAgentPriority = (payload) => ({
  type: ACTIONS.AGENT_UPDATE_PRIORITY,
  payload
});

export const updateProject = (payload) => ({
  type: ACTIONS.PROJECT_UPDATE,
  payload
});

export const updateQueryTemplate = (queryTemplate) => ({
  type: ACTIONS.QUERY_TEMPLATE_UPDATE,
  payload: queryTemplate
});

export const updateQuery = (payload) => ({
  type: ACTIONS.QUERY_UPDATE,
  payload
});

export const updateSubstitution = (updatedSubstitution) => ({
  type: ACTIONS.SUBSTITUTION_UPDATE,
  payload: updatedSubstitution
});

export const updateSubstitutions = (updatedSubstitutions) => ({
  type: 'app/updateSubstitutions',
  payload: updatedSubstitutions
});

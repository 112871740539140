//==============================================================================
// Internal:
import fetchAPI from "../../utils/fetchAPI";
import { setFlashMessage, setModalContent } from "../slices/ui.slice";
import environment from "../../utils/env";
import { setSubstitutions } from "../slices/substitutions.slice";
import * as ACTIONS from '../../utils/actionConstants';
//==============================================================================

export const deleteSubstitutionEffect = async (action, { dispatch, getState, take }) => {
  try {
    const { _id: substitutionId} = action.payload;

    dispatch(setModalContent('deleteSubstitutionConfirmation'));

    const actionsArray = [
      ACTIONS.APP_DELETE_SUBSTITUTION,
      ACTIONS.APP_KEEP_SUBSTITUTION,
      'ui/setModalContent'
    ];

    const [newAction, ...rest] = await take(action => actionsArray.includes(action.type));

    dispatch(setModalContent(''));

    const deleteConfirmationAction = ACTIONS.APP_DELETE_SUBSTITUTION

    if (newAction.type === deleteConfirmationAction) {
      const url = `${environment.dbBaseURL}/substitutions/${substitutionId}`;
      const response = await fetchAPI(url, 'DELETE')

      dispatch(setSubstitutions(response.substitutionsArray));
      dispatch(setFlashMessage({ type: 'success', message: response.message }));
    }

    dispatch(setFlashMessage({ type: 'success', message: 'Substitution Successfully Deleted!' }))
  } catch (error) {
    dispatch(setFlashMessage({ type: 'error', message: 'There Was An Error Deleting The Substitution. Please Try Again.' }))
    console.error('Error In The DeleteSubstitutionEffect: ', error)
  }
};

//==============================================================================
// Internal:
import { APP_EXTEND_SESSION } from "../../utils/actionConstants";
import { startSessionExpiration } from "../actions/actions";
import { extendSessionAPICall } from "../effectUtils/apiCalls";
import { getTokenExpirationTime } from "../slices/user.slice";
import {
  getIsExtendingSession,
  getShowModal,
  setIsExtendingSession
} from "../slices/ui.slice";
//==============================================================================

export const handleIdleSessionEffect = async (action, { dispatch, getState, take }) => {
  try {
    const {navigate} = action.payload;
    let HAS_STARTED_COUNTDOWN = false;
    let timeoutId;

    function startTimer() {
      const timeoutInMiliseconds = 2 * 60 * 1000;
      timeoutId = window.setTimeout(doInactive, timeoutInMiliseconds);
    }

    function resetTimer() {
      // fire 'cancel' action to kill timers in saga (if saga has been called)
      window.clearTimeout(timeoutId);
      const expirationTime = getTokenExpirationTime(getState())
      const sessionTimeRemaining = expirationTime - Date.now()

      // dont do anything if Countdown modal is displayed
      const modalContent = getShowModal(getState())
      if (modalContent === "countdownModal") return;

      // if within 5 mins, extend session
      const isExtendingSession = getIsExtendingSession(getState());

      // console.log('HANDLE IDLE SESSION: getting expiration time left', {sessionTimeRemaining, isExtendingSession});

      if (sessionTimeRemaining < (3 * 60 * 1000) && !isExtendingSession) {
        // console.log('HANDLE IDLE SESSION: within 3 mins, extending session ***********', {sessionTimeRemaining, isExtendingSession});
        dispatch(setIsExtendingSession(true));
        extendSessionAPICall(dispatch, resetTimer);
      } else {
        // console.log('HANDLE IDLE SESSION: outside of 3 mins, resetting timer', {sessionTimeRemaining, isExtendingSession});
        startTimer();
      };

      if (HAS_STARTED_COUNTDOWN) {
        dispatch({ type: APP_EXTEND_SESSION })
        HAS_STARTED_COUNTDOWN = false;
      }
    }

    function doInactive() {
      console.log("---------- CLIENT INACTIVE ---------");
      dispatch(startSessionExpiration({ navigate, resetTimer }));
      HAS_STARTED_COUNTDOWN = true;
    }

    function setupTimers() {
      document.addEventListener("onload", resetTimer, false);
      document.addEventListener("mousemove", resetTimer, false);
      document.addEventListener("mousedown", resetTimer, false);
      document.addEventListener("onclick", resetTimer, false);
      document.addEventListener("keydown", resetTimer, false);
      document.addEventListener("scroll", resetTimer, false);

      startTimer();
    }

    setupTimers();
  } catch (error) {
    console.error('Error in HandleIdleSessionEffect: ', error);
  }
}

/**
1. resetTimer needs to check the expiration time each time
2. if within 5 mins of expiring, extend the session
3. if the window is active within 3 mins of expiration, display the Countdown Modal
4. if window is not active within 2 mins of expiration, log user out
 */

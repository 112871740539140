//==============================================================================
// Internal:
import { fetchWithPost } from "../../utils/fetchAPI";
import { getAgents, setAgent } from "../slices/agents.slice";
import { setFlashMessage } from "../slices/ui.slice";
import { getUserId } from "../slices/user.slice";
import environment from "../../utils/env";
import { setPriorityOnCreate } from "../effectUtils/effectUtils";
//==============================================================================

export const createNewAgentEffect = async (action, { dispatch, getState }) => {
  try {
    const { navigate, ...newAgentInfo } = action.payload;
    const agents = getAgents(getState());
    const userId = getUserId(getState());

    if (newAgentInfo.doNotQuery) {
      newAgentInfo.priority = null;
    } else {
      const nextPriority = setPriorityOnCreate(agents)
      newAgentInfo.priority = nextPriority;
    }

    const newAgent = await fetchWithPost(
      `${environment.dbBaseURL}/agents`,
      { ...newAgentInfo, userId },
    );

    dispatch(setAgent(newAgent));
    navigate(`/project/${newAgentInfo.projectId}/agents`)
  } catch (error) {
    const parsedError = JSON.parse(error)
    dispatch(setFlashMessage({ type: 'error', message: parsedError.message }));
    console.error('Error in CreateNewAgent Effect:', error);
  }
};
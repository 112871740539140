//==============================================================================
// External:
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Inetnal:
import Quill from '../../assets/quill.svg'
//==============================================================================

const ErrorPage = () => {
  const navigate = useNavigate();

  const onReturnHome = () => {
    navigate('/');
    navigate(0);
  }

  // const onPreviousPage = () => {
  //   navigate(-1);
  // }

  return (
    <div className='ErrorPage__container'>
      <img src={Quill} className='ErrorPage__icon'/>
      <div className='ErrorPage__title'>
        400
      </div>
      <div className='ErrorPage__text'>
        <p>
          We're sorry, but something has gone wrong.
        </p>
        <p>
          While we look into this, please restart the application.
        </p>
      </div>
      <div className='ErrorPage__buttonContainer'>
        <button
          className='ErrorPage__returnHomeBtn'
          onClick={() => onReturnHome()}
        >
          Return Home & Reload
        </button>
        {/* <button
          className='ErrorPage__previousPageBtn'
          onClick={() => onPreviousPage()}
        >
          Previous Page
        </button> */}
      </div>
    </div>
  )
}

export default ErrorPage;
//==============================================================================
// External:
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Internal:
import {
  hasAddedChapters,
  hasAddedEmailSubject,
  hasAddedQueryText,
  hasAddedQueryTitle,
  hasAddedSubstitutions,
  hasAddedSynopsis,
  hasCompletedQueryMgrInfo
} from '../../../../utils/utils';
//==============================================================================

export const getChecklistProgress = (queryTemplate, substitutions) => {
  const progressCheck = {};
  progressCheck.hasTitle = hasAddedQueryTitle(queryTemplate.title) ? true : false;
  progressCheck.hasEmailSubject = hasAddedEmailSubject(queryTemplate.emailSubjectFS) ? true : false;
  progressCheck.hasQueryText = hasAddedQueryText(queryTemplate.queryTemplateText, substitutions) ? true : false;
  progressCheck.hasSynopsis = hasAddedSynopsis(queryTemplate.synopsis)
  progressCheck.hasSubstitutions = hasAddedSubstitutions(substitutions) ? true : false;
  progressCheck.hasChapters = hasAddedChapters(queryTemplate.chapterOneText, queryTemplate.chapterTwoText, queryTemplate.chapterThreeText)
  progressCheck.hasCompletedQueryMgr = hasCompletedQueryMgrInfo(queryTemplate)
  return progressCheck;
};

export const displayIcon = (boolCheck, title) => {
  return (
    <div className="QueryTemplateStatus__iconContainer">
      <div className="QueryTemplateStatus__iconTitle">
        {title}
      </div>
      {
        boolCheck ? <FontAwesomeIcon
          className={`QueryTemplateStatus__checkMark`}
          icon="fa-check-circle"
        />
        : <FontAwesomeIcon
            className={`QueryTemplateStatus__minus`}
            icon="fa-minus-circle"
          />
      }
    </div>
  )
}
//===================================================================================
// External
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Internal
import { gmailUpdateQuerySentToAgent } from '../../store/actions/actions';
import {
  useGetAgents,
  useGetQueries,
} from '../../hooks/commonHooks';
//===================================================================================

const useQueryFinalReviewHooks = () => {
  const { queryId } = useParams();
  const queries = useGetQueries();
  const query = queries[queryId];
  const agents = useGetAgents();
  const agent = agents[query.agentId];
  const [finalEmailHTML, setFinalEmailHTML] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // const finalEmail = convertTextToHTML(query.finalEmailText)
    // setFinalEmailHTML(finalEmail);

    // check if query has been sent to agent outside of Querier
    // (finalEmailText is set in the effect)
    dispatch(gmailUpdateQuerySentToAgent({navigate, query, setFinalEmailHTML}));
  }, []);

  return {
    agent,
    dispatch,
    finalEmailHTML,
    navigate,
    query,
  }
}

export default useQueryFinalReviewHooks;

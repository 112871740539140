//==============================================================================
// External:
import React from 'react';

// Internal:
import PageSubtitle from '../PageSubtitle/PageSubtitle';
import useQueryFinalEmailHooks from './QueryFinalEmail.hooks';
import { finalEmailFormat } from '../../utils/utils';
//==============================================================================

const QueryFinalEmail = ({
  agentId,
  emailSubject,
  fullHeight,
  finalEmailHTML,
}) => {

  const { agent, height } = useQueryFinalEmailHooks({agentId, fullHeight});

  return (
    <div className='QueryFinalEmail__container' >
      <PageSubtitle
        title={'Review Query Letter'}
        shouldShowQuestionMark
        tooltipKey={'queryManagerFinalText'}
      />
      <div className='QueryFinalEmail__finalEmail' style={{ height }}>
        {
          finalEmailFormat({
            emailAddress: agent?.email,
            emailSubject: emailSubject,
            emailBody: finalEmailHTML,
            queryFormat: agent?.queryFormat
          })
        }
      </div>
    </div>
  )
}

export default QueryFinalEmail;

//===================================================================================
// External
import React, { useEffect } from 'react';

// Internal
import Editor from '../../components/Editor/Editor';
import PageSubtitle from '../../components/PageSubtitle/PageSubtitle';
//===================================================================================

const PagesBuilder = ({ props }) => {
  const {
    chapterNum,
    localChapter1,
    localChapter2,
    localChapter3,
    setChapterNum,
    setLocalChapter1,
    setLocalChapter2,
    setLocalChapter3
  } = props;

  useEffect(() => {
    const { localChapter, setLocalChapter } = editorValues();
    setLocalChapter(localChapter);
  }, [chapterNum]);


  // event handlers:
  const onChangeHandler = (event) => {
    setChapterNum(event.target.value);
  };


  const editorValues = () => {
    let setLocalChapter, localChapter;

    if (chapterNum === '1') {
      setLocalChapter = setLocalChapter1;
      localChapter = localChapter1;
    }

    if (chapterNum === '2') {
      setLocalChapter = setLocalChapter2;
      localChapter = localChapter2;
    }

    if (chapterNum === '3') {
      setLocalChapter = setLocalChapter3;
      localChapter = localChapter3;
    }

    return { localChapter, setLocalChapter };
  }

  const editorComponent = () => {
    if (chapterNum === '1') {
      return <Editor setValue={setLocalChapter1} text={localChapter1} textType='Ch1'/>
    }

    if (chapterNum === '2') {
      return <Editor setValue={setLocalChapter2} text={localChapter2} textType='Ch2'/>
    }

    if (chapterNum === '3') {
      return <Editor setValue={setLocalChapter3} text={localChapter3} textType='Ch3'/>
    }
  }

  return (
    <div className="PagesBuilder__container">
      <div className="PagesBuilder__innerContainer" >
        <div className="PagesBuilder__dropdownContainer">
          <PageSubtitle title={'Select Chapter'} shouldShowQuestionMark tooltipKey={'pagesEditorDropdown'} />
          <select
            value={chapterNum}
            onChange={(event) => onChangeHandler(event)}
            className="PagesBuilder__dropdown"
          >
            <option value="1">Chapter One</option>
            <option value="2">Chapter Two</option>
            <option value="3">Chapter Three</option>
          </select>
        </div>
        <div className="PagesBuilder__editor">
          <PageSubtitle title={'Chapter Editor'} shouldShowQuestionMark tooltipKey={'pagesEditor'} />
          {editorComponent()}
        </div>
      </div>
    </div>
  )
}

export default PagesBuilder;

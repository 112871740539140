//==============================================================================
// External:
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Internal:
import { getQueryTemplates } from '../../store/slices/queryTemplates.slice';
//==============================================================================

const useSynopsisBuilderHooks = () => {
  const { synopsis: originalSynopsis = '' } = useSelector(getQueryTemplates);
  const [localSynopsis, setLocalSynopsis] = useState(originalSynopsis);

  return {
    localSynopsis,
    originalSynopsis,
    setLocalSynopsis,
  }
};

export default useSynopsisBuilderHooks;

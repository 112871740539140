//==============================================================================
// External:
import React from 'react';

// Internal:

//==============================================================================

const QueryFormatDropdown = ({setValue, value, shouldDisable}) => {
  const onChangeHandler = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className='Dropdown__container'>
      <select
        value={value}
        disabled={shouldDisable}
        onChange={(event) => onChangeHandler(event)}
        style={{height: '35px', marginRight: '10px'}} // cant get class as component is seperated out
      >
        <option value="">N/A</option>
        <option value="Email">Email</option>
        <option value="QueryManager">Query Manager</option>
        <option value="CustomForm">Custom Form</option>
      </select>
    </div>
  )
}

export default QueryFormatDropdown;

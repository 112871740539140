//==============================================================================
// Internal:
import { setLoading } from "../slices/ui.slice";
import { getUserDetails } from "../slices/user.slice";
import { convertTextToHTML } from "../../utils/utils";
import { updateQueryAPICall } from "../effectUtils/apiCalls";
import { setQuery } from "../slices/queries.slice";
import fetchAPI from "../../utils/fetchAPI";
import environment from "../../utils/env";
//==============================================================================

export const gmailUpdateQuerySentToAgent = async (action, { dispatch, getState }) => {
  try {
    dispatch(setLoading());
    const { navigate, query, setFinalEmailHTML } = action.payload;

    // send email:
    const returnedThread = await fetchAPI(
      `${environment.dbBaseURL}/mail/thread/${query.threadId}`,
    );

    if (
      !returnedThread.hasOwnProperty('errors')
      && returnedThread[0].labels.includes("SENT")
      && !query.dateSentToAgent
    ) {
      // update query:
      const updatedQuery = updateQueryAPICall(query, { dateSentToAgent: new Date() }, query._id)
      dispatch(setQuery(updatedQuery));
      navigate(`/query-thread/${query._id}`);
    } else {
      const finalEmailHTML = convertTextToHTML(query.finalEmailText);
      setFinalEmailHTML(finalEmailHTML);
    }
  } catch (error) {
    console.error('Error In The GmailUpdateQuerySentToAgent: ', error)
  } finally {
    dispatch(setLoading());
  }
};
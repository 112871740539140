//==============================================================================
// Internal:
import { fetchWithPost } from "../../utils/fetchAPI";
import { getQuery, setQuery } from "../slices/queries.slice";
import { setFlashMessage, setModalContent } from "../slices/ui.slice";
import { setSubstitutionEntries } from "../slices/substitutionEntries.slice";
import { gmailUpdateDraft } from "../actions/actions";
import { hasExistingGmailDraft, makeObjFromArray, objHasData } from "../../utils/utils";
import {
  APP_USER_APPROVE_UPDATE_DRAFT,
  APP_USER_CANCEL_UPDATE_DRAFT
} from "../../utils/actionConstants";
import environment from "../../utils/env";
//==============================================================================

export const updateQueryEffect = async (action, { dispatch, getState, take }) => {
  try {
    const { navigate, substitutionEntries = {}, ...newQueryInfo } = action.payload;
    const {existingSubstitutionEntriesFS, newSubstitutionEntriesFS} = substitutionEntries;
    const existingQueryInfo = getQuery(newQueryInfo.queryId)(getState());

    // update query:
    const url = `${environment.dbBaseURL}/queries/${newQueryInfo.queryId}`;
    const queryBody = { ...existingQueryInfo, ...newQueryInfo };
    const updatedQuery = await fetchWithPost(url, queryBody, 'PUT');
    dispatch(setQuery(updatedQuery));

    // update substitution entries:
    if (existingSubstitutionEntriesFS && objHasData(existingSubstitutionEntriesFS)) {
      const substitutionEntriesArray = Object.values(existingSubstitutionEntriesFS).reduce((accumulator, subEntry) => {
        if (!subEntry.queryId) subEntry.queryId = newQueryInfo.queryId;
        accumulator.push(subEntry)
        return accumulator
      }, []);

      const substitutionEntryURL = `${environment.dbBaseURL}/updateSubstitutionEntriesBatch`;
      const substitutionEntryBody = { substitutionEntriesArray };
      const returnedSubstitutionEntries = await fetchWithPost(substitutionEntryURL, substitutionEntryBody, 'PUT');
      const returnedSubstitutionEntriesObj = makeObjFromArray(returnedSubstitutionEntries);
      dispatch(setSubstitutionEntries(returnedSubstitutionEntriesObj));
    }

    if (newSubstitutionEntriesFS && objHasData(newSubstitutionEntriesFS)) {
      const substitutionEntriesArray = Object.values(newSubstitutionEntriesFS).reduce((accumulator, subEntry) => {
        if (!subEntry.queryId) subEntry.queryId = newQueryInfo.queryId;
        accumulator.push(subEntry)
        return accumulator
      }, []);

      const substitutionEntryURL = `${environment.dbBaseURL}/substitutionEntriesBatch`;
      const substitutionEntryBody = { substitutionEntriesArray };
      const returnedSubstitutionEntries = await fetchWithPost(substitutionEntryURL, substitutionEntryBody, 'POST');
      const returnedSubstitutionEntriesObj = makeObjFromArray(returnedSubstitutionEntries);
      dispatch(setSubstitutionEntries(returnedSubstitutionEntriesObj));
    }

    if (hasExistingGmailDraft(updatedQuery)) {
      dispatch(setModalContent('gmailUpdateDraft'));

      const actionsArray = [
        APP_USER_APPROVE_UPDATE_DRAFT,
        APP_USER_CANCEL_UPDATE_DRAFT,
        'ui/setModalContent'
      ];

      const [newAction, ...rest] = await take(action => actionsArray.includes(action.type));

      dispatch(setModalContent(''));

      if (newAction.type === APP_USER_APPROVE_UPDATE_DRAFT) {
        dispatch(gmailUpdateDraft({ ...action.payload }))
      }
    }

    dispatch(setFlashMessage({ type: 'success', message: 'Query Successfully Saved' }))
    navigate(`/project/${newQueryInfo.projectId}/queries`);
  } catch (error) {
    console.error('Error in the UpdateQueryEffect Effect', error)
    dispatch(setFlashMessage({ type: 'error', message: 'Unable To Submit Query Changes. Please Try Again.' }))
  }
};
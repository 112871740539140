//==============================================================================
// External:
import React from 'react';

// Internal:
import AgentSelect from '../../../AgentSelect/AgentSelect';
//==============================================================================

const SelectAgent = ({ agent, agentId, agents, preselectedQuery, setAgentId }) => {
  return (
    <div className='SelectAgent__container'>
      {
        preselectedQuery._id ?
          <div>
            <div><span>Selected Agent:</span></div> {`${agent.firstName} ${agent.lastName} | ${agent.agency}`}
          </div>
          : <AgentSelect
            agentId={agentId}
            agents={agents}
            setAgent={setAgentId}
          />
      }
    </div>
  )
}

export default SelectAgent;
//==============================================================================
// Internal:
import environment from "../../utils/env";
import fetchAPI from "../../utils/fetchAPI";
import { setSubstitutions } from "../slices/substitutions.slice";
//==============================================================================

export const getSubstitutionsEffect = async (action, {dispatch, getState}) => {
  const queryId = action.payload;
  const substitutions = await fetchAPI(`${environment.dbBaseURL}/queryTemplate/${queryId}/substitutions`);

  const substitutionsObj = substitutions.reduce((accumulator, substitution) => {
    accumulator[substitution._id] = substitution;
    return accumulator;
  }, {});

  dispatch(setSubstitutions(substitutionsObj));
};
//===================================================================================
// External
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tooltip';

// Internal
import IncreaseLineHeightIcon from '../../assets/line_height_increase.jpg'
import DecreaseLineHeightIcon from '../../assets/line_height_decrease.webp'
import IndentIcon from '../../assets/indent2.png';
import UnindentIcon from '../../assets/unindent2.png';
import UndoTextAlignment from '../../assets/text_align_undo.png';
//===================================================================================

const MenuItemButton = ({ className = '', icon, image, name, onClickHandler = () => { } }) => {
  const styles = {
    fontSize: '12px',
    maxWidth: '30%',
    whiteSpace: 'pre-wrap'
  }

  return (
    <div className='MenuItemButton__container'>
      <button
        onClick={onClickHandler}
        className={className}
        type='button'
        data-tooltip-id="my-tooltip"
        data-tooltip-content={`${name}`}
      >
        <Tooltip id="my-tooltip" style={styles} multiline />
        {icon && <FontAwesomeIcon icon={icon} className="MenuItemButton__icon" />}
        {image && <img src={image} />}
      </button>
    </div>
  )
}


const MenuItemDropdown = ({ editor, hasUndoneFonts, setHasUndoneFonts }) => {
  const [font, setFont] = useState('');

  const onFontChange = (event) => {
    const notUsedVar = (editor?.isActive('textStyle', { fontFamily: event.target.value }) ? 'is-active' : '');
    editor?.chain().focus().setFontFamily(event.target.value).run();
    setFont(event.target.value)
    if (hasUndoneFonts) setHasUndoneFonts(false);
  }

  useEffect(() => {
    if (hasUndoneFonts) setFont('')
  }, [hasUndoneFonts])

  return (
    <div className='MenuBarDropdown__container'>
      <select
        value={font}
        onChange={(event) => onFontChange(event)}
      >
        <option value="">Select Font</option>
        <option value="TimesNewRoman">Times New Roman</option>
        <option value="CourierNew">Courier New</option>
      </select>
    </div>
  )
}


const MenuBar = ({ editor }) => {
  const [hasUndoneFonts, setHasUndoneFonts] = useState(false);

  const onUndoFonts = () => {
    editor.chain().focus().unsetFontFamily().run()
    setHasUndoneFonts(true);
  }

  return (
    <div className='MenuBar__container'>
      <div className="MenuBar__buttonGroup">
        <MenuItemDropdown
          editor={editor}
          hasUndoneFonts={hasUndoneFonts}
          setHasUndoneFonts={setHasUndoneFonts}
        />
        <button
          onClick={() => onUndoFonts()}
          type='button'
        >
          Undo Fonts
        </button>
      </div>
      <div className="MenuBar__buttonGroup">
        <MenuItemButton
          name='Bold'
          icon='fa-bold'
          className={editor.isActive('bold') ? 'is-active' : ''}
          onClickHandler={() => editor.chain().focus().toggleBold().run()}
        />
        <MenuItemButton
          name='Italics'
          icon='fa-italic'
          className={editor.isActive('italize') ? 'is-active' : ''}
          onClickHandler={() => editor.chain().focus().toggleItalic().run()}
        />
        <MenuItemButton
          name='Underline'
          icon='fa-underline'
          className={editor.isActive('underline') ? 'is-active' : ''}
          onClickHandler={() => editor.chain().focus().toggleUnderline().run()}
        />
      </div>
      <div className="MenuBar__buttonGroup">
        <MenuItemButton
          name='Align Left'
          icon='fa-align-left'
          className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
          onClickHandler={() => editor.chain().focus().setTextAlign('left').run()}
        />
        <MenuItemButton
          name='Align Center'
          icon='fa-align-center'
          className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
          onClickHandler={() => editor.chain().focus().setTextAlign('center').run()}
        />
        <MenuItemButton
          name='Align Right'
          icon='fa-align-right'
          className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
          onClickHandler={() => editor.chain().focus().setTextAlign('right').run()}
        />
        <MenuItemButton
          name='Justify'
          icon='fa-align-justify'
          className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}
          onClickHandler={() => editor.chain().focus().setTextAlign('justify').run()}
        />
        <MenuItemButton
          name='Undo Text Alignment'
          image={UndoTextAlignment}
          className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}
          onClickHandler={() => editor.chain().focus().setTextAlign('justify').run()}
        />
      </div>
      <div className="MenuBar__buttonGroup">
        <MenuItemButton
          name='Double Line Height'
          image={IncreaseLineHeightIcon}
          className={editor.isActive({ lineHeight: '200%' }) ? 'is-active' : ''}
          onClickHandler={() => editor.chain().focus().setLineHeight('200%').run()}
        />
        <MenuItemButton
          name='Single Line Height'
          image={DecreaseLineHeightIcon}
          className={''}
          onClickHandler={() => editor.chain().focus().unsetLineHeight().run()}
        />
        <MenuItemButton
          name='Indent'
          image={IndentIcon}
          className={editor.isActive({ textIndent: '30px' }) ? 'is-active' : ''}
          onClickHandler={() => editor.chain().focus().setTextIndent('30px').run()}
        />
        <MenuItemButton
          name='Unindent'
          image={UnindentIcon}
          className={editor.isActive({ textIndent: '0px' }) ? 'is-active' : ''}
          onClickHandler={() => editor.chain().focus().unsetTextIndent().run()}
        />
      </div>
    </div >
  )
}

export default MenuBar;

//==============================================================================
/* External */
import {createSlice} from '@reduxjs/toolkit';
//==============================================================================

const initialState = {};

const queriesSlice = createSlice({
  name: 'queries',
  initialState,
  reducers: {
    createQuery(state, action) {
      const {id} = action.payload;
      return {
        ...state,
        [id]: { ...action.payload }
      }
    },
    setQueries(state, action) {
      return {
        ...action.payload
      }
    },
    setQuery(state, action) {
      const {_id} = action.payload;
      return {
        ...state,
        [_id]: { ...action.payload }
      }
    }
  },
});

// selectors:
export const getQueries = (state) => state.queries;
export const getQuery = (queryId) => (state) => state.queries[queryId];

// actions:
export const {createQuery, setQueries, setQuery} = queriesSlice.actions;

// reducer:
export default queriesSlice.reducer;

//==============================================================================
// External:
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Internal:
import { getUserId } from '../../store/slices/user.slice';
import { logout } from '../../store/actions/actions';
//==============================================================================

const PrivateRoute = ({children}) => {
  const userId = useSelector(getUserId);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!userId) {
    dispatch(logout({ navigate }));
    // return <Navigate to="/" replace />;
  }

  return children;
};


export default PrivateRoute;
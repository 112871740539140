//==============================================================================
// External:
import React from 'react';

// Internal:
import QuillImage from "../../assets/quill-black.svg";
//==============================================================================

const BetaAnnouncement = ({ }) => {
  return (
    <div className='BetaAnnouncement__container'>
      <div className='BetaAnnouncement__logoContainer'>
        <img src={QuillImage} className='BetaAnnouncement__logo' />
      </div>
      <div className='BetaAnnouncement__title'>Welcome to Querier!</div>
      <div className='BetaAnnouncement__text'>
        <p>We're in Beta testing mode. Some things may not work as intended.</p>
        <p>Please help us make Querier better by letting us know of any issues you encounter.</p>
        <p>Enjoy for free</p>
        <p>Now let's get querying!</p>
      </div>
      <div className="BetaAnnouncement__buttonContainer">
        <button>Leave Feedback</button>
      </div>
    </div>
  )
}

export default BetaAnnouncement;
//==============================================================================
// Internal:
import { fetchWithPost } from "../../utils/fetchAPI";
import { setQuery } from "../slices/queries.slice";
import { setSubstitutionEntries } from "../slices/substitutionEntries.slice";
import { setFlashMessage } from "../slices/ui.slice";
import { getUserId } from "../slices/user.slice";
import { makeObjFromArray } from "../../utils/utils";
import environment from "../../utils/env";
//==============================================================================

export const finalizeQueryEffect = async (action, { dispatch, getState, take }) => {
  try {
    const { navigate, ...finalQueryInfo } = action.payload;
    const userId = getUserId(getState());

    // save query:
    const queryURL = `${environment.dbBaseURL}/queries`;
    const queryBody = { ...finalQueryInfo, userId };
    const returnedQuery = await fetchWithPost(queryURL, queryBody);

    // save substitutionEntries:
    let returnedSubstitutionEntriesObj;
    const hasNewSubstitutions = Boolean(finalQueryInfo.substitutionEntries?.newSubstitutionEntriesFS);
    if (hasNewSubstitutions && Object.keys(finalQueryInfo?.substitutionEntries?.newSubstitutionEntriesFS).length > 0) {
      const substitutionEntriesArray = Object.values(finalQueryInfo.substitutionEntries.newSubstitutionEntriesFS).reduce((accumulator, subEntry) => {
        const { _id, ...dataToSave } = subEntry; // remove id from queryTemplate
        dataToSave.queryId = returnedQuery._id;
        accumulator.push(dataToSave)
        return accumulator
      }, []);
      const substitutionEntryURL = `${environment.dbBaseURL}/substitutionEntriesBatch`;
      const substitutionEntryBody = { substitutionEntriesArray };
      const returnedSubstitutionEntries = await fetchWithPost(substitutionEntryURL, substitutionEntryBody);
      returnedSubstitutionEntriesObj = makeObjFromArray(returnedSubstitutionEntries);
    }

    dispatch(setQuery(returnedQuery));
    dispatch(setSubstitutionEntries(returnedSubstitutionEntriesObj));
    dispatch(setFlashMessage({ type: 'success', message: 'Query Successfully Saved' }))
    navigate(`/project/${finalQueryInfo.projectId}/queries`);
  } catch (error) {
    console.error('Error in FinalizeQueryEffect: ', error)
  }
};
//==============================================================================
// External:
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Internal:
import QuestionDisplay from '../QuestionDisplay/QuestionDisplay';
//==============================================================================

const PageSubtitle = ({ onClickHandler, shouldShowAddIcon, shouldShowQuestionMark, title, tooltipKey }) => {
  return (
    <div className='PageSubtitle__container'>
      <div className='PageSubtitle__innerContainer'>
        <div className="PageSubtitle__title bold">
          {title}
        </div>
        <div className="PageSubtitle__questionIcon">
        {shouldShowQuestionMark && <QuestionDisplay tooltipKey={tooltipKey} />}
        </div>
      </div>
      {
        shouldShowAddIcon &&
        <div className='PageSubtitle__icon' onClick={() => onClickHandler()}>
          <FontAwesomeIcon icon="fa-plus-circle" className="PageSubtitle__plusIcon" />
        </div>
      }
    </div>
  )
}

export default PageSubtitle;
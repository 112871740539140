//==============================================================================
/* External */
import {createSlice} from '@reduxjs/toolkit';
//==============================================================================

const initialState = {};

const threadSlice = createSlice({
  name: 'thread',
  initialState,
  reducers: {
    setThread(state, action) {
      return {
        ...action.payload
      }
    },
    clearThread(state, action) {
      return initialState;
    }
  },
});

// selectors:
export const getThread = (state) => state.thread;

// actions:
export const {
  clearThread,
  setThread,
} = threadSlice.actions;

// reducer:
export default threadSlice.reducer;

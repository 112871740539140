//==============================================================================
// External:
import React from 'react';
import { useDispatch } from 'react-redux';

// Internal:
import {
  APP_DELETE_PROJECT,
  APP_KEEP_PROJECT,
} from '../../utils/actionConstants';
//==============================================================================

const DeleteProjectConfirmationModal = () => {
  const dispatch = useDispatch();

  const onDelete = () => {
    dispatch({type: APP_DELETE_PROJECT})
  }

  const onClose = () => {
    dispatch({type: APP_KEEP_PROJECT});
  }

  return (
    <div className='DeleteProjectConfirmationModal__container'>
      <div className='DeleteProjectConfirmationModal__textContainer'>
        <div>Are you sure you want to delete this project?</div>
        <div>Once it's been deleted, it can't be recovered.</div>
      </div>
      <div className='DeleteProjectConfirmationModal__buttonContainer'>
        <button
          className='DeleteProjectConfirmationModal__keepButton'
          onClick={() => onClose()}
        >
          Keep Project
        </button>
        <button
          className='DeleteProjectConfirmationModal__deleteButton'
          onClick={() => onDelete()}
        >
          Delete Project
        </button>
      </div>
    </div>
  )
}

export default DeleteProjectConfirmationModal;
//==============================================================================
// External:
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Internal:

//==============================================================================

const FAQCard = ({ customHt = null, text, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  const icon = isOpen ?
    <FontAwesomeIcon icon="fa-angle-down" />
    : <FontAwesomeIcon icon="fa-angle-up" />

  const textDisplay = isOpen &&
    <div className='FAQCard__text'>
      {text}
    </div>

  const styles = isOpen ? {height: customHt || '140px'} : {height: '100px'}

  return (
    <div className='FAQCard__container' onClick={() => setIsOpen(!isOpen)} style={styles}>
      <div className='FAQCard__titleContainer'>
        <div className='FAQCard__title'>
          {title}
        </div>
        <div className='FAQCard__arrowContainer'>
          {icon}
        </div>
      </div>
      { textDisplay }
    </div>
  )
}

export default FAQCard;
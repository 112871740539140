//==============================================================================
// External:
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Internal:
import { useGetProject } from '../../hooks/commonHooks';
import { getQueryTemplates } from '../../store/slices/queryTemplates.slice';

//==============================================================================


const useQueryManagerTemplateHooks = () => {
  const queryTemplate = useSelector(getQueryTemplates) || {};
  const [phoneNumber, setPhoneNumber] = useState(queryTemplate.phoneNumber || '');
  const [bookTitle, setBookTitle] = useState(queryTemplate.bookTitle || '');
  const [biography, setBiography] = useState(queryTemplate.biography || '');
  const [authorWebsite, setAuthorWebsite] = useState(queryTemplate.authorWebsite || '');
  const [authorBlog, setAuthorBlog] = useState(queryTemplate.authorBlog || '');
  const [authorTwitter, setAuthorTwitter] = useState(queryTemplate.authorTwitter || '');
  const [hasBeenPreviouslyPublished, setHasBeenPreviouslyPublished] = useState(queryTemplate.hasBeenPreviouslyPublished ? true : false);
  const [hasBeenPreviouslyRepd, setHasBeenPreviouslyRepd] = useState(queryTemplate.hasBeenPreviouslyRepd ? true : false);
  const [referral, setReferral] = useState(queryTemplate.referral || '');
  const [genre, setGenre] = useState(queryTemplate.genre || '');
  const [wordCount, setWordCount] = useState(queryTemplate.wordCount || 0);
  const [bookPreviouslyPublished, setBookPreviouslyPublished] = useState(queryTemplate.bookPreviouslyPublished ? true : false);
  const [pitch, setPitch] = useState(queryTemplate.pitch || '');
  const [targetAudience, setTargetAudience] = useState(queryTemplate.targetAudience || '');
  const [similarBooks, setSimilarBooks] = useState(queryTemplate.similarBooks || '');

  return {
    authorBlog,
    authorTwitter,
    authorWebsite,
    biography,
    bookPreviouslyPublished,
    bookTitle,
    genre,
    hasBeenPreviouslyPublished,
    hasBeenPreviouslyRepd,
    originalQueryTemplate: queryTemplate,
    phoneNumber,
    pitch,
    referral,
    setAuthorBlog,
    setAuthorTwitter,
    setAuthorWebsite,
    setBiography,
    setBookPreviouslyPublished,
    setBookTitle,
    setGenre,
    setHasBeenPreviouslyPublished,
    setHasBeenPreviouslyRepd,
    setPhoneNumber,
    setPitch,
    setReferral,
    setSimilarBooks,
    setTargetAudience,
    setWordCount,
    similarBooks,
    targetAudience,
    wordCount,
  }
}

export default useQueryManagerTemplateHooks;

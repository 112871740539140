//==============================================================================
// External:
import React from 'react';

// Internal:

//==============================================================================

const CookiePolicy = ({ }) => {
  return (
    <div className='CookiePolicy__container'>
      <h3 class="western">COOKIE POLICY</h3>
      <p><strong>Last updated July 13, 2024</strong></p>
      <p>&nbsp;</p>
      <p>This Cookie Policy explains how Querier LLC ("Company," "we," "us," and "our") uses cookies and similar technologies to recognize you when you visit our website at http://querier.net ("Website"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>
      <h4 class="western">What are cookies?</h4>
      <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>
      <p>Cookies are set by the Company are called "first-party cookies." Cookies set by parties other than the website owner are called "third-party cookies." Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., authentication, payment processing, or analytics). The parties that set these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.</p>
      <h4 class="western">Why do we use cookies?</h4>
      <p>Some cookies are required in order for our Website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Third parties may serve cookies through our Website to enable authentication, analytics, or other features.</p>
      <p>The Company itself uses first-party, &ldquo;essential&rdquo; cookies only in order to enhance user security and manage session expiration. These cookies expire with user logout or when the expiration time has been exceeded. A session has an initial expiration time of seven days but can automatically be extended through site use (&ldquo;rolling session&rdquo;). Additional third-party cookies may be used on the site, including those from Google (login) and Stripe.</p>
      <h4 class="western">How can I control cookies?</h4>
      <p>You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.</p>
      <p>The specific types of first- and third-party cookies served through our Website and the purposes they perform are described in the table below (please note that the specific cookies served may vary depending on the specific Online Properties you visit):</p>
      <p>&nbsp;</p>
      <p><strong>Querier</strong>: These cookies are set by Querier itself to enable session management.</p>
      <table width="80%" cellspacing="0" cellpadding="0" className="Policy__table">
        <tbody>
          <tr valign="top">
            <td width="33%" className="Policy__background">
              <p>Cooke Name</p>
            </td>
            <td width="36%" className="Policy__background">
              <p>Duration</p>
            </td>
            <td width="30%" className="Policy__background">
              <p>Purpose</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>session</p>
            </td>
            <td width="36%">
              <p>7 day minimum (&ldquo;rolling session&rdquo;)</p>
            </td>
            <td width="30%">
              <p>Session management</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>session.sig</p>
            </td>
            <td width="36%">
              <p>7 day minimum (&ldquo;rolling session&rdquo;)</p>
            </td>
            <td width="30%">
              <p>Verifies that Querier sent the session token</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p><strong>Google Login:</strong> These cookies are set by Google to enable you to log in to using your Google account.</p>
      <p>&nbsp;</p>
      <table width="80%" cellspacing="0" cellpadding="0" className="Policy__table">
        <tbody>
          <tr valign="top">
            <td width="33%" className="Policy__background">
              <p>Cooke Name</p>
            </td>
            <td width="36%" className="Policy__background">
              <p>Duration</p>
            </td>
            <td width="30%" className="Policy__background">
              <p>Purpose</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>g_state<br /> </p>
            </td>
            <td width="36%">
              <p>6 Months</p>
            </td>
            <td width="30%">
              <p>These cookies are set by Google to enable you to log in to using your Google account.</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>__Host-xxxx</p>
            </td>
            <td width="36%">
              <p>13 Months</p>
            </td>
            <td width="30%">
              <p>&nbsp;</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p><strong>Stripe: </strong>These are cookies set by Stripe. They are used to remember who you are and enable the website to process payments without storing your credit card information. To learn more about Stripe, see&nbsp;<a href="https://stripe.com/cookie-settings" target="_blank">here</a>. Used for fraud detection purposes and to help assess the risk associated with an attempted transaction.</p>
      <p>&nbsp;</p>
      <table width="80%" cellspacing="0" cellpadding="0" className="Policy__table">
        <tbody>
          <tr valign="top">
            <td width="33%" className="Policy__background">
              <p>Cooke Name</p>
            </td>
            <td width="36%" className="Policy__background">
              <p>Duration</p>
            </td>
            <td width="30%" className="Policy__background">
              <p>Purpose</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>__stripe_mid</p>
            </td>
            <td width="36%">
              <p>1 Year</p>
            </td>
            <td width="30%">
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>__stripe_sid</p>
            </td>
            <td width="36%">
              <p>30 Minutes</p>
            </td>
            <td width="30%">
              <p>&nbsp;</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <h4 class="western">How can I control cookies on my browser?</h4>
      <p>As the means by which you can refuse cookies through your web browser controls vary from browser to browser, you should visit your browser's help menu for more information. The following is information about how to manage cookies on the most popular browsers:</p>
      <ul>
        <li>
          <p><a href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies">Chrome</a></p>
        </li>
        <li>
          <p><a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&amp;redirectlocale=en-US">Firefox</a></p>
        </li>
        <li>
          <p><a href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac">Safari</a></p>
        </li>
        <li>
          <p><a href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">Edge</a></p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to find out more information, please visit:</p>
      <ul>
        <li>
          <p><a href="https://optout.aboutads.info/?c=2&amp;lang=EN">Digital Advertising Alliance</a></p>
        </li>
        <li>
          <p><a href="https://youradchoices.ca/">Digital Advertising Alliance of Canada</a></p>
        </li>
        <li>
          <p><a href="https://www.youronlinechoices.com/">European Interactive Digital Advertising Alliance</a></p>
        </li>
      </ul>
      <h4 class="western">What about other tracking technologies?</h4>
      <p>Cookies are not the only way to recognize or track visitors to a website. Web beacons are tiny graphics files that contain a unique identifier that enables us to recognize when someone has visited our Website or opened an email including them. "Flash Cookies" (also known as Local Shared Objects or "LSOs") allow the collection of storage of information about your use of services, fraud prevention, and for other site operations. Querier does not use web beacons, Local Shared Objects, or other tracking technologies.</p>
      <h4 class="western">How often will this Cookie Policy be updated?</h4>
      <p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please therefore revisit this Cookie Policy regularly to stay informed about our use of cookies and related technologies. The date at the top of this Cookie Policy indicates when it was last updated.</p>
      <h4 class="western">Need more information?</h4>
      <p>You can read more about how we use your information in our <a href="https://querier.net/privacy-policy/" target="_blank"><u>Privacy Policy</u></a>.</p>
      <h4 class="western">Where can I get further information?</h4>
      <p>If you have any questions about our use of cookies or other technologies, please contact us at:</p>
      <p>Querier LLC</p>
      <p>123 Elm Street</p>
      <p>Atlanta, GA 30067</p>
      <p>United States</p>
    </div>
  )
}

export default CookiePolicy;
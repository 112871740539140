//==============================================================================
// External:
import React from 'react';

// Internal:
import useFlashMessage from './FlashMessage.hooks';
//==============================================================================

const FlashMessage = () => {
  const { flashMessage: { type, message } } = useFlashMessage();

  return (
    <>
      {
        message &&
        <div className={`FlashMessage__container`}>
          <div className={`FlashMessage__content ${type}`}>
            {message}
          </div>
        </div>
      }
    </>
  )
}

export default FlashMessage;
//==============================================================================
// External:
import React from 'react';

// Internal:

//==============================================================================

const QueryMgrGenreDropdown = ({ setValue, value }) => {

  const onChangeHandler = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      <select
        value={value}
        onChange={(event) => onChangeHandler(event)}
      >
        <optgroup label="Fiction">
          <option value="24">Action/Adventure </option>
          <option value="25">Chick Lit </option>
          <option value="26">Childrens </option>
          <option value="27">Commercial </option>
          <option value="28">Contemporary </option>
          <option value="29">Crime/Detective/Police </option>
          <option value="31">Family Saga </option>
          <option value="32">Fantasy </option>
          <option value="34">LGBTQ+ </option>
          <option value="35">Graphic Novel </option>
          <option value="36">Historical </option>
          <option value="37">Humor/Satire </option>
          <option value="45">Inspirational </option>
          <option value="38">Literary </option>
          <option value="84">Middle Grade </option>
          <option value="39">Middle Grade: Contemporary </option>
          <option value="112">Middle Grade: Fantasy </option>
          <option value="83">Middle Grade: Historical </option>
          <option value="88">Middle Grade: Literary </option>
          <option value="82">Middle Grade: Mystery </option>
          <option value="1982">Middle Grade: Urban </option>
          <option value="40">Military/Espionage </option>
          <option value="41">Multicultural </option>
          <option value="42">Mystery </option>
          <option value="122">New Adult </option>
          <option value="43">Offbeat/Quirky </option>
          <option value="89">Picture Book </option>
          <option value="44">Religious </option>
          <option value="46">Romance </option>
          <option value="96">Romance: Category </option>
          <option value="92">Romance: Contemporary </option>
          <option value="93">Romance: Historical </option>
          <option value="94">Romance: Paranormal </option>
          <option value="95">Romance: Thriller/Suspense </option>
          <option value="50">Suspense </option>
          <option value="49">Thriller </option>
          <option value="211">Upmarket </option>
          <option value="51">Western </option>
          <option value="2">Women's Fiction </option>
          <option value="123">Young Adult </option>
          <option value="91">Young Adult: Christian </option>
          <option value="1">Young Adult: Contemporary </option>
          <option value="113">Young Adult: Fantasy </option>
          <option value="80">Young Adult: Historical </option>
          <option value="87">Young Adult: Literary </option>
          <option value="79">Young Adult: Mystery </option>
          <option value="97">Young Adult: Paranormal Romance </option>
          <option value="78">Young Adult: Romance </option>
        </optgroup>
        <optgroup label="Non-Fiction">
          <option value="3">Biography </option>
          <option value="10">Business </option>
          <option value="12">Celebrity </option>
          <option value="4">Cookbook </option>
          <option value="17">Cultural/Social Issues </option>
          <option value="18">Current Affairs </option>
          <option value="11">Finance </option>
          <option value="20">Food </option>
          <option value="22">Gardening </option>
          <option value="56">Gift Book </option>
          <option value="60">Health &amp; Fitness </option>
          <option value="6">History </option>
          <option value="53">Home Decorating &amp; Design </option>
          <option value="54">How To </option>
          <option value="55">Humor </option>
          <option value="57">Journalism </option>
          <option value="58">Juvenile </option>
          <option value="21">Lifestyle </option>
          <option value="52">Military </option>
          <option value="61">Multicultural </option>
          <option value="63">Nature &amp; Ecology </option>
          <option value="64">Parenting </option>
          <option value="65">Pets </option>
          <option value="8">Photography </option>
          <option value="19">Politics </option>
          <option value="66">Psychology </option>
          <option value="67">Relationship &amp; Dating </option>
          <option value="68">Religion </option>
          <option value="70">Science &amp; Technology </option>
          <option value="71">Self-Help </option>
          <option value="69">Spirituality </option>
          <option value="72">Sports </option>
          <option value="73">Travel </option>
          <option value="76">Women's Issues </option>
        </optgroup>
      </select>
    </>
  )
}

export default QueryMgrGenreDropdown;

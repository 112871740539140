//==============================================================================
//==============================================================================
// External:
import React from "react";
import { Link } from "react-router-dom";

// Internal:
import QuillImage from "../../assets/quill.svg";
//==============================================================================


const Footer = () => (
  <footer className="Footer__container">
    <div className="Footer__logoContainer">
      <img src={QuillImage} className="Footer__logo" />
      Querier
    </div>
    <div className="Footer__links">
      <Link to="/">Home</Link>
      <Link to="/about">How Querier Works</Link>
      <Link to="/knowledge-center">Knowledge Center</Link>
      <Link to="/privacy-policy">Privacy Policy</Link>
      <Link to="/terms-of-use">Terms of Use</Link>
      <Link to="/cookie-policy">Cookie Policy</Link>
    </div>
  </footer>
);

export default Footer;

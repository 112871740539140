//==============================================================================
// External:
import React from 'react';

// Internal:
//==============================================================================

const QueryStatusDropdown = ({value, onChangeHandler }) => {
  return (
    <div className="QueryThread__dropdownContainer">
      <label>Query Status:</label>
      <select
        className="Dropdown__container"
        defaultValue={'Select Query Status'}
        onChange={(event) => onChangeHandler(event.target.value)}
        value={value}
      >
        <option value="" disabled selected>Set Query Status</option>
        <optgroup label="Query Remains Open">
          <option value="waiting">Waiting</option>
          <option value="manuscript-request-full">Manuscript Request - Full</option>
          <option value="manuscript-request-partial">Manuscript Request - Partial</option>
          <option value="revise-resubmit">Revise & Resubmit</option>
        </optgroup>
        <optgroup label="Query Closed">
          <option value="rejection-form">Form Rejection</option>
          <option value="rejection-personal">Personal Rejection</option>
          <option value="representation-offer">Offer of Representation</option>
          <option value="no-response">No Response</option>
        </optgroup>
      </select>
    </div>
  )
}

export default QueryStatusDropdown;

//==============================================================================
// External:
import React from 'react';
import { useDispatch } from 'react-redux';

// Internal:
import {
  APP_USER_APPROVE_UPDATE_DRAFT,
  APP_USER_CANCEL_UPDATE_DRAFT
} from '../../utils/actionConstants';
//==============================================================================

const GmailUpdateDraftModal = ({ }) => {
  const dispatch = useDispatch();

  const onSendEmailToAgent = () => {
    dispatch({ type: APP_USER_APPROVE_UPDATE_DRAFT})
  }

  const onClose = () => {
    dispatch({ type: APP_USER_CANCEL_UPDATE_DRAFT })
  }

  return (
    <div className='GmailUpdateDraftModal__container'>
      <div className='GmailUpdateDraftModal__textContainer'>
        <div className='GmailUpdateDraftModal__text'>
          This query has an existing draft in your Gmail 'Drafts' folder.
          Would you also like to update this draft?
        </div>
      </div>
      <div className='GmailUpdateDraftModal__buttonContainer'>
        <button
          className='GmailUpdateDraftModal__confirmButton'
          onClick={() => onSendEmailToAgent()}
        >
          Yes, Update Draft
        </button>
        <button
          className='GmailUpdateDraftModal__closeButton'
          onClick={() => onClose()}
        >
          Don't Update Draft
        </button>
      </div>
    </div>
  )
}

export default GmailUpdateDraftModal;

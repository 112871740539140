//===================================================================================
// External
import React from 'react';


// Internal
import Editor from '../../components/Editor/Editor';
//===================================================================================

const SynopsisBuilder = ({props}) => {
  const {
    localSynopsis,
    setLocalSynopsis,
  } = props;

  return (
    <div className="SynopsisBuilder__container">
      <div className="SynopsisBuilder__innerContainer">
        <Editor setValue={setLocalSynopsis} text={localSynopsis} />
      </div>
    </div>
  )
}

export default SynopsisBuilder;

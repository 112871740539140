//==============================================================================
// External:
import React from 'react';

// Internal:
import environment from '../../utils/env';
//==============================================================================

const PrivacyPolicy = () => {
  return (
    <div className='PrivacyPolicy__container'>
      <h2 class="western">PRIVACY POLICY</h2>
      <p>This privacy policy for Querier LLC ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:</p>
      <ul>
        <li>
          <p>Visit our website at https://querier.net, or any website of ours that links to this privacy notice</p>
        </li>
        <li>
          <p>Engage with us in other related ways, including any sales, marketing, or events</p>
        </li>
      </ul>
      <p>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, do not use our Services. If you still have any questions or concerns, please contact us at <a href="mailto:admin@querier.com">admin@querier.com</a>.</p>
      <h4 class="western">TABLE OF CONTENTS</h4>
      <p><strong><a href={`${environment.uiBaseURL}/privacy-policy#1`}>1. WHAT INFORMATION DO WE COLLECT?</a></strong></p>
      <p><strong>2. <a href={`${environment.uiBaseURL}/privacy-policy#2`}>HOW DO WE PROCESS YOUR INFORMATION?</a></strong></p>
      <p><strong><a href={`${environment.uiBaseURL}/privacy-policy#3`}>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a></strong></p>
      <p><strong><a href={`${environment.uiBaseURL}/privacy-policy#4`}>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></strong></p>
      <p><strong><a href={`${environment.uiBaseURL}/privacy-policy#5`}>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></strong></p>
      <p><strong><a href={`${environment.uiBaseURL}/privacy-policy#6`}>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></strong></p>
      <p><strong><a href={`${environment.uiBaseURL}/privacy-policy#7`}>7. HOW LONG DO WE KEEP YOUR INFORMATION?</a></strong></p>
      <p><strong><a href={`${environment.uiBaseURL}/privacy-policy#8`}>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</a></strong></p>
      <p><strong><a href={`${environment.uiBaseURL}/privacy-policy#9`}>9. DO WE COLLECT INFORMATION FROM MINORS?</a></strong></p>
      <p><strong><a href={`${environment.uiBaseURL}/privacy-policy#10`}>10. WHAT ARE YOUR PRIVACY RIGHTS?</a></strong></p>
      <p><strong><a href={`${environment.uiBaseURL}/privacy-policy#11`}>11. CONTROLS FOR DO-NOT-TRACK FEATURES</a></strong></p>
      <p><strong><a href={`${environment.uiBaseURL}/privacy-policy#12`}>12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></strong></p>
      <p><strong><a href={`${environment.uiBaseURL}/privacy-policy#13`}>13. DO WE MAKE UPDATES TO THIS NOTICE?</a></strong></p>
      <p><strong><a href={`${environment.uiBaseURL}/privacy-policy#14`}>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></strong></p>
      <p><strong><a href={`${environment.uiBaseURL}/privacy-policy#15`}>15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></strong></p>
      <p>&nbsp;</p>
      <h4 class="western" id='1'>1. WHAT INFORMATION DO WE COLLECT?</h4>
      <h5 class="western">Personal information you disclose to us:</h5>
      <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
      <p><strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
      <ul>
        <li>
          <p>names</p>
        </li>
        <li>
          <p>email addresses</p>
        </li>
        <li>
          <p>usernames</p>
        </li>
      </ul>
      <p><strong>Sensitive Information.</strong> We do not process sensitive information.</p>
      <p><strong>Payment Data. </strong>We may collect data necessary to process your payment if you choose to make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is handled and stored by Stripe. You may find their privacy notice link(s) here: <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>.</p>
      <p><strong>Social Media Login Data. </strong>Users must register for and access Querier through social media accounts (ie, Google), allowing us to collect certain profile information about you, as described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below. All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
      <h4 class="western">Information automatically collected</h4>
      <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your contact information or phone number) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
      <p>Like many businesses, we also collect information through cookies and similar technologies. You can find out more about this in our <a href="https://www.querier.com/cookie-policy">Cookie Policy</a>.</p>
      <p>The information we collect includes:</p>
      <ul>
        <li>
          <p><strong>Log and Usage Data. </strong>Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).</p>
        </li>
        <li>
          <p><strong>Device Data.</strong> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</p>
        </li>
        <li>
          <p><strong>Location Data.</strong> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</p>
        </li>
      </ul>
      <h4 class="western">2. HOW DO WE PROCESS YOUR INFORMATION?</h4>
      <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
      <ul>
        <li>
          <p>To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</p>
        </li>
        <li>
          <p>To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual&rsquo;s vital interest, such as to prevent harm.</p>
        </li>
      </ul>
      <h4 class="western">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h4>
      <p>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</p>
      <p><strong>If you are located in the EU or UK. </strong>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</p>
      <p>Consent. We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about withdrawing your consent.</p>
      <p><strong>Legal Obligations. </strong>We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</p>
      <p><strong>I</strong><strong>f you are located in Canada.</strong> We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can&nbsp;withdraw your consent at any time.</p>
      <p>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</p>
      <ul>
        <li>
          <p>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</p>
        </li>
        <li>
          <p>For investigations and fraud detection and preventionFor business transactions provided certain conditions are met</p>
        </li>
        <li>
          <p>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</p>
        </li>
        <li>
          <p>For identifying injured, ill, or deceased persons and communicating with next of kin</p>
        </li>
        <li>
          <p>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</p>
        </li>
        <li>
          <p>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</p>
        </li>
        <li>
          <p>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</p>
        </li>
        <li>
          <p>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</p>
        </li>
        <li>
          <p>If the collection is solely for journalistic, artistic, or literary purposesIf the information is publicly available and is specified by the regulations</p>
        </li>
      </ul>
      <h4 class="western">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>
      <p>We may need to share your personal information in the following situations:</p>
      <p><strong>Business Transfers. </strong>We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>
      <h4 class="western">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
      <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.</p>
      <p>We also permit third parties and service providers to use technologies on our Services to assist with analytics, debugging, logging and conducting research</p>
      <p>Specific information about how we use such technologies and how you can refuse certain cookies is set out in our <a href="http://www.querier.com/cookie-policy">Cookie Policy</a>.</p>
      <h4 class="western">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h4>
      <p>Our Services offer you the ability to register and log in using your third-party social media account details (Google). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.</p>
      <p>We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.</p>
      <h4 class="western">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
      <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>
      <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
      <h4 class="western">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
      <p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
      <h4 class="western">9. DO WE COLLECT INFORMATION FROM MINORS?</h4>
      <p>We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent&rsquo;s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <a href="mailto:admin@querier.com">admin@querier.com</a>.</p>
      <h4 class="western">10. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
      <p>You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.</p>
      <p>In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</p>
      <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>
      <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or&nbsp;UK data protection authority.</p>
      <p>If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.</p>
      <p><strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</p>
      <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
      <h6 class="western">Account Information</h6>
      <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
      <ul>
        <li>
          <p>Log in to your account settings and update your user account.</p>
        </li>
      </ul>
      <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>
      <p><strong>Cookies and similar technologies: </strong>Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services, prevent security measures, and even render the service unusable. For further information, please see our Cookie Notice: <a href="http://www.querier.com/cookie-policy">http://www.querier.com/cookie-policy</a>.</p>
      <p>If you have questions or comments about your privacy rights, you may email us at <a href="mailto:admin@querier.com">admin@querier.com</a>.</p>
      <h4 class="western">11. CONTROLS FOR DO-NOT-TRACK FEATURES:</h4>
      <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
      <p>California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognizing or honoring DNT signals, we do not respond to them at this time.</p>
      <h4 class="western">12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4>
      <p>If you are a resident of Florida, you may have the right to request access to and receive details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. More information is provided below.</p>
      <h5 class="western">Categories of Personal Information We Collect</h5>
      <p>We have collected the following categories of personal information in the past twelve (12) months:</p>
      <table width="100%" cellspacing="0" cellpadding="0" className="Policy__table">
        <tbody>
          <tr valign="top">
            <td width="33%">
              <p>Category</p>
            </td>
            <td width="33%">
              <p>Examples</p>
            </td>
            <td width="33%">
              <p>Collected</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>A. Identifiers</p>
            </td>
            <td width="33%">
              <p>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</p>
            </td>
            <td width="33%">
              <p>YES</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>B. Protected classification characteristics under state or federal law</p>
            </td>
            <td width="33%">
              <p>Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data</p>
            </td>
            <td width="33%">
              <p>NO</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>C. Commercial information</p>
            </td>
            <td width="33%">
              <p>Transaction information, purchase history, financial details, and payment information</p>
            </td>
            <td width="33%">
              <p>YES</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>D. Biometric information</p>
            </td>
            <td width="33%">
              <p>Fingerprints and voiceprints</p>
            </td>
            <td width="33%">
              <p>NO</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>E. Internet or other similar network activity</p>
            </td>
            <td width="33%">
              <p>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</p>
            </td>
            <td width="33%">
              <p>NO</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>F. Geolocation data</p>
            </td>
            <td width="33%">
              <p>Device location, IP addresses</p>
            </td>
            <td width="33%">
              <p>YES</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>G. Audio, electronic, sensory, or similar information</p>
            </td>
            <td width="33%">
              <p>Images and audio, video or call recordings created in connection with our business activities</p>
            </td>
            <td width="33%">
              <p>NO</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>H. Professional or employment-related information</p>
            </td>
            <td width="33%">
              <p>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</p>
            </td>
            <td width="33%">
              <p>NO</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>I. Education Information</p>
            </td>
            <td width="33%">
              <p>Student records and directory information</p>
            </td>
            <td width="33%">
              <p>NO</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>J. Inferences drawn from collected personal information</p>
            </td>
            <td width="33%">
              <p>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual&rsquo;s preferences and characteristics</p>
            </td>
            <td width="33%">
              <p>NO</p>
            </td>
          </tr>
          <tr valign="top">
            <td width="33%">
              <p>K. Sensitive personal Information</p>
            </td>
            <td width="33%">
              <p>&nbsp;</p>
            </td>
            <td width="33%">
              <p>NO</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</p>
      <ul>
        <li>
          <p>Receiving help through our customer support channels;</p>
        </li>
        <li>
          <p>Participation in customer surveys or contests; and</p>
        </li>
        <li>
          <p>Facilitation in the delivery of our Services and to respond to your inquiries.</p>
        </li>
      </ul>
      <p>We will use and retain the collected personal information as needed to provide the Services or for:</p>
      <ul>
        <li>
          <p>Category A - As long as the user has an account with us</p>
        </li>
      </ul>
      <h5 class="western">Sources of Personal Information</h5>
      <p>Learn more about the sources of personal information we collect in "WHAT INFORMATION DO WE COLLECT?"</p>
      <h5 class="western">How We Use and Share Personal Information</h5>
      <p>Learn about how we use your personal information in the section, "HOW DO WE PROCESS YOUR INFORMATION?"</p>
      <p><strong>Will your information be shared with anyone else? </strong>We may disclose user information with our service providers pursuant to an agreement between us and each service provider. Learn more about how we disclose personal information to in the section, "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"</p>
      <p>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.</p>
      <p>We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.</p>
      <h5 class="western">Your Rights</h5>
      <p>You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:</p>
      <p>Right to know whether or not we are processing your personal data</p>
      <p>Right to access your personal data</p>
      <p>Right to correct inaccuracies in your personal data</p>
      <p>Right to request the deletion of your personal data</p>
      <p>Right to obtain a copy of the personal data you previously shared with us</p>
      <p>Right to non-discrimination for exercising your rights</p>
      <p>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</p>
      <p>Depending upon the state where you live, you may also have the following rights:</p>
      <p>Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida&rsquo;s privacy law)</p>
      <h5 class="western">How to Exercise Your Rights</h5>
      <p>To exercise these rights, you can contact us by submitting a&nbsp;data subject access request, by emailing us at <a href="mailto:admin@querier.com">admin@querier.com</a>, or by referring to the contact details at the bottom of this document.</p>
      <p>Under certain US state data protection laws, you can designate an authorized agent to make a request on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with applicable laws.</p>
      <h5 class="western">Request Verification</h5>
      <p>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.</p>
      <p>If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.</p>
      <h5 class="western">Appeals</h5>
      <p>Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing us at admin@querier.com. We will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may submit a complaint to your state attorney general.</p>
      <h4 class="western">13. DO WE MAKE UPDATES TO THIS NOTICE?</h4>
      <p>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
      <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date at the top of this privacy notice. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
      <h4 class="western">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>
      <p>If you have questions or comments about this notice, you may email us at admin@querier.com or contact us by post at:</p>
      <p>Querier LLC</p>
      <p>123 Elm Street</p>
      <p>Atlanta, GA 30067</p>
      <p>United States</p>
      <h4 class="western">15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h4>
      <p>Based on the applicable laws of your country or state of residence in the US, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please fill out and submit a data subject access request.</p>
    </div>
  )
}

export default PrivacyPolicy;

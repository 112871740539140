//===================================================================================
// External
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Internal
import PageTitleBar from '../PageTitleBar/PageTitleBar';
import QueryTemplateStatus from './Subcomponents/QueryTemplateStatus/QueryTemplateStatus';
import AgentStatus from './Subcomponents/AgentsStatus/AgentStatus';
import QueriesStatus from './Subcomponents/QueriesStatus/QueriesStatus';
import { deleteProject, fetchProjectData } from '../../store/actions/actions';
import { setModalContent } from '../../store/slices/ui.slice';
import { useGetProject } from '../../hooks/commonHooks';
import StatusContainer from './Subcomponents/StatusContainer/StatusContainer';
//===================================================================================

const Project = () => {
  let { projectId } = useParams();
  const { title, status } = useGetProject(projectId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchProjectData(projectId));
  }, [projectId]);

  const onEditProject = () => {
    dispatch(setModalContent('editProject'))
  }

  const onDeleteProject = () => {
    dispatch(deleteProject({ projectId, navigate }))
  }

  return (
    <div className="Project__container">
      <PageTitleBar title={title} subTitle={'Project Overview'} />
      <div className='Project__statusContainer'>
        <StatusContainer Component={QueryTemplateStatus} title={'Query Template Status'} />
        <StatusContainer Component={AgentStatus} title={'Agent List Status'} />
        <StatusContainer Component={QueriesStatus} title={'Queries Status'} />
      </div>
      <div className='Project__buttonContainer'>
        <button className='Project__editButton' onClick={() => onEditProject()}>Edit Project</button>
        <button className='Project__deleteButton' onClick={() => onDeleteProject()}>Delete Project</button>
      </div>
    </div>
  )
}

export default Project;

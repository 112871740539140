//==============================================================================
/* External */
import {createSlice} from '@reduxjs/toolkit';
import { makeObjFromArray } from '../../utils/utils';
//==============================================================================

const initialState = {};

const substitutionsSlice = createSlice({
  name: 'substitutions',
  initialState,
  reducers: {
    createSubstitution(state, action) {
      const {id} = action.payload;
      return {
        ...state,
        [id]: { ...action.payload }
      }
    },
    setSubstitution(state, action) {
      return {
        ...state,
        [action.payload._id]: {...action.payload}
      }
    },
    setSubstitutions(state, action) {
      return {
        ...action.payload
      }
    },
  },
});

// selectors:
export const getSubstitutions = (state) => state.substitutions;

// actions:
export const {
  createSubstitution,
  deleteSubstitution,
  setSubstitution,
  setSubstitutions,
} = substitutionsSlice.actions;

// reducer:
export default substitutionsSlice.reducer;

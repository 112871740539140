//==============================================================================
// Internal:
import { fetchWithPost } from "../../utils/fetchAPI";
import { makeObjFromArray } from "../../utils/utils";
import { getSubstitutionEntries, setSubstitutionEntries } from "../slices/substitutionEntries.slice";
import { setFlashMessage } from "../slices/ui.slice";
import environment from "../../utils/env";
//==============================================================================

// TODO: IS THIS USED ANYWHERE?
export const createOrUpdateSubstitutionEntriesEffect = async (action, { dispatch, getState }) => {
  try {
    const { substitutionEntries } = action.payload;
    const existingSubEntries = getSubstitutionEntries(getState());
    const subEntriesToUpdate = [];
    const subEntriesToCreate = [];
    let updatedubstitutionEntriesObj = {};
    let newSubstitutionEntriesObj = {};

    // determine if SubstitutionEntry already exists by looping through and comparing to substitutionEntry state
    Object.values(substitutionEntries).forEach(subEntry => {
      if (Object.keys(existingSubEntries).includes(subEntry._id)) {
        if (existingSubEntries[subEntry._id].finalText !== subEntry.finalText) {
          subEntriesToUpdate.push(subEntry)
        }
      } else {
        subEntriesToCreate.push(subEntry);
      }
    })

    // if already exists, call to update
    if (subEntriesToUpdate.length > 0) {
      const updateURL = `${environment.dbBaseURL}/updateSubstitutionEntriesBatch`;
      const updateBody = subEntriesToUpdate;
      const updateSubEntriesArray = await fetchWithPost(updateURL, updateBody, 'PUT');
      updatedubstitutionEntriesObj = makeObjFromArray(updateSubEntriesArray);
    }

    // if not, call to create:
    if (subEntriesToCreate.length > 0) {
      const createURL = `${environment.dbBaseURL}/substitutionEntriesBatch`;
      const createBody = subEntriesToCreate;
      const newSubEntriesArray = await fetchWithPost(createURL, createBody);
      newSubstitutionEntriesObj = makeObjFromArray(newSubEntriesArray);
    }
    dispatch(setSubstitutionEntries({ ...updatedubstitutionEntriesObj, ...newSubstitutionEntriesObj }));
    dispatch(setFlashMessage({ type: 'success', message: 'Substitutions Successfully Updated' }))
  } catch (error) {
    dispatch(setFlashMessage({ type: 'error', message: 'There Was An Error Submitting Substitution Info. Please Try Again.' }))
    console.error('Error In The createOrUpdateSubstitutionEntriesEffect: ', error)
  }
};
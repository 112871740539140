//===================================================================================
import { useEffect, useState } from 'react'
import useUnmount from './useUnmount'
//===================================================================================

export function useLocationListener() {
  const [location, setLocation] = useState(null);

  const observable = () => document.location.pathname;

  let oldValue = observable();
  const observer = new MutationObserver(() => {
    const newValue = observable();
    if (oldValue !== newValue) {
      setLocation(window.location.pathname)
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });

  return location;
}

// const [location, setLocation] = useState(null);

// useEffect(() => {
//   window.navigation.oncurrententrychange = () => {
//     setLocation(window.location.pathname);
//   }
// }, [])

// // Ask to save changes:
// useUnmount(() => {
//   window.navigation.oncurrententrychange = () => { }
// })

// return location;
//==============================================================================
// Internal:
import { fetchWithPost } from "../../utils/fetchAPI";
import { getAgents, setAgent } from "../slices/agents.slice";
import { setFlashMessage } from "../slices/ui.slice";
import { getUserId } from "../slices/user.slice";
import {
  doNotQueryHasChanged,
  setPriorityWhenDoNotQueryChange
} from "../effectUtils/effectUtils";
import environment from "../../utils/env";
//==============================================================================

export const updateAgentEffect = async (action, { dispatch, getState }) => {
  try {
    const {navigate, ...updatedAgentInfo} = action.payload;
    const fullAgentList = getAgents(getState());
    const existingAgentInfo = fullAgentList[updatedAgentInfo._id];
    const userId = getUserId(getState());

    // if changing 'doNotQuery' update
    if (doNotQueryHasChanged(updatedAgentInfo, existingAgentInfo)) {
      setPriorityWhenDoNotQueryChange(updatedAgentInfo, existingAgentInfo, fullAgentList)
    }

    const receivedAgentInfo = await fetchWithPost(
      `${environment.dbBaseURL}/agents/${updatedAgentInfo._id}`,
      { ...existingAgentInfo, ...updatedAgentInfo, userId},
      'PUT'
    );

    dispatch(setAgent(receivedAgentInfo));
    dispatch(setFlashMessage({ type: 'success', message: 'Agent Successfully Submitted...!' }))
    navigate(`/project/${receivedAgentInfo.projectId}/agents`)
  } catch(error) {
    console.error('Error In UpdateAgentEffect: ', error);
    dispatch(setFlashMessage({ type: 'error', message: 'Unable To Submit Agent Update. Please Try Again.' }))
  }
};

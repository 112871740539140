//===================================================================================
// External
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal
import { getUserId } from '../../store/slices/user.slice';
import { setModalContent } from '../../store/slices/ui.slice';
import { createNewProject } from '../../store/actions/actions';
import { useNavigate } from 'react-router-dom';
//===================================================================================

const ProjectCreate = ({ error, setError }) => {
  const [title, setTitle] = useState('');
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(createNewProject({ error, navigate, title, userId, setError }));
  }

  const handleClick = () => {
    dispatch(setModalContent(''));
  };

  return (
    <form className="ProjectCreate__container" onSubmit={(event) => handleSubmit(event)}>
      <div className="ProjectCreate__inputContainer">
        <label>Project Title:</label>
        <input
          className="ProjectCreate__inputField"
          onChange={(event) => { setTitle(event.target.value) }}
          placeholder={'Enter project title here...'}
          type='text'
          value={title}
        />
      </div>
      <div className="ProjectCreate__submitButtonContainer">
        <button className="ProjectCreate__submitButton">Submit Project</button>
        <button
          type=""
          className="ProjectCreate__cancelButton"
          onClick={() => handleClick()}
        >
          Cancel
        </button>
      </div>
    </form>
  )
}

export default ProjectCreate;
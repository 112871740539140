//===================================================================================
// External
import React from "react";
import ErrorPage from "../ErrorPage/ErrorPage";
//===================================================================================

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log('*** ERROR BOUNDARY: ***', error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="ErrorBoundary__container">
          <ErrorPage />
        </div>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

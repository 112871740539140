//==============================================================================
// External:
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Internal:
import { useGetProject } from '../../hooks/commonHooks';
import { getAgents } from '../../store/slices/agents.slice';
import { updateAgentPriority } from '../../store/actions/actions';
import { handleList } from './Agent.utils';
import { orderedAgentList } from '../../utils/utils';
import { getFilter, getSearchTerm, getSort, setSearchTerm } from '../../store/slices/ui.slice';
//==============================================================================

export const useAgentsHooks = () => {
  const { projectId } = useParams();
  const { title: projectTitle } = useGetProject(projectId)
  const [orderHasChanged, setOrderHasChanged] = useState(false);
  const agentsObj = useSelector(getAgents);
  const filter = useSelector(getFilter);
  const sort = useSelector(getSort);
  const searchTerm = useSelector(getSearchTerm);
  const [shouldAllowDnD, setShouldAllowDND] = useState(true);
  const [agents, setAgents] = useState(orderedAgentList(agentsObj));

  useEffect(() => {
    setAgents(orderedAgentList(agentsObj))
  }, [])

  const dispatchSetPriority = ({ newOnDropList }) => {
    dispatch(updateAgentPriority({ newAgentList: newOnDropList, projectId, setOrderHasChanged }))
    setOrderHasChanged(false);
  }

  useEffect(() => {
    const agentsList = handleList({agentsObj, filter, searchTerm, sort})
    setAgents(agentsList);
  }, [agentsObj, searchTerm, sort, filter])


  useEffect(() => {
    if (searchTerm) {
      dispatch(setSearchTerm(''));
    }

    if (filter === 'agentsToQuery' && sort === 'priority') {
      setShouldAllowDND(true);
    } else {
      setShouldAllowDND(false);
    }
  }, [filter, sort])


  useEffect(() => {
    // prevent DND when searching:
    if (searchTerm && sort === 'priority') {
      setShouldAllowDND(false)
    }
    if (!searchTerm && sort === 'priority' && filter === 'agentsToQuery') {
      setShouldAllowDND(true)
    }
  }, [searchTerm])

  const dispatch = useDispatch();

  return {
    agents,
    dispatch,
    dispatchSetPriority,
    filter,
    projectId,
    projectTitle,
    searchTerm,
    setAgents,
    shouldAllowDnD,
    sort,
  }
}

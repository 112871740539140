//===================================================================================
// External
import React from 'react';

// Internal

//===================================================================================

const PageTitleBar = ({ subTitle, title }) => {
  return (
    <div className="PageTitleBar__container">
      <div className="PageTitleBar__leftContainer">
        <div className="PageTitleBar__title"> {title} </div>
      </div>
      <div className="PageTitleBar__rightContainer">
        {subTitle}
      </div>
    </div>
  )
}

export default PageTitleBar;
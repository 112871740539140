//==============================================================================
// External:
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Internal:

//==============================================================================

const WhyQueryCard = ({ icon, text, title }) => {
  const handleIcon = (icon) => {
    switch (icon) {
      case 'agents':
        return 'fa-users'
      case 'query':
        return 'fa-pen-nib'
      case 'plan':
        return 'fa-list-ol';
    }
  }
  return (
    <div className='WhyQueryCard__container'>
      <div className='WhyQueryCard__icon'>
        <FontAwesomeIcon icon={handleIcon(icon)} />
      </div>
      <div>
        <div className='WhyQueryCard__title'>
          {title}
        </div>
        <div className='WhyQueryCard__text'>
          {text}
        </div>
      </div>
    </div>
  )
}

export default WhyQueryCard;
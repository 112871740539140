//===================================================================================
import { Extension } from '@tiptap/core';
//===================================================================================

export const Indent = Extension.create({
  name: 'indent',
  addOptions() {
    return {
      types: ['paragraph'],
      alignments: ['0px', '30px','left', 'center', 'right', 'justify'],
      defaultAlignment: '0px',
    }
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          textIndent: {
            default: this.options.defaultAlignment,
            parseHTML: element => {
              // Executes second when first renderin the text
              const alignment = element.style.textIndent || this.options.defaultAlignment
              return this.options.alignments.includes(alignment) ? alignment : this.options.defaultAlignment
            },
            renderHTML: attributes => {
              // Executes when first renderin the text
              if (attributes.textIndent === this.options.defaultAlignment) {
                return {}
              }

              return { style: `text-indent: ${attributes.textIndent}` }
            },
          },
        },
      },
    ]
  },
  addCommands() {
    return {
      setTextIndent: (alignment) => ({ commands }) => {
        return this.options.types
        .map(type => commands.updateAttributes(type, { textIndent: alignment }))
        .every(response => response)
      },

      unsetTextIndent: (alignment) => ({ commands }) => {
        return this.options.types
          .map(type => commands.resetAttributes(type, 'textIndent'))
          .every(response => response)
      },
    }
  },

  addKeyboardShortcuts() {
    return {
      'Tab': () => this.editor.commands.setTextIndent('30px'),
      'Shift-Tab': () => this.editor.commands.unsetTextIndent(),
    }
  },
})

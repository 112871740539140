//===================================================================================
// External
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// Internal
import { useGetUser } from '../../hooks/commonHooks';
import { useParams } from 'react-router-dom';
//===================================================================================

const useSubstitutionHooks = (substitution) => {
  const {id: projectId, queryTemplateId} = useParams();
  const [title, setTitle] = useState(substitution.title || '');
  const [signifier, setSignifier] = useState(substitution.signifier || '');
  const [exampleText, setExampleText] = useState(substitution.exampleText || '');
  const [showEditSubstitution, setShowEditSubstitution] = useState(false);
  const [redBorderClass, setRedBorderClass] = useState('');
  const {sub: userId} = useGetUser()
  const dispatch = useDispatch();

  return {
    dispatch,
    exampleText,
    projectId,
    queryTemplateId,
    redBorderClass,
    setExampleText,
    setRedBorderClass,
    setShowEditSubstitution,
    setSignifier,
    setTitle,
    showEditSubstitution,
    signifier,
    title,
    userId,
  }
}

export default useSubstitutionHooks;

//==============================================================================
// Internal:
import { fetchWithPost } from "../../utils/fetchAPI";
import { setSubstitution } from "../slices/substitutions.slice";
import { setFlashMessage } from "../slices/ui.slice";
import { getUserId } from "../slices/user.slice";
import environment from "../../utils/env";
//==============================================================================

export const createSubstitutionEffect = async (action, { dispatch, getState }) => {
  try {
    const newSubstitution = action.payload;
    const userId = getUserId(getState());

    const returnedSubstitution = await fetchWithPost(
      `${environment.dbBaseURL}/substitutions`,
      { ...newSubstitution, finalText: '', userId },
    );

    dispatch(setSubstitution(returnedSubstitution));
    dispatch(setFlashMessage({ type: 'success', message: 'Substitution Successfully Updated' }))
  } catch (error) {
    dispatch(setFlashMessage({ type: 'error', message: 'There Was An Error Submitting Your Substitution. Please Try Again.' }))
    console.error('Error In The CreateSubstitutionEffect: ', error)
  }
};

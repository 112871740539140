//==============================================================================
// External:
import React from 'react';
import { useDispatch } from 'react-redux';

// Internal:
import { APP_DELETE_QUERY, APP_KEEP_QUERY } from '../../utils/actionConstants';
//==============================================================================

const DeleteQueryConfirmationModal = ({ }) => {
  const dispatch = useDispatch();

  const onDelete = () => {
    dispatch({type: APP_DELETE_QUERY})
  }

  const onClose = () => {
    dispatch({type: APP_KEEP_QUERY})
  }

  return (
    <div className='DeleteQueryConfirmationModal__container'>
      <div className='DeleteQueryConfirmationModal__textContainer'>
        <div>Are you sure you want to delete this query?</div>
        <div>Once it's been deleted, it can't be recovered.</div>
      </div>
      <div className='DeleteQueryConfirmationModal__buttonContainer'>
        <button
          className='DeleteQueryConfirmationModal__keepButton'
          onClick={() => onClose()}
        >
          Keep Query
        </button>
        <button
          className='DeleteQueryConfirmationModal__deleteButton'
          onClick={() => onDelete()}
        >
          Delete Query
        </button>
      </div>
    </div>
  )
}

export default DeleteQueryConfirmationModal;
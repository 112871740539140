//==============================================================================
/* External */
import {createSlice} from '@reduxjs/toolkit';
//==============================================================================

const initialState = {
  flashMessage: {},
  isLoading: false,
  modalContent: '',
  filter: 'agentsToQuery',
  sort: 'priority',
  isExtendingSession: false,
  paymentInfo: {}
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setFlashMessage(state, action) {
      state.flashMessage = action.payload
    },
    setLoading(state) {
      state.isLoading = !state.isLoading
    },
    setModalContent(state, action) {
      state.modalContent = action.payload
    },
    setFilter(state, action) {
      state.filter = action.payload
    },
    setIsExtendingSession(state, action) {
      state.isExtendingSession = action.payload
    },
    setSearchTerm(state, action) {
      state.searchTerm = action.payload
    },
    setSort(state, action) {
      state.sort = action.payload
    }
  },
});

// selectors:
export const getFilter = (state) => state?.ui?.filter;
export const getFlashMessage = (state) => state?.ui?.flashMessage;
export const getLoading = (state) => state?.ui?.isLoading;
export const getSearchTerm = (state) => state?.ui?.searchTerm;
export const getShowModal = (state) => state?.ui?.modalContent;
export const getSort = (state) => state?.ui?.sort;
export const getIsExtendingSession = (state) => state?.ui.isExtendingSession;

// actions:
export const {
  setFilter,
  setFlashMessage,
  setIsExtendingSession,
  setLoading,
  setModalContent,
  setSearchTerm,
  setSort
} = uiSlice.actions;

// reducer:
export default uiSlice.reducer;

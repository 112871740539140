//==============================================================================
// External:
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

// Internal:
import { getTooltipText } from './QuestionDisplayUtils';
import QuestionMarkCircleIcon from '../../assets/question-mark-circle.png';
//==============================================================================

const QuestionDisplay = ({ tooltipKey }) => {
  const styles = {
    fontSize: '14px',
    maxWidth: '30%',
    whiteSpace: 'pre-wrap'
  }

  return (
    <div
      className='QuestionDisplay__container'
      data-tooltip-id="my-tooltip"
      data-tooltip-content={`${getTooltipText(tooltipKey)}`}
    >
      <Tooltip id="my-tooltip" style={styles} multiline />
      <div className='QuestionDisplay__iconContainer' >
        {/* <FontAwesomeIcon icon="fa-circle-question" className="QuestionDisplay__questionIcon" /> */}
        <img src={QuestionMarkCircleIcon} className='QuestionDisplay__icon'/>
      </div>
    </div>
  )
}

export default QuestionDisplay;

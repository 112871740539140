//==============================================================================
/* External */
import {createSlice} from '@reduxjs/toolkit';
//==============================================================================

const initialState = {};

const substitutionsEntriesSlice = createSlice({
  name: 'substitutionEntries',
  initialState,
  reducers: {
    // setSubstitutionEntry(state, action) {
    //   const {id} = action.payload;
    //   return {
    //     ...state,
    //     [id]: { ...action.payload }
    //   }
    // },
    setSubstitutionEntry(state, action) {
      return {
        ...state,
        [action.payload._id]: {...action.payload}
      }
    },
    setSubstitutionEntries(state, action) {
      return {
        ...action.payload
      }
    },
  },
});

// selectors:
export const getSubstitutionEntries = (state) => state.substitutionEntries;

// actions:
export const {
  setSubstitutionEntries,
  setSubstitutionEntry,
} = substitutionsEntriesSlice.actions;

// reducer:
export default substitutionsEntriesSlice.reducer;

//==============================================================================
// External:
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Intternal:
import fetchAPI from '../../utils/fetchAPI';
import environment from '../../utils/env';
import QuillImage from "../../assets/quill.svg";
//==============================================================================

const Subscribe = () => {
  const [billingPortal, setBillingPortal] = useState('')

  useEffect(() => {
    const getStripeBillingPortal = async () => {
      const { session } = await fetchAPI(`${environment.dbBaseURL}/billing-portal`)
      setBillingPortal(session.url)
    }
    getStripeBillingPortal();
  }, [])

  return (
    <div className='Subscribe__container'>
      <div className='Subscribe__hero'>
        <img src={QuillImage} className='Subscribe__logo' />
        <div>Find the best agent for you.</div>
        <div>Subscribe to Querier today!</div>
      </div>
      <div className='Subscribe__innerContainer'>
        <div className='Subscribe__title'>
          Monthly Subscription Plan
        </div>
        <div className='Subscribe__checkList'>
          <div className='Subscribe__checkListItem'>
            <FontAwesomeIcon icon="fa-check-circle" className="Subscribe__icon" />
            <div className='Subscribe__checkListItemText'>Build A Customizable Query Template</div>
          </div>
          <div className='Subscribe__checkListItem'>
            <FontAwesomeIcon icon="fa-check-circle" className="Subscribe__icon" />
            <div className='Subscribe__checkListItemText'>Curate Your Agent List</div>
          </div>
          <div className='Subscribe__checkListItem'>
            <FontAwesomeIcon icon="fa-check-circle" className="Subscribe__icon" />
            <div className='Subscribe__checkListItemText'>Send Unlimited Queries</div>
          </div>
          <div className='Subscribe__checkListItem'>
            <FontAwesomeIcon icon="fa-check-circle" className="Subscribe__icon" />
            <div className='Subscribe__checkListItemText'>Generate Email Queries With The Click of a Button</div>
          </div>
          <div className='Subscribe__checkListItem'>
            <FontAwesomeIcon icon="fa-check-circle" className="Subscribe__icon" />
            <div className='Subscribe__checkListItemText'>Post To QueryManager In Seconds</div>
          </div>
        </div>
        <div className='Subscribe__subscriptionInfoContainer'>
          <div className='Subscribe__subscriptionInfoTextBold'>Only $3.99/Month!</div>
          <div className='Subscribe__subscriptionInfoText'>Recurring Monthly Charge</div>
          <div className='Subscribe__subscriptionInfoText'>Pause/Cancel Any Time!</div>
        </div>
        <button className='Subscribe__button'>
          <a href={billingPortal}> Subscribe Today </a>
        </button>
      </div>
    </div>
  );
}

export default Subscribe;

//==============================================================================
// External:
import React from 'react';

// Internal:

//==============================================================================

// Mission: make it easier to query. No more spreadsheets, copy and pasting
// Steps: 1. Template, 2. Agent, 3. Make Query
// Cost
// Wait! I have to give access to my gmail account?
// What if I don't want emails automatically sent to my email?

const About = () => {
  return (
    <div className='About__container'>
      About Page
    </div>
  )
}

export default About;
//===================================================================================
// External
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal
import { getProjects } from '../../store/slices/projects.slice';
//===================================================================================

const useSidebarHooks = () => {
  const projects = useSelector(getProjects);
  const [shouldShowProjectLinks, setShouldShowProjectLinks] = useState("");
  const [shouldShowQueryLinks, setShouldShowQueryLinks] = useState("");
  const dispatch = useDispatch();

  return {
    dispatch,
    projects,
    setShouldShowQueryLinks,
    setShouldShowProjectLinks,
    shouldShowQueryLinks,
    shouldShowProjectLinks,
  }
}

export default useSidebarHooks;

//==============================================================================
// External:
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Internal:

//==============================================================================

const SubstitutionTitle = ({ setShowEditSubstitution, title }) => {
  const handleClick = () => {
    setShowEditSubstitution(true);
  }

  return (
    <div className='SubstitutionTitle__container'>
      <div className='SubstitutionTitle__title'>
        {title}
      </div>
      <FontAwesomeIcon
        className='SubstitutionTitle__icon'
        onClick={() => handleClick()}
        icon='fa-pen'
      />
    </div>
  )
}

export default SubstitutionTitle;

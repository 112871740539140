//==============================================================================
// Internal:
import { fetchWithPost } from "../../utils/fetchAPI";
import { updateProject } from "../slices/projects.slice";
import { setFlashMessage, setModalContent } from "../slices/ui.slice";
import environment from "../../utils/env";
//==============================================================================

export const updateProjectEffect = async (action, { dispatch, getState }) => {
  try {
    const {id, title} = action.payload;

    const url = `${environment.dbBaseURL}/projects/${id}`;
    const updateProjectData = { title };
    const updatedProjectData = await fetchWithPost(url, updateProjectData, 'PUT');

    dispatch(updateProject(updatedProjectData));
    dispatch(setModalContent(''));
    dispatch(setFlashMessage({ type: 'success', message: 'Project was successfully updated' }));
  } catch (error) {
    console.error('Error in the UpdateProjectEffect Effect', error)
    dispatch(setFlashMessage({ type: 'error', message: 'Unable To Submit Project Changes. Please Try Again.' }))
  }
};

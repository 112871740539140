//==============================================================================
// External:
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal:
import { getChapter } from '../../store/slices/queryTemplates.slice';
import { useParams } from 'react-router-dom';
//==============================================================================

const usePagesBuilderHooks = () => {
  const { id } = useParams();
  const [chapterNum, setChapterNum] = useState('1');
  let originalChapter1 = useSelector(getChapter("1"));
  let originalChapter2 = useSelector(getChapter("2"));
  let originalChapter3 = useSelector(getChapter("3"));
  const originalChapters = {"1": originalChapter1, "2": originalChapter2, "3": originalChapter3}
  const [localChapter1, setLocalChapter1] = useState(originalChapter1 || '');
  const [localChapter2, setLocalChapter2] = useState(originalChapter2 || '');
  const [localChapter3, setLocalChapter3] = useState(originalChapter3 || '');
  const dispatch = useDispatch();

  return {
    chapterNum,
    dispatch,
    id,
    localChapter1,
    localChapter2,
    localChapter3,
    originalChapters,
    originalChapter1,
    originalChapter2,
    originalChapter3,
    setChapterNum,
    setLocalChapter1,
    setLocalChapter2,
    setLocalChapter3
  }
};

export default usePagesBuilderHooks;

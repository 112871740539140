//==============================================================================
// Internal:
import { fetchWithPost } from "../../utils/fetchAPI";
import { getQueryTemplates, setQueryTemplate } from "../slices/queryTemplates.slice";
import { setFlashMessage } from "../slices/ui.slice";
import environment from "../../utils/env";
//==============================================================================

export const createNewQueryTextEffect = async (action, { dispatch, getState }) => {
  try {
    const newText = action.payload;
    const existingQuery = getQueryTemplates(getState());
    const queryToPost = { ...existingQuery, queryTemplateText: newText };
    const updatedQuery = await fetchWithPost(
      `${environment.dbBaseURL}/query-templates/${existingQuery._id}`,
      queryToPost,
      'PUT'
    );
    dispatch(setQueryTemplate(updatedQuery));
    dispatch(setFlashMessage({ type: 'success', message: 'Query Template Successfully Updated' }))
  } catch (error) {
    dispatch(setFlashMessage({ type: 'error', message: 'There Was An Error Submitting Your Query Template. Please Try Again.'}))
    console.error('Error In CreateNewQueryTextEffect: ', error);
  }
};
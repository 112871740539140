//==============================================================================
// External:
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Internal:
import PageTitleBar from '../../components/PageTitleBar/PageTitleBar';
import CheckListItem from '../../components/CheckListItem/CheckListItem';
import SubmitToQueryManager from './Subcomponents/SubmitToQueryManager/SubmitToQueryManager';
import QueryManagerPersonalLink from './Subcomponents/QueryManagerPersonalLink/QueryManagerPersonalLink';
import {
  useGetAgents,
  useGetQueries,
  useGetQueryTemplate,
  useGetUser
} from '../../hooks/commonHooks';
import { deleteQuery } from '../../store/actions/actions';
import { QM_PERSONAL_LINK, QM_SUBMIT_TEXT } from '../../utils/constants';
//==============================================================================

const QueryManagerSubmit = () => {
  const { queryId } = useParams();
  const user = useGetUser();
  const queryTemplate = useGetQueryTemplate()
  const query = useGetQueries()[queryId];
  const agent = useGetAgents()[query.agentId];
  const [queryManagerPersonalLink, setQueryManagerPersonalLink] = useState(query.queryManagerPersonalLink);
  const [dateSentToAgent, setDateSentToAgent] = useState(query.dateSentToAgent);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const submitToQueryManagerProps = {
    agent,
    query,
    queryTemplate,
    user
  }

  const queryManagerPersonalLinkProps = {
    dateSentToAgent,
    dispatch,
    navigate,
    queryId,
    query,
    queryManagerPersonalLink,
    setDateSentToAgent,
    setQueryManagerPersonalLink,
  }

  const onDeleteEntireQuery = () => {
    dispatch(deleteQuery({ ...query, navigate }));
  }

  useEffect(() => {
  }, [dateSentToAgent])

  const onEditQuery = () => {
    navigate(`/query/${queryId}`, { state: { queryId } })
  }

  return (
    <div className='QueryManagerSubmit__container'>
      <PageTitleBar
        title={'QueryManager'}
        subTitle={'Submit To QueryManager'}
      />
      <div className='QueryManagerSubmit__innerContainer'>
        <div className='QueryManagerSubmit__checklistContainer'>
          <div className='QueryManagerSubmit__headerContainer'>
            <div className='QueryManagerSubmit__agentInfoHeader'>Submitting Query For:</div>
            <div className='QueryManagerSubmit__agentInfoItem'>
              <div className='QueryManagerSubmit__agentInfoLabel'>Agent Name:</div>
              <div>{agent.firstName} {agent.lastName}</div>
            </div>
            <div className='QueryManagerSubmit__agentInfoItem'>
              <div className='QueryManagerSubmit__agentInfoLabel'>Agency:</div>
              <div>{agent.agency}</div>
            </div>
          </div>
          <div className='QueryManagerSubmit__text'>
            <p>To quickly and easily submit your query to QueryManager:</p>
            <ol type="1">
              <li>Add the <a href='https://google.com'>Querier Browser Extension</a> to your browser</li>
              <li>Click the 'Open QueryManager' button below</li>
              <li>Querier will open a new tab with the correct QueryManager form. Head over to this tab and open the Querier extension.</li>
              <li>Click the 'Submit to QueryManager' button and Querier will populate the form with your query information!</li>
              <li>Review the data, then click QueryManager's 'Submit Query' button at the bottom of the page</li>
            </ol>
          </div>
          <CheckListItem
            component={<SubmitToQueryManager {...submitToQueryManagerProps} />}
            hasCompletedStep
            number='1'
            openByDeault
            text={QM_SUBMIT_TEXT}
            title="Submit To QueryManager"
            />
          <CheckListItem
            component={<QueryManagerPersonalLink {...queryManagerPersonalLinkProps} />}
            hasCompletedStep
            isLastItem
            number='2'
            openByDeault
            text={QM_PERSONAL_LINK}
            title="Enter QueryManager Personal Link"
          />
        </div>
        <div className='QueryManagerSubmit__buttonContainer'>
          <button
            className='QueryManagerSubmit__editButton'
            onClick={() => onEditQuery()}
            type="button"
          >
            Edit Query
          </button>
          <button
            className='QueryManagerSubmit__deleteButton red'
            onClick={() => onDeleteEntireQuery()}
            type="button"
          >
            Delete Query
          </button>
        </div>
      </div>
    </div>
  )
}

export default QueryManagerSubmit;

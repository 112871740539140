//==============================================================================
// External:
import React, { useEffect, useState } from "react";

// Internal:
import { useDispatch } from "react-redux";
import { setModalContent } from "../../store/slices/ui.slice";
import {
  APP_EXTEND_SESSION,
  APP_CONT_LOGOUT_COUNTDOWN,
} from "../../utils/actionConstants";
//==============================================================================

const CountdownModal = () => {
  const [timeMsg, setTimeMsg] = useState("");
  const dispatch = useDispatch();
  let countdownInterval = null;

  useEffect(() => {
    onCountdown();
    return () => clearInterval(countdownInterval);
  }, []);

  const onCountdown = () => {
    let remainingTime = 2 * 60 * 1000;

    countdownInterval = setInterval(() => {
      // Display the result in the element with id="demo"
      var minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      var seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
      setTimeMsg(`${minutes}:${seconds.toString().padStart(2, '0')}`);
      remainingTime = remainingTime - 1000;

      if (remainingTime === 0) {
        clearInterval(countdownInterval);
        onLogOut();
        dispatch(setModalContent(""));
      }
    }, 1000);
  };

  const onContinueSession = () => {
    dispatch({ type: APP_EXTEND_SESSION });
  };

  const onLogOut = () => {
    dispatch({ type: APP_CONT_LOGOUT_COUNTDOWN });
  };

  return (
    <div className="CountdownModal__container">
      <div className="CountdownModal__text">
        <div>
          Your online session will expire in <b>{timeMsg}</b>.
        </div>
        <div>
          Please click 'Continue' to keep working, or 'Log Out' to end your session.
        </div>
      </div>
      <div className="CountodnwModal__buttonsContainer">
        <button onClick={() => onContinueSession()}>Continue Working</button>
        <button onClick={() => onLogOut()}>Log Out</button>
      </div>
    </div>
  );
};

export default CountdownModal;

//==============================================================================
// External:
import React from 'react';
import HomeCard from './HomeCard';

// Internal:
import {
  HOME_CARD_AGENT,
  HOME_CARD_SEND,
  HOME_CARD_WRITE
} from '../../../utils/constants';
//==============================================================================

const HowDoesQuerierWork = () => {
  return (
    <div className='HowDoesQuerierWork__container'>
      <div>
        <HomeCard title='Write Your Query Template' text={HOME_CARD_WRITE} image='write' textPosition='left'/>
        <HomeCard title='Enter Agent Requirements' text={HOME_CARD_AGENT} image='agent' textPosition='right'/>
        <HomeCard title='Write Your Query Template' text={HOME_CARD_SEND} image='send' textPosition='left'/>
      </div >
    </div >
  )
}

export default HowDoesQuerierWork;
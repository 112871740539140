//==============================================================================
// External:
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getQueryTemplates } from '../../store/slices/queryTemplates.slice';

// Internal:

//==============================================================================

const useQueryTitle = () => {
  const {id: projectId} = useParams();
  const queryTemplate = useSelector(getQueryTemplates);
  const dispatch = useDispatch();
  const [hasQueryTemplate, setHasQueryTemplate] = useState(false);
  const {
    title: originalTemplateTitle,
    queryTemplateText,
    emailSubject: originalEmailSubject,
  } = queryTemplate;
  const [templateTitleFS, setTemplateTitleFS] = useState(originalTemplateTitle);
  const [emailSubjectFS, setEmailSubjectFS] = useState(originalEmailSubject);

  useEffect(() => {
    const hasQueryTemplate = Object.keys(queryTemplate).length > 0;
    if (hasQueryTemplate) {
      setHasQueryTemplate(true)
    }
  }, [queryTemplate])

  return {
    dispatch,
    emailSubjectFS,
    hasQueryTemplate,
    originalEmailSubject,
    originalTemplateTitle,
    projectId,
    queryTemplate,
    queryTemplateText,
    setEmailSubjectFS,
    setHasQueryTemplate,
    setTemplateTitleFS,
    templateTitleFS,
  }
}

export default useQueryTitle;

let environment;

if (process.env.NODE_ENV === 'production') {
  environment = {
    uiBaseURL: 'https://querier.net',
    // dbBaseURL: 'https://querier.api.net'
    dbBaseURL: 'https://api.querier.net'
  }
} else {
  environment = {
    uiBaseURL: 'http://localhost:3000',
    dbBaseURL: 'http://localhost:3001'
  }
}

export default environment;

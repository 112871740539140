//==============================================================================
// Internal:
import fetchAPI from "../../utils/fetchAPI";
import { makeObjFromArray } from "../../utils/utils";
import { setAgents } from "../slices/agents.slice";
import { setQueries } from "../slices/queries.slice";
import { setQueryTemplate } from "../slices/queryTemplates.slice";
import { setSubstitutionEntries } from "../slices/substitutionEntries.slice";
import { setSubstitutions } from "../slices/substitutions.slice";
import { setLoading } from "../slices/ui.slice";
import environment from "../../utils/env";
//==============================================================================

export const getProjectDataEffect = async (action, { dispatch, getState }) => {
  try {
    dispatch(setLoading())
    const projectId = action.payload;
    const {
      agents,
      queries,
      queryTemplates,
      substitutionEntries,
      substitutions,
    } = await fetchAPI(`${environment.dbBaseURL}/project/${projectId}`); // 'proj' bc clashing URLs

    const queriesObj = makeObjFromArray(queries);
    const substitutionsObj = makeObjFromArray(substitutions)
    const agentsObj = makeObjFromArray(agents);
    const substitutionsEntryObj = makeObjFromArray(substitutionEntries)

    dispatch(setQueries(queriesObj));
    dispatch(setQueryTemplate(queryTemplates[0])); // TODO: replace [0]
    dispatch(setSubstitutions(substitutionsObj));
    dispatch(setSubstitutionEntries(substitutionsEntryObj))
    dispatch(setAgents(agentsObj));
  } catch (error) {
    console.error('Error in GetProjectDataEffect: ', error);
  } finally {
    dispatch(setLoading())
  }
};
//==============================================================================
// External:
import React from 'react';

// Internal:
import { useGetQueries } from '../../hooks/commonHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//==============================================================================

const useAgentSelectHooks = (agents) => {
  const { projectId } = useParams();
  const queries = useGetQueries();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const agentIdArray = Object.values(queries).reduce((accumulator, query) => {
    accumulator.push(query.agentId)
    return accumulator;
  }, [])

  const agentsNotQueried = Object.values(agents).filter((agent) => {
    return !agentIdArray.includes(agent._id)
  })

  const options = Object.values(agentsNotQueried).map((agentId) => {
    const {_id, firstName, lastName, agency} = agentId;
    return <option value={_id} key={_id}>{firstName} {lastName} | {agency}</option>
  })

  return {
    dispatch,
    navigate,
    options,
    projectId
  }
}

export default useAgentSelectHooks;

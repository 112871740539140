//==============================================================================
// External:
import React from 'react';

// Internal:

//==============================================================================

const EmailSubject = ({emailSubjectFS, setEmailSubjectFS }) => {
  return (
    <div className='EmailSubject__container'>
      <span>Email Subject: </span>
      <input
        className="QueryBuilderForm__inputField"
        onChange={(event) => { setEmailSubjectFS(event.target.value) }}
        type='text'
        value={emailSubjectFS}
      />
    </div>
  )
}

export default EmailSubject;

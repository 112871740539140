//==============================================================================
// External:
import React from 'react';
import { useLocation } from 'react-router';

// Internal:
import PageSubtitle from '../PageSubtitle/PageSubtitle';
import QueryBuilderForm from '../QueryBuilderForm/QueryBuilderForm';
import { useGetQueries } from '../../hooks/commonHooks';
import PageTitleBar from '../PageTitleBar/PageTitleBar';
//==============================================================================

// TODO: move this to 'views'
const QueryEdit = () => {
  const { state } = useLocation();
  const queryId = state?.queryId || undefined;
  const query = useGetQueries()[queryId] || {};

  return (
    <div className="QueryEdit__container">
      <PageTitleBar title='Edit Query' />
      <QueryBuilderForm preselectedQuery={query} />
    </div>
  )
}

export default QueryEdit;

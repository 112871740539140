//==============================================================================
// External:
import React from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Internal:
import { getTooltipText } from '../../../QuestionDisplay/QuestionDisplayUtils';
import { SUBSTITUTION_DELETE } from '../../../../utils/actionConstants';
//==============================================================================

const SubstitutionTitleForm = ({
  handleSubmit,
  setShowEditSubstitution,
  setShowNewSubstitution,
  setTitle,
  showNewSubstitution,
  substitution,
  title
}) => {
  const dispatch = useDispatch();

  const handleCancel = () => {
    setShowNewSubstitution(false)
    setShowEditSubstitution(false)
  }

  const handleDelete = () => {
    dispatch({ type: SUBSTITUTION_DELETE, payload: { ...substitution } });
  }

  return (
    <div className='SubstitutionTitleForm__container'>
      <input
        className='SubstitutionTitleForm__formTitleInput'
        id='SubstitutionNameInput'
        onChange={(event) => setTitle(event.target.value)}
        placeholder='Substitution Name'
        value={title}
      />
      <div className='SubstitutionTitleForm__iconContainer'>
        <div
          className='QuestionDisplay__container'
          data-tooltip-id="my-tooltip"
          data-tooltip-content={`${getTooltipText('substitutionFormSubmit')}`}
        >
          <Tooltip id="my-tooltip" />
          <button type='submit' className='hiddenButton' form='SubstitutionForm'>
            <FontAwesomeIcon
              id='SubstitutionSubmitIcon'
              className='SubstitutionTitleForm__icon checkMark'
              onClick={() => handleSubmit()}
              icon='fa-check-circle'
            />
          </button>
        </div>
        <div
          className='QuestionDisplay__container'
          data-tooltip-id="my-tooltip"
          data-tooltip-content={`${getTooltipText('substitutionFormCancel')}`}
        >
          <Tooltip id="my-tooltip" />
          <FontAwesomeIcon
            id='SubstitutionCancelChangesIcon'
            className='SubstitutionTitleForm__icon minus'
            onClick={() => handleCancel()}
            icon='fa-minus-circle'
          />
        </div>
        {
          !showNewSubstitution &&
          <div
            className='QuestionDisplay__container'
            data-tooltip-id="my-tooltip"
            data-tooltip-content={`${getTooltipText('substitutionFormDelete')}`}
          >
            <Tooltip id="my-tooltip" />
            <FontAwesomeIcon
              className='SubstitutionTitleForm__icon trashCan'
              onClick={() => handleDelete()}
              icon='fa-trash'
              id='DeleteSubstitutionIcon'
            />
          </div>
        }
      </div>
    </div>
  )
}

export default SubstitutionTitleForm;

//==============================================================================
// Internal:
import { fetchWithPost } from "../../utils/fetchAPI";
import { createNewSubstitutionEntries } from "../actions/actions";
import { setQuery } from "../slices/queries.slice";
import { getUserId } from "../slices/user.slice";
import environment from "../../utils/env";
//==============================================================================

export const createNewQueryEffect = async (action, { dispatch, getState }) => {
  try {
    const {newSubstitutions, navigate, ...newQueryInfo} = action.payload;
    const userId = getUserId(getState());
    
    const url = `${environment.dbBaseURL}/queries`;
    const body = { ...newQueryInfo, userId }
    const newQuery = await fetchWithPost(url, body);

    dispatch(setQuery(newQuery));

    if (Object.keys(newSubstitutions).length > 0) {
      const substitutionEntriesArray = Object.values(newSubstitutions).map((substitution) => {
        return {
          finalText: substitution.finalText,
          queryTemplateId: substitution.queryTemplateId,
          substitutionId: substitution._id,
          userId: substitution.userId,
          projectId: newQueryInfo.projectId,
          queryId: newQuery._id
        }
      });

      dispatch(createNewSubstitutionEntries({substitutionEntriesArray, navigate}))
    }
  } catch (error) {
    console.error('Error in CreateNewQueryEffect: ', error)
  }
};
//==============================================================================
// External:
import React, { useEffect, useState } from 'react';

// Internal:
import { useGetAgents } from '../../hooks/commonHooks';
//==============================================================================

const useQueryFinalEmailHooks = ({agentId, fullHeight}) => {
  const agent = useGetAgents()[agentId];
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const editor = document.querySelector('.Editor__container');
    if (editor) {
      const { height } = document?.querySelector('.Editor__container')?.getBoundingClientRect();
      setHeight(`${height}px`)
    } else if (fullHeight) {
      setHeight(`100%`)
    }else {
      setHeight('85%')
    }
  }, []);

  return {
    agent,
    height,
  }
};

export default  useQueryFinalEmailHooks

//==============================================================================
// External:
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

// Internal:

//==============================================================================

const StatusComponent = ({ Content, title, metric, backgroundColor = 'purple', icon }) => {
  const hasMetric = metric !== null && metric !== undefined;

  return (
    <div className='StatusComponent__container'>
      <div className='StatusComponent__contentContainer'>
        {Content && <Content />}
        {
          hasMetric &&
          <div className='StatusComponent__metricContainer'>
            <div className='StatusComponent__metric'>
              {metric}
            </div>
            {
              icon &&
              <FontAwesomeIcon
                className='StatusComponent__icon'
                icon={icon}
              />
            }
          </div>
        }
      </div>
      <div className={`StatusComponent__title ${backgroundColor}`}>
        {title}
      </div>
    </div>
  )
}

export default StatusComponent;
//==============================================================================
// External:
import React, { useState } from 'react';

// Internal:
import { useGetQueries } from '../../hooks/commonHooks';
import { useNavigate, useParams } from 'react-router-dom';
//==============================================================================

export const useAgentCardHooks = (agent) => {
  const { projectId } = useParams();
  const [shouldShowGripDots, setShouldShowGripDots] = useState(false);
  const queries = useGetQueries();
  const queriesByAgentArray = Object.values(queries).map(query => query.agentId)
  const query = Object.values(queries).filter(query => query.agentId === agent._id)[0];
  const navigate = useNavigate();

  return {
    navigate,
    projectId,
    queriesByAgentArray,
    query,
    setShouldShowGripDots,
    shouldShowGripDots,
  }
}
//==============================================================================
// External:
import React from 'react';

// Internal:
import AgentImage from "../../../assets/query-agent.jpg";
import AgentMail from "../../../assets/query-mail.jpg";
import AgentWrite from "../../../assets/query-write.jpg";
//==============================================================================

// Explanatory cards on the main page:
const HomeCard = ({ image, textPosition = 'left', text, title }) => {
  const setImage = (image) => {
    switch (image) {
      case 'agent': {
        return AgentImage;
      }
      case 'send': {
        return AgentMail;
      }
      case 'write': {
        return AgentWrite
      }
      default:
        return AgentImage
    }
  }

  return (
    <div className={`HomeCard__container ${textPosition}`}>
      <img className='HomeCard__image' src={setImage(image)} />
      <div className={`HomeCard__contentContainer`}>
        <div className='HomeCard__title'>{title}</div>
        <div className='HomeCard__text'>{text}</div>
      </div>
    </div>
  )
}

export default HomeCard;

//==============================================================================
// External:
import React from 'react';

// Internal:
import useAgentSelectHooks from './AgentSelect.hooks';
import { setFlashMessage } from '../../store/slices/ui.slice';
//==============================================================================

const AgentSelect = ({ agentId, agents, preselectedAgentId, setAgent }) => {
  const {
    dispatch,
    navigate,
    options,
    projectId
  } = useAgentSelectHooks(agents);

  const onChangeHandler = (event) => {
    setAgent(event.target.value);
  };

  // if (options.length === 0) {
  //   dispatch(setFlashMessage({
  //     type: 'error',
  //     message: 'All agents have been queried. Please add new agents to your list or edit existing queries!'
  //   }))
  //   navigate(`/project/${projectId}/queries`)
  // }

  return (
    <div className='AgentSelect__container'>
      <div className='AgentSelect__container'>
        <select
          className='AgentSelect__dropdown'
          defaultValue={preselectedAgentId || agentId || ''}
          onChange={(event) => onChangeHandler(event)}
        >
          {!preselectedAgentId && <option value="">N/A</option>}
          {options}
        </select>
      </div>
    </div>
  )
}

export default AgentSelect;
//==============================================================================
// External:
import React from 'react';
import { Rating, ThinStar } from '@smastrom/react-rating';
import '@smastrom/react-rating/style.css';

// Internal:
//==============================================================================

const AgentRating = ({ rating }) => {
  const customStyles = {
    itemShapes: ThinStar,
    // activeBoxColor: ['#e7040f', '#ff6300', '#ffde37', '#61bb00', '#19a974'],
    activeBoxColor: '#5125ad',
    activeFillColor: 'white',
    inactiveBoxColor: 'lightgrey',
    inactiveFillColor: 'white',
    boxBorderWidth: 1,
  };

  return (
    <div className='AgentRating__container'>
      <Rating
        key={rating}
        value={rating / 2}
        itemStyles={customStyles}
        readOnly
        spaceBetween="small"
        halfFillMode="box"
        style={{ width: '100%', height: '20px', }}
        spaceInside='large'
      />
    </div>
  )
}

export default AgentRating;

//==============================================================================
// External:
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal:
import { getQueryTemplates } from '../../store/slices/queryTemplates.slice';
import { useGetProject, useGetSubstitutions } from '../../hooks/commonHooks';
import { useParams } from 'react-router';
import { contentHasChanged } from '../../utils/utils';
//==============================================================================

const useQueryLetterTemplate = () => {
  const { id } = useParams()
  const { title: projectTitle } = useGetProject(id)
  const { queryTemplateText: originalQueryTemaplateText } = useSelector(getQueryTemplates) || {};
  const [localText, setLocalText] = useState(originalQueryTemaplateText);
  const [formattedText, setFormattedText] = useState('');
  const [textHasChanged, setTextHasChanged] = useState(false)
  const substitutions = useGetSubstitutions();
  const dispatch = useDispatch();

  function formatText(textToBeFormatted) {
    if (Object.keys(substitutions).length === 0) {
      return localText;
    };

    let newText = textToBeFormatted;
    Object.values(substitutions).forEach((substitution) => {
      const { exampleText, signifier } = substitution;
      if (signifier && exampleText) {
        newText = newText?.replace(signifier, `<span className='purple'>${exampleText}</span>`);
      }
    });
    setFormattedText(newText);
  }

  useEffect(() => {
    formatText()
  }, []);

  useEffect(() => {
    formatText(originalQueryTemaplateText)
  }, [originalQueryTemaplateText])

  useEffect(() => {
    setTextHasChanged(contentHasChanged(localText, originalQueryTemaplateText))
  }, [localText])

  useEffect(() => {
    formatText(localText)
  }, [substitutions])

  return {
    dispatch,
    formatText,
    formattedText,
    localText,
    originalQueryTemaplateText,
    projectTitle,
    setFormattedText,
    setLocalText,
    substitutions,
    textHasChanged,
  }
}

export default useQueryLetterTemplate;
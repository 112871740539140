//===================================================================================
// External
import React, { useEffect, useState } from 'react';
import { EditorContent, useEditor } from '@tiptap/react'
import Bold from '@tiptap/extension-bold'
import Document from '@tiptap/extension-document'
import FontFamily from '@tiptap/extension-font-family'
import Heading from '@tiptap/extension-heading'
import Italic from '@tiptap/extension-italic'
import Paragraph from '@tiptap/extension-paragraph'
import StarterKit from '@tiptap/starter-kit'
import Text from '@tiptap/extension-text'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'

// Internal
import MenuBar from '../MenuBar/MenuBar';
import { LineHeight } from './LineHeights';
import { Indent } from './Indent';
//===================================================================================

const Editor = ({ setValue, text, textType = '' }) => {
   const [localTextType, setLocalTextType] = useState('');

   const extensions = [
      StarterKit,
      Document,
      Paragraph,
      LineHeight,
      Text,
      Bold,
      Italic,
      Heading,
      TextStyle,
      Underline,
      Indent,
      TextAlign.configure({
         types: ['heading', 'paragraph'],
      }),
      FontFamily.configure({
         types: ['textStyle'],
      }),
   ];

   const editor = useEditor({
      extensions,
      content: text,
      onUpdate: ({ editor }) => {
         const cursorLocation = editor.state.selection.anchor
         const html = editor.getHTML(); // note 3
         setValue(html);
         editor.commands.focus(cursorLocation)
      },
   })

   useEffect(() => {
      if (textType) {
         setLocalTextType(textType) // see note 4
      }
   }, []);

   useEffect(() => {
      if (textType !== localTextType) {
         setLocalTextType(textType) // see note 4
         editor.commands.setContent(text, false, { preserveWhitespace: true });
      }
   }, [text])

   return (
      <form className="Editor__container">
         <MenuBar editor={editor} />
         <EditorContent editor={editor} />
      </form>
   )
}

export default Editor;

/*
NOTES:
1. Sources:
   Tiptap React: https://tiptap.dev/docs/editor/getting-started/install/react
   Make Custom Ext: https://tiptap.dev/docs/editor/extensions/custom-extensions#keyboard-shortcuts
   Text Align Ext: https://tiptap.dev/docs/editor/extensions/functionality/textalign
   Add to Existing Extension: https://tiptap.dev/docs/editor/extensions/custom-extensions/extend-existing
   Paste Rules: https://tiptap.dev/docs/editor/extensions/custom-extensions/extend-existing#paste-rules
   Extensions: https://tiptap.dev/docs/editor/extensions/overview
   Tutorial 1: https://geekyants.com/blog/how-to-create-a-custom-extension-in-tiptap-wysiwyg-editor
   Tutorial 2: https://www.bigbinary.com/blog/building-custom-extensions-in-tiptap
   Inserting a React component: https://tiptap.dev/docs/editor/extensions/custom-extensions/node-views/react
2. Source for this fix: https://github.com/ueberdosis/tiptap/issues/873
3. Fix for empty lines not showing up in the HTML for 'Formatted Text', which was creating a <p> with a <br> inside.
   The HTML was stripping this out. Now, we just substitute a <br> without the <p> tag.
4. This code fixes the issue in QueryTemplate where changing between Chapter texts wasn't updating the input field
   Source for this fix: https://github.com/ueberdosis/tiptap/issues/873
*/
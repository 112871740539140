//==============================================================================
/* External */
import {createSlice} from '@reduxjs/toolkit';
//==============================================================================

const initialState = {};

const queriesSlice = createSlice({
  name: 'queryTemplates',
  initialState,
  reducers: {
    setQueryTemplate(state, action) {
      return {
        ...action.payload
      }
    },
  },
});

// selectors:
export const getQueryTemplates = (state) => state.queryTemplates;
// export const getQueryTemplateByProjectId = (projectId) => (state) => Object.values(state.queryTemplates).filter(template => template.projectId === projectId);
export const getChapter = (number) =>  (state) => {
  if (number === "1") return state.queryTemplates?.chapterOneText
  if (number === "2") return state.queryTemplates?.chapterTwoText
  if (number === "3") return state.queryTemplates?.chapterThreeText
  return state.queryTemplates.chapterOneText;
}

// actions:
export const {setQueryTemplate} = queriesSlice.actions;

// reducer:
export default queriesSlice.reducer;

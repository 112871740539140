//==============================================================================
// External:
import React from 'react';
import QueryFinalEmail from '../../components/QueryFinalEmail/QueryFinalEmail';

// Internal:
import useQueryFinalReviewHooks from './QueryFinalReviewHooks';
import {
  deleteQuery,
  gmailDeleteDraft,
  gmailSendEmailToAgent
} from '../../store/actions/actions';
//==============================================================================

const QueryFinalReview = () => {
  const {
    agent,
    dispatch,
    finalEmailHTML,
    navigate,
    query,
  } = useQueryFinalReviewHooks();

  const onDeleteEntireQuery = () => {
    dispatch(deleteQuery({ ...query, navigate }));
  }

  const onDeleteDraft = () => {
    dispatch(gmailDeleteDraft({...query, navigate}))
  }

  const onEditDraft = () => {
    navigate(`/query/${query._id}`, {state: {queryId: query._id}})
  }

  const onSendEmail = () => {
    dispatch(
      gmailSendEmailToAgent({
        agent,
        email: finalEmailHTML,
        navigate,
        projectId: query.projectId,
        query
      })
    )
  }

  return (
    <div className='QueryFinalReview__container'>
      <QueryFinalEmail
        agentId={query.agentId}
        finalEmailHTML={finalEmailHTML}
        fullHeight
        emailSubject={query.emailSubject}
      />
      <div className='QueryFinalReview__buttonsContainer'>
        <button onClick={() => onSendEmail()}>Email Query To Agent</button>
        <button onClick={() => onEditDraft()}>Edit Draft</button>
        <button onClick={() => onDeleteDraft()} className='QueryFinalReview__deleteButton'>Delete Draft From 'Drafts' Folder</button>
        <button onClick={() => onDeleteEntireQuery()} className='QueryFinalReview__deleteButton'>Delete Query</button>
      </div>
    </div>
  )
}

export default QueryFinalReview;

//===================================================================================
// External
import LogRocket from 'logrocket';
import { isProduction } from './utils';
//===================================================================================

const logrocketInit = () => {
  if (isProduction()) {
    LogRocket.init('a6cluj/querier');
  }
}

export default logrocketInit;

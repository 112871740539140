//==============================================================================
// External
import React from 'react';

// Internal
import useModalHandlerHooks from './ModalHandler.hooks';
import { setModalContent } from '../../store/slices/ui.slice';
//==============================================================================

const ModalHandler = () => {
  const {
    dispatch,
    error,
    modalContent,
    modalTitle,
  } = useModalHandlerHooks();
  
  const handleOnClick = () => {
    dispatch(setModalContent(''));
  };

  return (
    <>
      {modalContent && (
        <div className="ModalHandler__container">
          <div className="ModalHandler__contentContainer" id="ModalHandler">
            <div className="ModalHandler__titleContainer">
              <div className="ModalHandler__titleText">{modalTitle}</div>
              <div className="ModalHandler__close" onClick={() => handleOnClick()} />
            </div>
            <div className="ModalHandler__content">{modalContent}</div>
            {error && <div className="ModalHandler__errorContainer">{error}</div>}
          </div>
        </div>
      )}
    </>
  );
};

export default ModalHandler;

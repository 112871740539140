//==============================================================================
// Internal:
import { setFlashMessage, setLoading } from "../slices/ui.slice";
import { setThread } from "../slices/thread.slice";
import fetchAPI from "../../utils/fetchAPI";
import environment from "../../utils/env";
import { GMAIL_TOKEN_EXPIRED } from "../../utils/constants";
//==============================================================================

export const gmailGetThreadEffect = async (action, { dispatch, getState }) => {
  let returnedThread;

  try {
    dispatch(setLoading());
    const {query} = action.payload;

    returnedThread = await fetchAPI(
      `${environment.dbBaseURL}/mail/thread/${query.threadId}`,
    );

    if (returnedThread.status !== 200) throw Error;

  } catch (error) {
    if (returnedThread.status === 404) dispatch(setFlashMessage({ type: 'error', message: 'Google was unable to find this email thread.'}));
    if (returnedThread.status === 401) dispatch(setFlashMessage({ type: 'error', message: GMAIL_TOKEN_EXPIRED}));
  } finally {
    dispatch(setThread(returnedThread)); // return thread or error code
    dispatch(setLoading());
  }
};
//==============================================================================
// Internal:
import environment from "../../utils/env";
import { fetchWithPost } from "../../utils/fetchAPI";
import { getQueryTemplates, setQueryTemplate } from "../slices/queryTemplates.slice";
import { setFlashMessage } from "../slices/ui.slice";
import { getUserId } from "../slices/user.slice";
//==============================================================================

export const updateQueryTemplateEffect = async (action, { dispatch, getState }) => {
  try {
    const existingQueryInfo = getQueryTemplates(getState());
    const newQueryInfo = action.payload;
    const userId = getUserId(getState());
    const newQueryTemplate = await fetchWithPost(
      `${environment.dbBaseURL}/query-templates/${existingQueryInfo._id}`,
      { ...existingQueryInfo, ...newQueryInfo, userId },
      'PUT'
    );

    dispatch(setQueryTemplate(newQueryTemplate));
    dispatch(setFlashMessage({ type: 'success', message: 'Query Template Successfully Submitted...!' }))
  } catch (error) {
    console.error('Error in the UpdateQueryTemplate Effect', error)
    dispatch(setFlashMessage({ type: 'error', message: 'Unable To Submit Query Template. Please Try Again.' }))
  }
};
//==============================================================================
// External:
import React from 'react';

// Internal:
import Editor from '../Editor/Editor';
import QuestionDisplay from '../QuestionDisplay/QuestionDisplay';
import QueryMgrGenreDropdown from '../Dropdown/QueryManagerGenres';
//==============================================================================

const QueryManagerTemplate = ({ props }) => {
  const {
    authorBlog,
    authorTwitter,
    authorWebsite,
    biography,
    bookPreviouslyPublished,
    genre,
    hasBeenPreviouslyPublished,
    hasBeenPreviouslyRepd,
    phoneNumber,
    pitch,
    referral,
    setAuthorBlog,
    setAuthorTwitter,
    setAuthorWebsite,
    setBiography,
    setBookPreviouslyPublished,
    setGenre,
    setHasBeenPreviouslyPublished,
    setHasBeenPreviouslyRepd,
    setPhoneNumber,
    setPitch,
    setReferral,
    setSimilarBooks,
    setTargetAudience,
    setBookTitle,
    setWordCount,
    similarBooks,
    targetAudience,
    bookTitle,
    wordCount,
  } = props;


  return (
    <div className='QueryTitle__container'>
      <div className='QueryTitle__form' >
        <div className='QueryTitle__queryTitle'>
          <label>Title:</label>
          <input
            id='Title'
            onChange={(event) => setBookTitle(event.target.value)}
            placeholder={"Enter book's title here..."}
            value={bookTitle}
          />
          {/* <QuestionDisplay tooltipKey={'queryBuilderTemplateTitle'} /> */}
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Author's Biography:</label>
          <textarea
            id='Biography'
            onChange={(event) => setBiography(event.target.value)}
            placeholder={'Enter your biography here...'}
            value={biography}
          />
          {/* <QuestionDisplay tooltipKey={'queryBuilderTemplateTitle'} /> */}
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Phone Number:</label>
          <input
            id='PhoneNumber'
            onChange={(event) => setPhoneNumber(event.target.value)}
            placeholder={'Enter your phone number here...'}
            value={phoneNumber}
          />
          {/* <QuestionDisplay tooltipKey={'queryBuilderTemplateTitle'} /> */}
        </div>

        <div className='QueryTitle__queryTitle'>
          <label>Author's Website:</label>
          <input
            id='AuthorWebAddress'
            onChange={(event) => setAuthorWebsite(event.target.value)}
            placeholder={'Enter your website address here...'}
            value={authorWebsite}
          />
          {/* <QuestionDisplay tooltipKey={'queryBuilderTemplateTitle'} /> */}
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Author's Blog:</label>
          <input
            id='AuthorBlogAddress'
            onChange={(event) => setAuthorBlog(event.target.value)}
            placeholder={'Enter your blog address here...'}
            value={authorBlog}
          />
          <QuestionDisplay tooltipKey={'queryBuilderTemplateTitle'} />
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Author's Twitter (X):</label>
          <input
            id='AuthorTwitter'
            onChange={(event) => setAuthorTwitter(event.target.value)}
            placeholder={'Enter your Twitter (X) address here...'}
            value={authorTwitter}
          />
          {/* <QuestionDisplay tooltipKey={'queryBuilderTemplateTitle'} /> */}
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Genre:</label>
          <QueryMgrGenreDropdown value={genre} setValue={setGenre} />
          {/* <QuestionDisplay tooltipKey={'queryBuilderTemplateTitle'} /> */}
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Word Count:</label>
          <input
            id='WordCount'
            onChange={(event) => setWordCount(event.target.value)}
            placeholder={'Enter word count here...'}
            value={wordCount}
          />
          {/* <QuestionDisplay tooltipKey={'queryBuilderTemplateTitle'} /> */}
        </div>


        <div className='QueryTitle__queryTitle'>
          <label>Pitch:</label>
          {/* <div id="Pitch">
            <Editor setValue={setPitch} text={pitch} />
          </div> */}
          <textarea
            id='Pitch'
            onChange={(event) => setPitch(event.target.value)}
            placeholder={'Enter your pitch here...'}
            value={pitch}
          />
          {/* <QuestionDisplay tooltipKey={'queryBuilderTemplateTitle'} /> */}
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Target Audience:</label>
          {/* <div id="TargetAudience">
            <Editor setValue={setTargetAudience} text={targetAudience} />
          </div> */}
          <textarea
            id='TargetAudience'
            onChange={(event) => setTargetAudience(event.target.value)}
            placeholder={'Enter your target audience here...'}
            value={targetAudience}
          />
          {/* <QuestionDisplay tooltipKey={'queryBuilderTemplateTitle'} /> */}
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Similar Books:</label>
          {/* <div id="SimilarBooks">
            <Editor setValue={setSimilarBooks} text={similarBooks} />
          </div> */}
          <textarea
            id='SimilarBooks'
            onChange={(event) => setSimilarBooks(event.target.value)}
            placeholder={'Enter list of similar books here...'}
            value={similarBooks}
          />
          {/* <QuestionDisplay tooltipKey={'queryBuilderTemplateTitle'} /> */}
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Has this book been published before?</label>
          <label className='QueryTitle__radioLabel'>
            <input
              type="radio"
              value={true}
              checked={bookPreviouslyPublished === true}
              onChange={() => setBookPreviouslyPublished(true)}
            />
            Yes
          </label>
          <label className='QueryTitle__radioLabel'>
            <input
              type="radio"
              value={false}
              checked={bookPreviouslyPublished === false}
              onChange={() => setBookPreviouslyPublished(false)}
            />
            No
          </label>
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Have you previously published other books?</label>
          <label className='QueryTitle__radioLabel'>
            <input
              type="radio"
              value={true}
              checked={hasBeenPreviouslyPublished === true}
              onChange={() => setHasBeenPreviouslyPublished(true)}
            />
            Yes
          </label>
          <label className='QueryTitle__radioLabel'>
            <input
              type="radio"
              value={false}
              checked={hasBeenPreviouslyPublished === false}
              onChange={() => setHasBeenPreviouslyPublished(false)}
            />
            No
          </label>
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Have you been represented by a literary agent before?</label>
          <label className='QueryTitle__radioLabel'>
            <input
              type="radio"
              value={true}
              checked={hasBeenPreviouslyRepd === true}
              onChange={() => setHasBeenPreviouslyRepd(true)}
            />
            Yes
          </label>
          <label className='QueryTitle__radioLabel'>
            <input
              type="radio"
              value={false}
              checked={hasBeenPreviouslyRepd === false}
              onChange={() => setHasBeenPreviouslyRepd(false)}
            />
            No
          </label>
        </div>

        {/* <div className='QueryTitle__queryTitle bold'>
            <label>Referral:</label>
            <textarea
              id='Referral'
              onChange={(event) => setReferral(event.target.value)}
              placeholder={'Enter referral here...'}
              value={referral}
            />
            <QuestionDisplay tooltipKey={'queryBuilderTemplateTitle'} />
          </div> */}
      </div>
    </div>
  )
}

export default QueryManagerTemplate;
//==============================================================================
// External:
import LogRocket from "logrocket";

// Internal:
import environment from "../../utils/env";
import fetchAPI from "../../utils/fetchAPI";
import { createNewCustomer, fetchProjects } from "../actions/actions";
import { setUserDetails } from "../slices/user.slice";
import { getPaymentInfoEffect } from "./getPaymentInfo.effect";
//==============================================================================

export const initializeEffect = async (action, { dispatch, getState, take }) => {
  try {
    const { navigate } = action.payload;

    const currentUserURL = `${environment.dbBaseURL}/current-user`;
    const currentUser = await fetchAPI(currentUserURL)
    dispatch(setUserDetails(currentUser));

    LogRocket.identify(
      currentUser._id, {
      email: currentUser.email,
      name: `${currentUser.firstName} ${currentUser.lastName}`
    });

    if (currentUser.stripeCustomerId) {
      getPaymentInfoEffect({}, { dispatch, getState })
    } else {
      dispatch(createNewCustomer())
    }

    window.FreshworksWidget('identify', 'ticketForm', {
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      email: currentUser.email,
    });

    // TODO: can we call this effect directly?
    dispatch(fetchProjects())
    navigate('/dashboard')
  } catch (error) {
    console.error('Error in InitializeEffect: ', error);
  }
}

//==============================================================================
// External:
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFlashMessage, setFlashMessage } from '../../store/slices/ui.slice';

// Internal:

//==============================================================================

const useFlashMessage = () => {
  const flashMessage = useSelector(getFlashMessage || {});
  const dispatch = useDispatch();

  useEffect(() => {
    if (flashMessage.message) {
        setTimeout(() => {
          dispatch(setFlashMessage({}));
        }, 5000);
      }
  }, [flashMessage]);

  return {
    flashMessage,
  }
}

export default useFlashMessage;
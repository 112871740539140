//==============================================================================
// External:
import React from 'react';
import { useSelector } from 'react-redux';

// Internal:
import { getAgents } from '../store/slices/agents.slice';
import { getProjects } from '../store/slices/projects.slice';
import { getQueries } from '../store/slices/queries.slice';
import { getQueryTemplates } from '../store/slices/queryTemplates.slice';
import { getSubstitutions } from '../store/slices/substitutions.slice';
import { getSubstitutionEntries } from '../store/slices/substitutionEntries.slice';
import { getUserDetails } from '../store/slices/user.slice';
//==============================================================================

export const useGetProject = (id) => {
  const project = useSelector(getProjects)[id];
  return project;
};

export const useGetAgents = () => {
  const agents = useSelector(getAgents);
  return agents;
}

export const useGetQueryPages = () => {
  const {chapterOneText, chapterTwoText, chapterThreeText} = useSelector(getQueryTemplates);
  return {chapterOneText, chapterTwoText, chapterThreeText};
}

export const useGetQueries = () => {
  const queries = useSelector(getQueries)
  return queries;
}

export const useGetQueryTemplate = () => {
  const queryTemplate = useSelector(getQueryTemplates);
  return queryTemplate;
}

export const useGetQueryTemplateText = () => {
  const {queryTemplateText} = useSelector(getQueryTemplates);
  return queryTemplateText;
}

export const useGetSubstitutions = () => {
  const substitutions = useSelector(getSubstitutions);
  return substitutions;
}

export const useGetSubstitutionEntries = (queryId) => {
  const substitutionEntries = useSelector(getSubstitutionEntries);
  const querySubstitutions = Object.values(substitutionEntries).filter(subEntry => subEntry.queryId === queryId);
  return querySubstitutions;
}

export const useGetSynopsis = () => {
  const {synopsis} = useSelector(getQueryTemplates);
  return synopsis;
}

export const useQueryTemplateId = () => {
  const {_id} = useSelector(getQueryTemplates);
  return _id;
};

export const useGetUser = () => {
  const user = useSelector(getUserDetails)
  return user;
}

//==============================================================================
// Internal:
import fetchAPI from "../../utils/fetchAPI";
import { setQueries } from "../slices/queries.slice";
import { setFlashMessage, setModalContent } from "../slices/ui.slice";
import { gmailDeleteDraftAPICall } from "../effectUtils/apiCalls";
import { getUserDetails } from "../slices/user.slice";
import * as ACTIONS from '../../utils/actionConstants';
import environment from "../../utils/env";
//==============================================================================

export const deleteQueryEffect = async (action, { dispatch, getState, take }) => {
  try {
    const { _id: queryId, gmailDraftId, projectId, navigate } = action.payload;
    const {_id: userId} = getUserDetails(getState());

    dispatch(setModalContent('deleteQueryConfirmation'));

    const actionsArray = [
      ACTIONS.APP_DELETE_QUERY,
      ACTIONS.APP_KEEP_QUERY,
      'ui/setModalContent'
    ];

    const [newAction, ...rest] = await take(action => actionsArray.includes(action.type));

    dispatch(setModalContent(''));

    const deleteConfirmationAction = ACTIONS.APP_DELETE_QUERY

    if (newAction.type === deleteConfirmationAction) {
      const url = `${environment.dbBaseURL}/queries/${queryId}`;
      const response = await fetchAPI(url, 'DELETE')
      dispatch(setQueries(response.queriesArray));
      dispatch(setFlashMessage({ type: 'success', message: response.message }));
    }

    gmailDeleteDraftAPICall(userId, gmailDraftId);

    navigate(`/project/${projectId}/queries`);
  } catch (error) {
    dispatch(setFlashMessage({ type: 'error', message: 'There Was An Error Deleting The Query. Please Try Again.' }))
    console.error('Error In The DeleteQueryEffect: ', error)
  }
};

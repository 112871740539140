//==============================================================================
// Internal:
import { fetchWithPost } from "../../utils/fetchAPI";
import { setQueryTemplate } from "../slices/queryTemplates.slice";
import { getUserId } from "../slices/user.slice";
import environment from "../../utils/env";
//==============================================================================

export const createNewQueryTemplateEffect = async (action, { dispatch, getState }) => {
  try {
    const newQueryInfo = action.payload;
    const userId = getUserId(getState());

    const newQueryTemplate = await fetchWithPost(
      `${environment.dbBaseURL}/query-templates`,
      { ...newQueryInfo, userId },
    );
    dispatch(setQueryTemplate(newQueryTemplate));
    return newQueryTemplate;
  } catch (error) {
    console.error('Error In CreateNewQueryTemplateEffect: ', error);
  }
};
//==============================================================================
// Internal:
import fetchAPI from "../../utils/fetchAPI";
import { getProjects, setProjects } from "../slices/projects.slice";
import { setFlashMessage, setModalContent } from "../slices/ui.slice";
import environment from "../../utils/env";
import * as ACTIONS from '../../utils/actionConstants';
import { makeObjFromArray } from "../../utils/utils";
//==============================================================================

export const deleteProjectEffect = async (action, { dispatch, getState, take }) => {
  try {
    const { projectId, navigate } = action.payload;

    dispatch(setModalContent('deleteProjectConfirmation'));

    const actionsArray = [
      ACTIONS.APP_DELETE_PROJECT,
      ACTIONS.APP_KEEP_PROJECT,
      'ui/setModalContent'
    ];

    const [newAction, ...rest] = await take(action => actionsArray.includes(action.type));

    dispatch(setModalContent(''));

    if (newAction.type === ACTIONS.APP_DELETE_PROJECT) {
      const url = `${environment.dbBaseURL}/projects/${projectId}`
      const {projectId: returnedProjectId} = await fetchAPI(url, 'DELETE')

      const allProjects = getProjects(getState())
      const remainingProjects = Object.values(allProjects).filter(project => project._id !== returnedProjectId);
      const newProjectObj = makeObjFromArray(remainingProjects)

      dispatch(setProjects(newProjectObj));
      dispatch(setFlashMessage({ type: 'success', message: 'Project successfully deleted!' }));
    }

    navigate('/dashboard');
  } catch (error) {
    dispatch(setFlashMessage({ type: 'error', message: 'There Was An Error Deleting This Project. Please Try Again.' }))
    console.error('Error In The DeleteProjectEffect: ', error)
  }
};


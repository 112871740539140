// Home:
export const HOME_CARD_AGENT = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
export const HOME_CARD_WRITE = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
export const HOME_CARD_SEND = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'

//FAQs:
export const QUERIER_COST = 'During our Beta period, we are offering Querier for free. After our Beta period, Querier will costs $6.99 per project to send unlimited queries. However, the first three queries are free for new users to try the service.'
export const QUERIER_COST_QUESTION = 'How Much Does Querier Cost?'
export const USE_GMAIL = 'If you want to send your query drafts directly to Gmail, you will need to sign up for Querier using your Gmail account. If you don’t want to use Gmail, you can sign up with an email account, and copy/paste the final query draft into your email editor.'
export const USE_GMAIL_QUESTION = 'Do I Have To Use Gmail?'
export const GMAIL_ACCESS = 'We recommend signing up for a new Gmail account specifically for querrying.'
export const GMAIL_ACCESS_QUESTION = 'What If I Don’t Like Querier Having Access To My Gmail Account?'

// Base Texts:
export const BASE_TEXT_CH_ONE = '<p>Add your Chapter One text here!</p>'
export const BASE_TEXT_CH_THREE = '<p>Add your Chapter Three text here!</p>'
export const BASE_TEXT_CH_TWO = '<p>Add your Chapter Two text here!</p>'
export const BASE_TEXT_EMAIL_TEMPLATE = '<p>{{queryLetter}}</p><p style="margin-top: 30px">Synopsis:</p><p>{{synopsis}}</p><p style="margin-top: 30px">Pages:</p><p>{{pages}}</p>'
export const BASE_TEXT_EMAIL_SUBJECT = "QUERY - {Your Project Name}"
export const BASE_TEXT_QUERY = '<p>Hello {{name}},</p><p>This is the start of my query letter.</p><p>Regards,</p><p>Me</p>'
export const BASE_TEXT_SYNOPSIS = '<p>Add your synopsis text here!</p>'

// Query Manager:
export const STEP_TEXT_AGENT_LIST = 'Agent List Step Text will do here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
export const STEP_TEXT_CHAPTER = 'Chapter Step Text here! Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
export const STEP_TEXT_QUERY_BUILDER = 'Query BUILDER Step Text will do here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
export const STEP_TEXT_QUERY_TEMPLATE = 'Query Template Step Text will do here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
export const STEP_TEXT_SYNOPSIS = 'Synopsis Step Text will do here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
export const STEP_TEXT_QUERYMANAGER = 'Query Manager text here'

// Query Builder:
export const STEP_TEXT_SELECT_AGENT = 'Query Create: select agent. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
export const STEP_TEXT_ADD_SUBSTITUTIONS = 'Query Create: add substitutions. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
export const STEP_TEXT_SELECT_VERSION = 'Query Create: select version. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
export const STEP_TEXT_REVIEW_QUERY = 'Query Create: review query. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
export const STEP_TEXT_SEND_QUERY_INBOX = 'Query Create: send query to inbox. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
export const STEP_TEXT_SEND_QUERY_AGENT = 'Query Create: send query to agent. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'

// Query Manager Submit:
export const QM_SUBMIT_TEXT = 'QueryManager submit text'
export const QM_PERSONAL_LINK = 'QueryManager personal link text'

// Gmail Errors:
export const GMAIL_THREAD_NOT_FOUND = `We apologize for the inconvenience, but Google could not find this email thread. \nPlease return to your Gmail account to read these emails.`
export const GMAIL_TOKEN_EXPIRED = `Your Google credentials have expired. Please log back in to Querier to obtain new Google credentials.`



export const TEST = `
<p>Build and send queries with three simple steps:</p>
<ul>
<ul>
<ul>
<li>
<p>Make Your Query Template: Create a query template with placeholders for customized text.</p>
</li>
<li>
<p>Create Your Agent List: Store a list of agents</p>
</li>
</ul>
</ul>
</ul>
`

//==============================================================================
// External:
import React from 'react';

// Internal:

//==============================================================================

const Dropdown = ({setValue, value, shouldDisable = false}) => {

  const onChangeHandler = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className='Dropdown__container'>
      <select
        // defaultValue={''}
        disabled={shouldDisable}
        value={value}
        onChange={(event) => onChangeHandler(event)}
      >
        <option value="">N/A</option>
        <option value="Words">Words</option>
        <option value="Pages">Pages</option>
        <option value="Chapter">Chapter</option>
      </select>
    </div>
  )
}

export default Dropdown;

//==============================================================================
// Internal:
import fetchAPI from "../../utils/fetchAPI";
import { setPaymentInfo } from "../slices/user.slice";
import environment from "../../utils/env";
//==============================================================================

export const createNewCustomerEffect = async (action, { dispatch, getState }) => {
  try {
    let subscriptionId, subscriptionInfo;

    const { customer } = await fetchAPI(`${environment.dbBaseURL}/create-customer`);

    if (customer.id) {
      ({ subscriptionId	} = await fetchAPI(`${environment.dbBaseURL}/new-subscription`));
    }

    if (subscriptionId) {
      subscriptionInfo = await fetchAPI(`${environment.dbBaseURL}/get-subscription`);
      dispatch(setPaymentInfo(subscriptionInfo));
    }
  } catch (error) {
    console.error('Error in CreateNewCustomer Effect: ', error);
  }
};

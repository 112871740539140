//==============================================================================
// External
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal
import { setModalContent } from '../../store/slices/ui.slice';
import { getProjects } from '../../store/slices/projects.slice';
import { updateProject } from '../../store/actions/actions';
//==============================================================================

const ProjectEdit = ({ error, setError }) => {
  const [id, ...theRest] = window.location.pathname.split('/').reverse();
  const { title: existingTitle } = useSelector(getProjects)[id] || {};
  const [title, setTitle] = useState(existingTitle);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updateProject({ title, id }));
  }

  const handleCancel = () => {
    dispatch(setModalContent(''));
  };

  return (
    <form className="ProjectCreate__container" onSubmit={(event) => handleSubmit(event)}>
      <div className="ProjectCreate__inputContainer">
        <label>Project Title:</label>
        <input
          className="ProjectCreate__inputField"
          onChange={(event) => { setTitle(event.target.value) }}
          type='text'
          value={title}
        />
      </div>
      <div className="ProjectCreate__submitButtonContainer">
        <button
          className="ProjectCreate__submitButton"
          onClick={(event) => handleSubmit(event)}
        >
          Submit
        </button>
        <button
          type="button"
          className="ProjectCreate__cancelButton"
          onClick={() => handleCancel()}
        >
          Cancel
        </button>
      </div>
    </form>
  )
};

export default ProjectEdit;

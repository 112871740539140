//==============================================================================
// External:
import React from 'react';
import { useGetAgents, useGetQueries } from '../../../../hooks/commonHooks';

// Internal:
import AgentRatingGraph from '../AgentRatingGraph/AgentRatingGraph';
import StatusComponent from '../StatusComponent/StatusComponent';
//==============================================================================

const AgentStatus = ({ }) => {
  const agents = useGetAgents();
  const queries = useGetQueries();

  const totalAgentCount = Object.keys(agents).length;
  const queriesCount = Object.keys(queries).length;
  const closedCount = Object.values(agents).filter(agent => !agent.isOpen).length;
  const percentAgentsQueried = () => {
    if (!totalAgentCount) return `0%`;
    const percent = (queriesCount / totalAgentCount) * 100;
    return `${Math.round(percent)}%`;
  }

  return (
    <div className='AgentStatus__container'>
      <div className='AgentStatus__agentStats'>
        <StatusComponent
          title={"Agents Added"}
          metric={totalAgentCount}
          icon='fa-users'
        />
        <StatusComponent
          title={"Agents Queried"}
          metric={percentAgentsQueried()}
          icon='fa-envelope-open'
        />
        <StatusComponent
          title={"Open/Closed"}
          metric={`${totalAgentCount - closedCount}/${closedCount}`}
          icon='fa-minus-circle'
        />
        <StatusComponent
          title={"Agents Ratings"}
          Content={AgentRatingGraph}
        />
      </div>
    </div>
  )
}

export default AgentStatus;

//==============================================================================
/* External */
import {createSlice} from '@reduxjs/toolkit';
//==============================================================================

const initialState = {
  // activeProject: null
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    createProject(state, action) {
      const {_id} = action.payload;
      return {
        ...state,
        [_id]: { ...action.payload }
      }
    },
    setActiveProject(state, action) {
      state.activeProject = action.payload;
    },
    setProjects(state, action) {
      return {
        ...action.payload
      }
    },
    updateProject(state, action) {
      const {_id} = action.payload;
      return {
        ...state,
        [_id]: { ...action.payload }
      }
    }
  },
});

// selectors:
export const getProjects = (state) => state.projects;

// actions:
export const {
  createProject,
  deleteProject,
  setActiveProject,
  setProjects,
  updateProject
} = projectsSlice.actions;

// reducer:
export default projectsSlice.reducer;

//==============================================================================
//Internal:
import * as ACTIONS from '../../utils/actionConstants';
// effects:
import { createNewAgentEffect } from "./createNewAgent.effect";
import { createNewCustomerEffect } from './createNewCustomer.effect';
import { createNewProjectEffect } from "./createNewProject.effect";
import { createNewQueryEffect } from "./createNewQuery.effect";
import { createNewQueryTemplateEffect } from "./createNewQueryTemplate.effect";
import { createNewQueryTextEffect } from "./createNewQueryText.effect";
import { createOrUpdateSubstitutionEntriesEffect } from "./createOrUpdateSubstitutionEntries.effect";
import { createSubstitutionEffect } from "./createSubstitution.effect";
import { deleteAgentEffect } from './deleteAgent.effect';
import { deleteProjectEffect } from './deleteProject.effect';
import { deleteQueryEffect } from './deleteQuery.effect';
import { deleteSubstitutionEffect } from './deleteSubstitution.effect';
import { deleteUserEffect } from './deleteUser.effect';
import { finalizeQueryEffect } from "./finalizeQuery.effect";
import { getPaymentInfoEffect } from './getPaymentInfo.effect';
import { getProjectDataEffect } from "./getProjectData.effect";
import { getProjetEffect } from "./getProject.effect";
import { getSubstitutionsEffect } from "./getSubstitutions.effect";
import { gmailDeleteDraftEffect } from "./gmailDeleteDraft.effect";
import { gmailGetThreadEffect } from './gmailGetThread.effect';
import { gmailSendDraftEffect } from "./gmailSendDraft.effect";
import { gmailSendEmailEffect } from "./gmailSendEmail.effect";
import { gmailUpdateDraftEffect } from "./gmailUpdateDraft.effect";
import { gmailUpdateQuerySentToAgent } from './gmailUpdateQuerySentToAgent.effect';
import { handleIdleSessionEffect } from './handleIdleSession.effect';
import { initializeEffect } from "./initialize.effect";
import { logoutEffect } from "./logout.effect";
import { redirectIfTrialExpiredEffect } from './redirectIfTrialExpiredEffect.effect';
import { simpleUpdateQueryEffect } from './simpleUpdateQuery.effect';
import { startSessionExpirationEffect } from "./startSessionExpiration.effect";
import { updateAgentEffect } from "./updateAgent.effect";
import { updateAgentPriorityEffect } from "./updateAgentPriority.effect";
import { updateProjectEffect } from './updateProject.effect';
import { updateQueryEffect } from "./updateQuery.effect";
import { updateQueryTemplateEffect } from "./updateQueryTemplate.effect";
import { updateSubstitutionEffect } from "./updateSubstitution.effect";
//==============================================================================

const rootListener = (startListening) => {
  startListening({type: ACTIONS.AGENT_CREATE, effect: createNewAgentEffect})
  startListening({type: ACTIONS.AGENT_DELETE, effect: deleteAgentEffect})
  startListening({type: ACTIONS.AGENT_UPDATE, effect: updateAgentEffect})
  startListening({type: ACTIONS.AGENT_UPDATE_PRIORITY, effect: updateAgentPriorityEffect})
  startListening({type: ACTIONS.APP_START_SESSION_EXPIRATION, effect: startSessionExpirationEffect})
  startListening({type: ACTIONS.APP_DELETE_USER_ACCT, effect: deleteUserEffect})
  startListening({type: ACTIONS.APP_LOGOUT, effect: logoutEffect})
  startListening({type: ACTIONS.APP_CREATE_NEW_CUSTOMER, effect: createNewCustomerEffect})
  startListening({type: ACTIONS.APP_GET_PAYMENT_INFO, effect: getPaymentInfoEffect})
  startListening({type: ACTIONS.GMAIL_DELETE_DRAFT, effect: gmailDeleteDraftEffect})
  startListening({type: ACTIONS.GMAIL_GET_THREAD, effect: gmailGetThreadEffect})
  startListening({type: ACTIONS.GMAIL_SEND_DRAFT, effect: gmailSendDraftEffect})
  startListening({type: ACTIONS.GMAIL_SEND_EMAIL, effect: gmailSendEmailEffect})
  startListening({type: ACTIONS.GMAIL_UPDATE_DRAFT, effect: gmailUpdateDraftEffect})
  startListening({type: ACTIONS.GMAIL_UPDATE_QUERY_SENT_TO_AGENT, effect: gmailUpdateQuerySentToAgent})
  startListening({type: ACTIONS.APP_HANDLE_IDLE_SESSION, effect: handleIdleSessionEffect})
  startListening({type: ACTIONS.INITIALIZE, effect: initializeEffect})
  startListening({type: ACTIONS.PROJECTS_GET, effect: getProjetEffect})
  startListening({type: ACTIONS.PROJECT_CREATE, effect: createNewProjectEffect})
  startListening({type: ACTIONS.PROJECT_DELETE, effect: deleteProjectEffect})
  startListening({type: ACTIONS.PROJECT_GET, effect: getProjectDataEffect})
  startListening({type: ACTIONS.PROJECT_UPDATE, effect: updateProjectEffect})
  startListening({type: ACTIONS.QUERY_CREATE_NEW, effect: createNewQueryEffect})
  startListening({type: ACTIONS.QUERY_CREATE_NEW_TEXT, effect: createNewQueryTextEffect})
  startListening({type: ACTIONS.QUERY_DELETE, effect: deleteQueryEffect})
  startListening({type: ACTIONS.QUERY_FINALIZE, effect: finalizeQueryEffect})
  startListening({type: ACTIONS.QUERY_TEMPLATE_CREATE_NEW, effect: createNewQueryTemplateEffect})
  startListening({type: ACTIONS.QUERY_TEMPLATE_UPDATE, effect: updateQueryTemplateEffect})
  startListening({type: ACTIONS.QUERY_UPDATE, effect: updateQueryEffect})
  startListening({type: ACTIONS.QUERY_UPDATE_SIMPLE, effect: simpleUpdateQueryEffect})
  startListening({type: ACTIONS.APP_REDIRECT_IF_TRIAL_EXPIRED, effect: redirectIfTrialExpiredEffect});
  startListening({type: ACTIONS.SUBSTITUTIONS_GET, effect: getSubstitutionsEffect})
  startListening({type: ACTIONS.SUBSTITUTION_CREATE_NEW, effect: createSubstitutionEffect})
  startListening({type: ACTIONS.SUBSTITUTION_DELETE, effect: deleteSubstitutionEffect})
  startListening({type: ACTIONS.SUBSTITUTION_UPDATE, effect: updateSubstitutionEffect})
  startListening({type: ACTIONS.SUB_ENTRY_CREATE_OR_UPDATE, effect: createOrUpdateSubstitutionEntriesEffect})
  // startListening({type: 'app/createNewSubstitutionEntries', effect: createNewSubstitutionEntriesEffect})
  // startListening({type: 'app/updateSubstitutions', effect: updateSubstitutionsFinalTextEffect})
}

export default rootListener;

/*
  determines whether to add synopsis or query letter to Query
  if QueryManager, we want to add so it will be added to obj to paste
  if Email, conditionally add if user checks the box
*/
export const handleQueryAttr = (queryFormat, attrToAdd) => {
  if (queryFormat === 'QueryManager') {
    return true;
  } else {
    return attrToAdd;
  }
}

export const handlePagesType = (queryFormat, pageType) => {
  if (queryFormat === 'QueryManager' && !pageType) {
    return 'Pages';
  } else {
    return pageType;
  }
}

export const handlePageCount = (queryFormat, pageCount) => {
  if (queryFormat === 'QueryManager' && !pageCount) {
    return pageCount || 10;
  } else {
    return pageCount;
  }
}


//==============================================================================
// External:
import React from 'react';

// Internal:
import Dropdown from '../../../Dropdown/Dropdown';
import QuestionDisplay from '../../../QuestionDisplay/QuestionDisplay';
import { makeCheckbox, makeInput } from '../../../../utils/utils';
import PopoutWindow from '../../../../assets/window_popout.png'
//==============================================================================

const EmailForm = ({ emailFormProps, queryFormat }) => {
  const {
    agencyGuidelines,
    email,
    pageCount,
    pagesType,
    queryLetter,
    setAgencyGuidelines,
    setEmail,
    setPageCount,
    setPagesType,
    setQueryLetter,
    setSynopsis,
    shouldDisable,
    synopsis,
  } = emailFormProps;

  return (
    <>
    {
      queryFormat === 'Email' &&
      <div className='AgentForm__email inputContainer'>
        <div className='AgentForm__label'>Submission Email: </div>
        {makeInput(setEmail, email, 'agentEmail', 'Agent Email', {}, shouldDisable)}
      </div>
      }
      <div className='AgentForm__agencyGuidelines inputContainer'>
        <div className='AgentForm__labelPopoutContainer'>
          <div className='AgentForm__label'>Submission Guidelines: </div>
          {
            agencyGuidelines &&
            <a href={`${agencyGuidelines}`} target="_blank">
              <img src={PopoutWindow} className='AgentForm__popoutIcon' />
            </a>
          }
        </div>
        {makeInput(setAgencyGuidelines, agencyGuidelines, 'agencyGuidelines', 'Agency Guidelines Address', {}, shouldDisable)}
      </div>
      <div className='AgentForm__queryLetter inputContainer'>
        <div className='AgentForm__label'>Query Letter: </div>
        {makeCheckbox(setQueryLetter, queryLetter, 'queryLetter', shouldDisable)}
      </div>
      <div className='AgentForm__synopsis inputContainer'>
        <div className='AgentForm__label'>Synopsis: </div>
        {makeCheckbox(setSynopsis, synopsis, 'synopsis', shouldDisable)}
      </div>
      <div className='AgentForm__pagesType'>
        <div className='AgentForm__label'>Sample Pages Type: </div>
        <div className='AgentForm__pagesDropdownContainer'>
          <Dropdown setValue={setPagesType} value={pagesType} shouldDisable={shouldDisable}/>
          <div className='AgentForm__questionDisplay'>
            <QuestionDisplay tooltipKey={'pagesDropdown'} />
          </div>
        </div>
      </div>
      {
        pagesType &&
        <div className='AgentForm__pageCount​'>
          <div className='AgentForm__label'> {`${pagesType} Count:`} </div>
          {makeInput(setPageCount, pageCount, 'pageCountInput', `Number of ${pagesType}`, {}, shouldDisable)}
        </div>
      }
    </>
  )
}

export default EmailForm;
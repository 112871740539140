//==============================================================================
// External:
import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Internal:
import PageSubtitle from '../PageSubtitle/PageSubtitle';
import CheckListItem from '../CheckListItem/CheckListItem';
import PagesBuilder from '../PagesBuilder/PagesBuilder';
import QueryTitle from '../QueryTitle/QueryTitle';
import QueryLetterTemplate from '../QueryLetterTemplate/QueryLetterTemplate';
import QueryManagerTemplate from '../QueryManagerTemplate/QueryManagerTemplate';
import SynopsisBuilder from '../SynopsisBuilder/SynopsisBuilder';
import { useGetSubstitutions } from '../../hooks/commonHooks';
import {
  STEP_TEXT_QUERY_TEMPLATE,
  STEP_TEXT_CHAPTER,
  STEP_TEXT_SYNOPSIS,
  STEP_TEXT_QUERY_BUILDER,
} from '../../utils/constants';
import {
  hasAddedChapters,
  hasAddedEmailSubject,
  hasChangedQueryMgrInfo,
  hasAddedQueryText,
  hasAddedQueryTitle,
  hasAddedSubstitutions,
  hasAddedSynopsis,
  hasCompletedQueryMgrInfo
} from '../../utils/utils';
import useQueryTitle from '../QueryTitle/QueryTitle.hooks';
import useQueryLetterTemplate from '../QueryLetterTemplate/QueryLetterTemplateHooks';
import useSynopsisBuilderHooks from '../SynopsisBuilder/SynopsisBuilderHooks';
import usePagesBuilderHooks from '../PagesBuilder/PagesBuilderHooks';
import { updateQueryTemplate } from '../../store/actions/actions';
import useQueryManagerTemplateHooks from '../QueryManagerTemplate/QueryManagerTemplate.hooks';
//==============================================================================

const QueryTemplateChecklist = () => {
  const { id: projectId } = useParams()
  const queryTitleProps = useQueryTitle();
  const queryLetterTemplateProps = useQueryLetterTemplate();
  const synopsisBuilderProps = useSynopsisBuilderHooks();
  const pagesBuilderProps = usePagesBuilderHooks();
  const substitutions = useGetSubstitutions();
  const queryManagerProps = useQueryManagerTemplateHooks();
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault()
    const params = {
      chapterOneText: pagesBuilderProps.localChapter1,
      chapterThreeText: pagesBuilderProps.localChapter3,
      chapterTwoText: pagesBuilderProps.localChapter2,
      emailSubject: queryTitleProps.emailSubjectFS,
      projectId,
      queryTemplateText: queryLetterTemplateProps.localText,
      synopsis: synopsisBuilderProps.localSynopsis,
      title: queryTitleProps.templateTitleFS,
      authorWebsite: queryManagerProps.authorWebsite,
      authorTwitter: queryManagerProps.authorTwitter,
      biography: queryManagerProps.biography,
      referral: queryManagerProps.referral,
      authorBlog: queryManagerProps.authorBlog,
      bookPreviouslyPublished: queryManagerProps.bookPreviouslyPublished,
      genre: queryManagerProps.genre,
      hasBeenPreviouslyPublished: queryManagerProps.hasBeenPreviouslyPublished,
      hasBeenPreviouslyRepd: queryManagerProps.hasBeenPreviouslyRepd,
      phoneNumber: queryManagerProps.phoneNumber,
      pitch: queryManagerProps.pitch,
      similarBooks: queryManagerProps.similarBooks,
      targetAudience: queryManagerProps.targetAudience,
      bookTitle: queryManagerProps.bookTitle,
      wordCount: queryManagerProps.wordCount,
    }
    dispatch(updateQueryTemplate(params))
  }

  const shouldEnableButton = () => {
    // TODO: set to hasChanged and compare to original
    if (hasAddedQueryTitle(queryTitleProps.templateTitleFS)) return true;
    if (hasAddedEmailSubject(queryTitleProps.emailSubjectFS)) return true;
    if (hasAddedQueryText(queryLetterTemplateProps.localText, substitutions)) return true;
    if (hasAddedSubstitutions(substitutions)) return true;
    if (hasAddedSynopsis(synopsisBuilderProps.localSynopsis)) return true;
    if (hasAddedChapters(pagesBuilderProps.localChapter1, pagesBuilderProps.localChapter2, pagesBuilderProps.localChapter3)) return true;
    if (hasCompletedQueryMgrInfo(queryLetterTemplateProps)) return true;
    return false;
  }

  // TODO: is this needed?
  // const onClearState = () => {
  //   queryTitleProps.setTemplateTitleFS('');
  //   queryTitleProps.setEmailSubjectFS(queryTitleProps.originalEmailSubject);
  //   synopsisBuilderProps.setLocalSynopsis(synopsisBuilderProps.originalSynopsis);
  //   queryLetterTemplateProps.setLocalText(queryLetterTemplateProps.originalQueryTemaplateText);
  //   pagesBuilderProps.setLocalChapter1(pagesBuilderProps.originalChapter1);
  //   pagesBuilderProps.setLocalChapter2(pagesBuilderProps.originalChapter2);
  //   pagesBuilderProps.setLocalChapter3(pagesBuilderProps.originalChapter3);
  // }

  return (
    <form className='QueryTemplateChecklist__container' onSubmit={(event) => handleSubmit(event)}>
      <div>
        <PageSubtitle title='Query Builder Checklist' />
        <div className="QueryTemplate__checkListItemContainer">
          <CheckListItem
            component={<QueryTitle props={queryTitleProps} />}
            hasCompletedStep={hasAddedQueryTitle(queryTitleProps.templateTitleFS) && hasAddedEmailSubject(queryTitleProps.emailSubjectFS)}
            number='1'
            openByDeault
            text={STEP_TEXT_QUERY_BUILDER}
            title="Add Email Subject"
          />
          <CheckListItem
            component={<QueryLetterTemplate props={queryLetterTemplateProps} />}
            hasCompletedStep={hasAddedQueryText(queryLetterTemplateProps.localText, substitutions) || hasAddedSubstitutions(substitutions)}
            number="2"
            text={STEP_TEXT_QUERY_TEMPLATE}
            title="Make Query Letter Template"
          />
          <CheckListItem
            component={<SynopsisBuilder props={synopsisBuilderProps} />}
            hasCompletedStep={hasAddedSynopsis(synopsisBuilderProps.localSynopsis)}
            number='3'
            text={STEP_TEXT_SYNOPSIS}
            title="Add Synopsis"
          />
          <CheckListItem
            component={<PagesBuilder props={pagesBuilderProps} />}
            hasCompletedStep={hasAddedChapters(pagesBuilderProps.localChapter1, pagesBuilderProps.localChapter2, pagesBuilderProps.localChapter3)}
            number='4'
            text={STEP_TEXT_CHAPTER}
            title="Add Chapters/Pages"
          />
          <CheckListItem
            component={<QueryManagerTemplate props={queryManagerProps} />}
            hasCompletedStep={hasCompletedQueryMgrInfo(queryManagerProps)}
            isLastItem
            number='5'
            text={STEP_TEXT_CHAPTER}
            title="Add QueryManager Information"
          />
        </div>
      </div>
      <div className="QueryTemplate__buttonContainer">
        <button
          className="QueryTemplate__submitButton"
          disabled={!shouldEnableButton()}
        >
          Submit Query Template
        </button>
        {/* <button
          className="QueryTemplate__resetButton"
          onClick={() => onClearState()}
          type="button"
        >
          Reset Data
        </button> */}
      </div>
    </form>
  )
}

export default QueryTemplateChecklist;

//==============================================================================
// External:
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

// Internal:
import QueryCard from '../../components/QueryCard/QueryCard';
import PageTitleBar from '../../components/PageTitleBar/PageTitleBar';
import ButtonCreateQuery from '../../components/ButtonCreateQuery/ButtonCreateQuery';
import { useGetProject, useGetQueries } from '../../hooks/commonHooks';
//==============================================================================

const QueryBuilder = () => {
  const { projectId } = useParams()
  const queries = useGetQueries();
  const { title: projectTitle } = useGetProject(projectId) || {};
  const [filter, setFilter] = useState('all');
  const [filteredSortedQueries, setFilteredSortedQueries] = useState(queries);

  const onFilter = (event) => {
    const selection = event.target.value;
    setFilter(event.target.value);
    switch (selection) {
      case 'all': {
        setFilteredSortedQueries(queries);
        break;
      }
      case 'sentToInbox': {
        const filteredList = Object.values(queries).filter(query => query?.dateSentToInbox !== null && query.dateSentToAgent === null)
        setFilteredSortedQueries(filteredList)
        break;
      }
      case 'sentToAgent': {
        const filteredList = Object.values(queries).filter(query => query?.dateSentToAgent !== null)
        setFilteredSortedQueries(filteredList)
        break;
      }
      case 'unsentToInbox': {
        const filteredList = Object.values(queries).filter(query => query?.dateSentToInbox === null)
        setFilteredSortedQueries(filteredList)
        break
      }
      case 'inProgress': {
        const filteredList = Object.values(queries).filter(query => query?.dateSentToInbox === null && query.dateSentToAgent === null)
        setFilteredSortedQueries(filteredList)
        break;
      }
      default: {
        const filteredList = Object.values(queries).filter(query => query?.status === selection)
        setFilteredSortedQueries(filteredList)
        break;
      }
    }
  };

  const queriesList = Object.values(filteredSortedQueries).map((query, index) => {
    return <QueryCard query={query} number={index + 1} />
  });

  return (
    <div className='QueryBuilder__container'>
      <PageTitleBar
        title={projectTitle}
        subTitle={'Queries'}
      />
      <div>
        <div className='QueryBuilder__contentContainer'>
          <div className='QueryBuilder__header'>
            <ButtonCreateQuery
              route={`/project/${projectId}/query-create`}
              text='New Query'
            />
            <div className='QueryBuilder__dropdownContainer'>
              <label>Filter By:</label>
              <select value={filter} onChange={(event) => onFilter(event)}>
                <option value="all">All</option>
                <optgroup label="Open Queries">
                  <option value="inProgress">Started</option>
                  <option value="sentToInbox">Sent To Drafts Folder</option>
                  <option value="sentToAgent">Sent To Agent</option>
                  <option value="unsentToInbox">Not Sent To Drafts Folder</option>
                  <option value="waiting">Waiting</option>
                  <option value="manuscript-request-full">Manuscript Request - Full</option>
                  <option value="manuscript-request-partial">Manuscript Request - Partial</option>
                  <option value="revise-resubmit">Revise & Resubmit</option>
                </optgroup>
                <optgroup label="Closed Queries">
                  <option value="rejection-form">Form Rejection</option>
                  <option value="rejection-personal">Personal Rejection</option>
                  <option value="representation-offer">Offer of Representation</option>
                  <option value="no-response">No Response</option>
                </optgroup>
              </select>
            </div>
          </div>
          <table className='QueryBuilder__list'>
            <tr>
              <th>Number</th>
              <th>Agent Name</th>
              <th>Agency</th>
              <th>Status</th>
              <th>Query Type</th>
              <th>Respone Time Remaining</th>
              <th>Created</th>
            </tr>
            {queriesList}
          </table>
        </div>
      </div>
    </div>
  )
}

export default QueryBuilder;
